import { ListRequestParams } from '../api/types';
import { FilterParams } from '../ui/table-filter/TextTableFilter';

export function applyFilterWithParams(
  fieldName: string,
  fieldValue: string | string[],
  params: ListRequestParams,
  operator: 'equal' | 'notin' | 'contains' = 'equal',
): ListRequestParams {
  if (!fieldValue.length) return { ...params };
  if (Array.isArray(fieldValue)) {
    const additionalFilter = `${fieldName},${operator},${fieldValue.join(',')}`;

    return { ...params, filter: [...(params.filter ?? []), additionalFilter] };
  }
  const additionalFilter = `${fieldName},${operator},${fieldValue}`;

  return { ...params, filter: [...(params.filter ?? []), additionalFilter] };
}

export function applyFilter(
  fieldName: string,
  fieldValue: string | string[],
  operator: 'equal' | 'in' | 'notin' | 'contains' = 'equal',
): string {
  if (!fieldValue.length) return '';
  if (Array.isArray(fieldValue)) {
    return `${fieldName},${operator},${fieldValue.join(',')}`;
  }

  return `${fieldName},${operator},${fieldValue}`;
}

export function subscriptionFilterToQuery(subscriptionFilter: Record<string, FilterParams>) {
  return (
    Object.keys(subscriptionFilter)
      .map(fieldName =>
        subscriptionFilter[fieldName]?.filterValue
          ? applyFilter(fieldName, subscriptionFilter[fieldName].filterValue, subscriptionFilter[fieldName].operator)
          : '',
      )
      .filter(Boolean) || []
  );
}
