import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const ContactLogListPage = React.lazy(() => import('./customer/ViewCustomerPage/ContactLogTab/ContactLogListPage'));
const DocumentsListPage = React.lazy(() => import('./customer/ViewCustomerPage/DocumentsList/DocumentsListPage'));
const TicketListPage = React.lazy(() => import('./ticket/TicketListPage'));
const EditTicketPage = React.lazy(() => import('./ticket/EditTicketPage'));
const SearchCustomerAndSubscriptionPage = React.lazy(
  () => import('./customer-search/SearchCustomerAndSubscriptionPage'),
);
const ViewCustomerPage = React.lazy(() => import('./customer/ViewCustomerPage/ViewCustomerPage'));
const EditCustomerPage = React.lazy(() => import('./customer/EditCustomerPage/EditCustomerPage'));
const EditSubscriptionPage = React.lazy(() => import('./subscription/EditSubscriptionPage/EditSubscriptionPage'));
const SubscriptionHistoryPage = React.lazy(
  () => import('./subscription/SubscriptionHistoryPage/SubscriptionHistoryPage'),
);
const SubscriptionListPage = React.lazy(() => import('./subscription/SubscriptionListPage'));
const NewTicketPage = React.lazy(() => import('./ticket/NewTicketPage'));
const ViewAccountPage = React.lazy(() => import('./account/ViewAccountPage'));
const ViewInvoicePage = React.lazy(() => import('./invoice/ViewInvoicePage'));
const ViewInvoicesListPage = React.lazy(() => import('./invoices/ViewInvoicesListPage'));
const ViewPaymentsPage = React.lazy(() => import('./payments/PaymentsPage'));

export const crmRoutes = () =>
  new RoutesConfig({
    contactLogList: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customer.contactLog,
      element: <ContactLogListPage />,
    },
    documentsList: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customer.documents,
      element: <DocumentsListPage />,
    },
    customerTicketsList: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customerTicket.list,
      element: <TicketListPage />,
    },
    editCustomerTicket: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.customerTicket.edit,
      element: <EditTicketPage />,
    },
    editFilteredTicket: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.customerTicket.editFiltered,
      element: <EditTicketPage />,
    },
    customerSearch: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customer.search,
      element: <SearchCustomerAndSubscriptionPage />,
    },
    viewCustomer: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customer.view,
      element: <ViewCustomerPage />,
    },
    editCustomer: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.customer.edit,
      element: <EditCustomerPage />,
    },
    editSubscription: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.subscription.edit,
      element: <EditSubscriptionPage />,
    },
    subscriptionHistory: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.subscription.history,
      element: <SubscriptionHistoryPage />,
    },
    subscriptionList: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.subscription.list,
      element: <SubscriptionListPage />,
    },
    paymentsList: {
      permission: { type: 'CRM', groups: ['PAYMENT'], actions: ['VIEW'] },
      path: routePaths.crm.customer.payments,
      element: <ViewPaymentsPage />,
    },
    ticketsList: {
      permission: { type: 'TICKET', actions: ['VIEW'] },
      path: routePaths.crm.ticket.list,
      element: <TicketListPage />,
    },
    newTicket: {
      permission: { type: 'TICKET', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.ticket.new,
      element: <NewTicketPage />,
    },
    editTicket: {
      permission: { type: 'TICKET', actions: ['VIEW', 'EDIT'] },
      path: routePaths.crm.ticket.edit,
      element: <EditTicketPage />,
    },
    viewAccount: {
      permission: { type: 'CRM', actions: ['VIEW'] },
      path: routePaths.crm.customer.account,
      element: <ViewAccountPage />,
    },
    viewInvoice: {
      path: routePaths.crm.invoice.view,
      permission: { type: 'BILLING', groups: ['ACCOUNT'], actions: ['VIEW'] },
      element: <ViewInvoicePage />,
    },
    viewInvoicesList: {
      path: routePaths.crm.invoicesList.view,
      permission: { type: 'BILLING', groups: ['ACCOUNT'], actions: ['VIEW'] },
      element: <ViewInvoicesListPage />,
    },
  });
