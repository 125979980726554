const redirectReasons = ['UNEXPECTED_ERROR', 'OBJECT_NOT_FOUND'];

export function shouldRedirectOnError(reason = '', message?: string | undefined): boolean {
  if (!reason && !message) {
    return false;
  }

  if (typeof message !== 'string' && (message as any)?.response?.status === 404) {
    return true;
  }

  return redirectReasons.includes(reason);
}
