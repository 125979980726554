import { useContext } from 'react';

import { AuthContext } from './contexts/AuthContext';

export const useAuth = () => {
  const { user, isLoading, error, isAuthenticated, login, logout, getAccessToken } = useContext(AuthContext);

  return {
    user,
    isLoading,
    error,
    isAuthenticated,
    login,
    logout,
    getAccessToken,
  };
};
