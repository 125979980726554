import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Paper, Tab, Tabs as MuiTabs, tabsClasses, TabsProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  tabsProps?: TabsProps;
  tabs: Array<{
    label: string;
    content: React.ReactNode;
    error?: boolean;
    isRequired?: boolean;
    disabled?: boolean;
  }>;
}

export const Tabs: React.FC<React.PropsWithChildren<Props>> = ({ tabs, tabsProps }) => {
  const [tabIndex, setTabIndex] = useState(getActiveTabIndexFromUrl());
  const location = useLocation();
  const handleChange = (_: React.ChangeEvent<{}>, index: number) => {
    window.location.hash = `active-tab=${index}`;
    setTabIndex(index);
  };
  const theme = useTheme();

  useEffect(() => {
    const hash = location.hash;
    const currentTabIndex = hash && hash.includes('active-tab') ? parseInt(hash.split('=')[1]) : 0;
    setTabIndex(currentTabIndex);
  }, [location.hash]);

  return (
    <Paper variant="outlined">
      <MuiTabs
        value={tabIndex}
        textColor="secondary"
        indicatorColor="secondary"
        onChange={handleChange}
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
        {...tabsProps}
      >
        {tabs
          .filter(tab => !tab.disabled)
          .map((tab, idx) => (
            <Tab
              sx={{
                ...(tab?.error && { color: `${theme.palette.error.main} !important` }),
              }}
              key={idx + `${tab.error}`}
              id={`product-tab-${idx}`}
              label={`${tab.label}${tab.isRequired ? '*' : ''}`}
            />
          ))}
      </MuiTabs>
      {tabs
        .filter(tab => !tab.disabled)
        .map((tab, idx) => (
          <Box key={idx} role="tabpanel" hidden={tabIndex !== idx} id={`product-tabpanel-${idx}`} p={3}>
            {tabIndex === idx && tab.content}
          </Box>
        ))}
    </Paper>
  );
};

function getActiveTabIndexFromUrl(): number {
  const hash = window.location.hash.slice(1);

  return hash && hash.includes('active-tab') ? parseInt(hash.split('=')[1]) : 0;
}
