import { AxiosInstance } from 'axios';

import { MatchingOfferParams, MatchingOfferResult } from '../order/types';
import { ListRequestParams, ScrollableEntry, SuccessResponse, UpdateProductStatusParams } from '../types';
import {
  ApiProductForm,
  ICopyOffer,
  Product,
  ProductCategory,
  ProductListItem,
  ServiceFeeForm,
  ServiceFee,
  PenaltyRuleListView,
  PenaltyRuleForm,
  PenaltyRule,
  UpdatePenaltyRuleParams,
} from './types';

export class ProductApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<ProductListItem>>('/product/api/product', { params });
  }

  public async product(id: string) {
    return await this.axios.get<Product>(`/product/api/product/${id}`);
  }

  public async create(form: ApiProductForm) {
    return await this.axios.post<SuccessResponse>('/product/api/product', form);
  }

  public async update(id: string, form: ApiProductForm) {
    return await this.axios.put<SuccessResponse>(`/product/api/product/${id}`, form);
  }

  public async updateStatus({ id, productStatus }: UpdateProductStatusParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/product/${id}/update-status`, {
      productStatus,
    });
  }

  public async categories() {
    return await this.axios.get<ProductCategory[]>('/product/api/product-category');
  }

  public async matchingOffers(params: MatchingOfferParams) {
    return await this.axios.post<MatchingOfferResult[]>('/product/api/offer/match', params);
  }

  public async copyOffer(params: ICopyOffer) {
    return await this.axios.post<SuccessResponse>(`/product/api/offer/${params.id}/copy`, { newName: params.newName });
  }

  public async serviceFeeList() {
    return await this.axios.get<ServiceFee[]>('/product/api/service-fee');
  }

  public async saveServiceFee(serviceFeeForm: ServiceFeeForm[]) {
    return await this.axios.put<SuccessResponse>('/product/api/service-fee', serviceFeeForm);
  }

  public async penaltyFeeRuleList() {
    return await this.axios.get<PenaltyRuleListView[]>('/product/api/penalty-rule');
  }

  public async penaltyFeeRule(id: PenaltyRule['id']) {
    return await this.axios.get<PenaltyRule>(`/product/api/penalty-rule/${id}`);
  }

  public async updatePenaltyFeeRuleStatus({ id, productStatus }: UpdateProductStatusParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/penalty-rule/${id}/update-status`, { productStatus });
  }

  public async newPenaltyRule(penaltyRuleForm: PenaltyRuleForm) {
    return await this.axios.post<SuccessResponse>(`/product/api/penalty-rule`, penaltyRuleForm);
  }

  public async updatePenaltyRule({ id, penaltyRuleForm }: UpdatePenaltyRuleParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/penalty-rule/${id}`, penaltyRuleForm);
  }
}
