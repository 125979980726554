import { ThemeOptions } from '@mui/material';

import { prodTelfiDocumentTitle, prodTelfiLogo, prodTelfiThemeOptions } from './tenants/telfi';
import { prodBestConnectDocumentTitle, prodBestConnectLogo, prodBestConnectThemeOptions } from './tenants/bestConnect';
import {
  prodFairplayMobileDocumentTitle,
  prodFairplayMobileLogo,
  prodFairplayMobileThemeOptions,
} from './tenants/fairplayMobile';
import { prodL2TravelSimDocumentTitle, prodL2TravelSimLogo, prodL2TravelSimThemeOptions } from './tenants/l2TravelSim';
import {
  prodTenantWorldMobileDocumentTitle,
  tenantWorldMobileLogo,
  tenantWorldMobileThemeOptions,
} from './tenants/tenantWorldMobile';

export const PROD_TENANTS_ID_MAP = {
  BEST_CONNECT: 'BEST_CONNECT',
  TELFI: 'TELFI',
  FAIRPLAY_MOBILE: 'FAIRPLAY_MOBILE',
  L2_TRAVEL_SIM: 'L2_TRAVEL_SIM',
  WORLD_MOBILE: 'WORLD_MOBILE',
};

export const PROD_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [PROD_TENANTS_ID_MAP.BEST_CONNECT]: prodBestConnectThemeOptions,
  [PROD_TENANTS_ID_MAP.TELFI]: prodTelfiThemeOptions,
  [PROD_TENANTS_ID_MAP.FAIRPLAY_MOBILE]: prodFairplayMobileThemeOptions,
  [PROD_TENANTS_ID_MAP.L2_TRAVEL_SIM]: prodL2TravelSimThemeOptions,
  [PROD_TENANTS_ID_MAP.WORLD_MOBILE]: tenantWorldMobileThemeOptions,
};

export const PROD_LOGO_BY_TENANT_MAP: Record<string, string> = {
  [PROD_TENANTS_ID_MAP.BEST_CONNECT]: prodBestConnectLogo,
  [PROD_TENANTS_ID_MAP.TELFI]: prodTelfiLogo,
  [PROD_TENANTS_ID_MAP.FAIRPLAY_MOBILE]: prodFairplayMobileLogo,
  [PROD_TENANTS_ID_MAP.L2_TRAVEL_SIM]: prodL2TravelSimLogo,
  [PROD_TENANTS_ID_MAP.WORLD_MOBILE]: tenantWorldMobileLogo,
};

export const PROD_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP: Record<string, string> = {
  [PROD_TENANTS_ID_MAP.BEST_CONNECT]: prodBestConnectDocumentTitle,
  [PROD_TENANTS_ID_MAP.TELFI]: prodTelfiDocumentTitle,
  [PROD_TENANTS_ID_MAP.FAIRPLAY_MOBILE]: prodFairplayMobileDocumentTitle,
  [PROD_TENANTS_ID_MAP.L2_TRAVEL_SIM]: prodL2TravelSimDocumentTitle,
  [PROD_TENANTS_ID_MAP.WORLD_MOBILE]: prodTenantWorldMobileDocumentTitle,
};
