import * as yup from 'yup';

import { normalizeOptionalDate } from '../../date';
import { validateCustomerEmailUnique, validateLegalRepresentativeEmailUnique } from '../../../app/validation';
import { CustomerFeatures, Features, isFeatureEnabled } from '../../features';
import { SOUTH_AFRICA_ALPHA2_CODE, ZA_PHONE_CODE, ZA_PHONE_NUMBER_LENGTH } from '../../countries';
import { isSouthAfricanCustomerCodeValid } from '../../customer';
import { consentsSchema } from '../consents';
import { isIdentityExpiryDateRequired } from '../../countries/utils';
import { IDENTITY_TYPES as INDIAN_IDENTITY_TYPES } from '../../countries/india';
import { IdentityType as DEFAULT_IDENTITY_TYPES } from '../types';

export const customerTypeSchema = yup.string().required('common:errors.required').oneOf(['PRIVATE', 'BUSINESS']);

export const birthDateSchema = yup
  .date()
  .required('common:errors.required')
  .transform(normalizeOptionalDate)
  .test('age', 'pos:add-customer-details.age-validation-error', birthDate => {
    const cutoff = new Date();
    cutoff.setFullYear(cutoff.getFullYear() - 18);

    return birthDate ? birthDate <= cutoff : false;
  });

export const identityNumberSchema = yup.string().when(['country'], {
  is: (country: string) => country === SOUTH_AFRICA_ALPHA2_CODE,
  then: schema => schema.notRequired(),
  otherwise: schema => schema.required('common:errors.required'),
});

export const identityExpiryDateSchema = yup
  .date()
  .when(['country', 'identityType'], {
    is: (country: string, identityType: DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES) =>
      !isIdentityExpiryDateRequired({ country, identityType }),
    then: schema => schema.transform(normalizeOptionalDate),
    otherwise: schema =>
      schema
        .required('common:errors.required')
        .transform(normalizeOptionalDate)
        .test('Valid', 'pos:add-customer-details.document-validation-error', validationDate => {
          const today = new Date();

          return validationDate ? validationDate > today : false;
        }),
  })
  .transform(normalizeOptionalDate)
  .test('Valid', 'pos:add-customer-details.document-validation-error', validationDate => {
    const today = new Date();
    if (!validationDate) return true;

    return validationDate ? validationDate > today : false;
  });

export const customerCodeSchema = yup
  .string()
  .required('common:errors.required')
  .test({
    name: 'customerCodePattern',
    message: 'pos:add-customer-details.customer-code-pattern',
    test: (value, context) =>
      context.parent.country !== SOUTH_AFRICA_ALPHA2_CODE ||
      (context.parent.birthDate && isSouthAfricanCustomerCodeValid(value, context.parent.birthDate)),
  });

export const contactPhoneTestOptions: (message: string) => yup.TestConfig<string | undefined, Record<string, any>> = (
  message: string,
) => ({
  name: 'phone',
  message,
  test: value => !value || !value.startsWith(ZA_PHONE_CODE) || value.length === ZA_PHONE_NUMBER_LENGTH,
});

export const contactPhoneNumberSchema = yup
  .string()
  .transform(val => val.replace(/[^A-Z0-9]/gi, ''))
  .test(contactPhoneTestOptions('pos:add-customer-details.phone'));

export const getContactEmailSchema = (getAccessToken: () => Promise<string>) =>
  yup
    .string()
    .email('common:errors.invalid-email')
    .test('contactEmail', 'common:errors.email-exists', (email, testContext) =>
      validateCustomerEmailUnique({
        email: email || '',
        getAccessToken,
        testContext,
      }),
    );

export const getLegalRepresentativeContactEmailSchema = (getAccessToken: () => Promise<string>) =>
  yup
    .string()
    .email('common:errors.invalid-email')
    .test('contactEmail', 'common:errors.email-exists', (email, testContext) =>
      validateLegalRepresentativeEmailUnique({
        email: email || '',
        getAccessToken,
        testContext,
      }),
    );

export const addressValidationSchema = yup.object({
  addressLine1: yup.string().required('common:errors.required'),
  addressLine2: yup.string(),
  country: yup.string(),
  region: yup.string().required('common:errors.required'),
  district: yup.string().required('common:errors.required'),
  city: yup.string().required('common:errors.required'),
  zipCode: yup.string().required('common:errors.required'),
  addressFieldsSetByGoogle: yup.array().of(yup.string()),
});

export const legalRepresentativeSchema = yup.object({
  firstName: yup.string().required('common:errors.required'),
  lastName: yup.string().required('common:errors.required'),
  birthDate: birthDateSchema,
  contactPhoneNo: contactPhoneNumberSchema,
  contactEmail: yup.string().email('common:errors.invalid-email'),
  country: yup.string().required('common:errors.required'),
  identityType: yup.string<DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES>().required('common:errors.required'),
  identityNumber: identityNumberSchema,
  identityExpiryDate: identityExpiryDateSchema,
  customerCode: customerCodeSchema,
});

export const businessCustomerValidationSchema = yup.object({
  legalRepresentative: legalRepresentativeSchema,
  customerType: customerTypeSchema.default('BUSINESS'),
  companyName: yup.string().required('common:errors.required'),
  tradingAs: yup.string(),
  vatNumber: yup.string(),
  country: yup.string().required('common:errors.required'),
  taxNumber: yup.string(),
  faxNo: yup.string(),
  customerCode: yup.string().required('common:errors.required'),
  contactPhoneNo: contactPhoneNumberSchema,
  contactEmail: yup.string().email('common:errors.invalid-email'),
  address: addressValidationSchema,
  existing: yup.boolean(),
  consents: consentsSchema,
});

export const privateCustomerValidationSchema = yup.object({
  customerType: customerTypeSchema.default('PRIVATE'),
  firstName: yup.string().required('common:errors.required'),
  lastName: yup.string().required('common:errors.required'),
  additionalName: yup.string(),
  birthDate: birthDateSchema,
  customerCode: customerCodeSchema,
  country: yup.string().required('common:errors.required'),
  identityType: yup.string<DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES>().required('common:errors.required'),
  identityNumber: identityNumberSchema,
  identityExpiryDate: identityExpiryDateSchema,
  contactPhoneNo: contactPhoneNumberSchema,
  contactEmail: yup.string().email('common:errors.invalid-email'),
  existing: yup.boolean(),
  address: addressValidationSchema,
  consents: consentsSchema,
});

export const getBusinessCustomerFormSchema = (getToken: () => Promise<string>, features: Features) => {
  const contactEmailSchema = getContactEmailSchema(getToken);
  const legalRepresentativeContactEmailSchema = getLegalRepresentativeContactEmailSchema(getToken);
  const identityFieldsDisabled = !isFeatureEnabled(features, CustomerFeatures.IdConfirmationAvailability);
  const noopSchema = yup.string().strip().default(undefined);

  return businessCustomerValidationSchema.shape({
    contactEmail: isFeatureEnabled(features, CustomerFeatures.RequireEmailField)
      ? contactEmailSchema.required('common:errors.required')
      : contactEmailSchema.notRequired(),
    address: isFeatureEnabled(features, CustomerFeatures.AddressAvailability)
      ? addressValidationSchema.shape({
          district: isFeatureEnabled(features, CustomerFeatures.RequireDistrictField)
            ? yup.string().required('common:errors.required')
            : yup.string().notRequired(),
        })
      : addressValidationSchema.strip(),
    legalRepresentative: legalRepresentativeSchema.shape({
      contactEmail: isFeatureEnabled(features, CustomerFeatures.RequireEmailField)
        ? legalRepresentativeContactEmailSchema.required('common:errors.required')
        : legalRepresentativeContactEmailSchema.notRequired(),
      ...(identityFieldsDisabled && {
        country: noopSchema,
        customerCode: noopSchema,
        identityType: noopSchema,
        identityNumber: noopSchema,
        identityExpiryDate: noopSchema,
      }),
    }),
  });
};

export const getPrivateCustomerFormSchema = (getToken: () => Promise<string>, features: Features) => {
  const contactEmailSchema = getContactEmailSchema(getToken);
  const identityFieldsDisabled = !isFeatureEnabled(features, CustomerFeatures.IdConfirmationAvailability);
  const noopSchema = yup.string().strip().default(undefined);

  return privateCustomerValidationSchema.shape({
    contactEmail: isFeatureEnabled(features, CustomerFeatures.RequireEmailField)
      ? contactEmailSchema.required('common:errors.required')
      : contactEmailSchema.notRequired(),
    address: isFeatureEnabled(features, CustomerFeatures.AddressAvailability)
      ? addressValidationSchema.shape({
          district: isFeatureEnabled(features, CustomerFeatures.RequireDistrictField)
            ? yup.string().required('common:errors.required')
            : yup.string().notRequired(),
        })
      : addressValidationSchema.strip(),
    ...(identityFieldsDisabled && {
      country: noopSchema,
      customerCode: noopSchema,
      identityType: noopSchema,
      identityNumber: noopSchema,
      identityExpiryDate: noopSchema,
    }),
  });
};
