import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const BillRunsPage = React.lazy(() => import('./bill-runs/BillRunsPage'));
const PaymentsPage = React.lazy(() => import('./payments/PaymentsPage'));
const BillRunViewPage = React.lazy(() => import('./bill-runs/BillRunViewPage'));

export const billingRoutes = () =>
  new RoutesConfig({
    billRunsList: {
      permission: { type: 'BILLING', actions: ['VIEW'] },
      path: routePaths.billing.billRuns.list,
      element: <BillRunsPage />,
    },
    billRunViewPage: {
      permission: { type: 'BILLING', actions: ['VIEW'] },
      path: routePaths.billing.billRuns.view,
      element: <BillRunViewPage />,
    },
    paymentsList: {
      permission: { type: 'BILLING', groups: ['PAYMENT'], actions: ['VIEW'] },
      path: routePaths.billing.payments.list,
      element: <PaymentsPage />,
    },
  });

export interface BillingViewRouteParams {
  billRunId: string;
}
