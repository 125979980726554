import { i18n, InitOptions, Module, StringMap, TFunction, TOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { arSA, enUS, esES } from '@mui/material/locale';

export const ENGLISH_LANGUAGE = { key: 'en', nativeLabel: 'English', muiLocale: enUS };
export const SPANISH_LANGUAGE = { key: 'es', nativeLabel: 'Español', muiLocale: esES };
export const SAUDI_ARABIC_LANGUAGE = {
  key: 'arSA',
  nativeLabel: 'العربية',
  muiLocale: arSA,
};

export const AVAILABLE_LANGUAGES = [ENGLISH_LANGUAGE, SPANISH_LANGUAGE, SAUDI_ARABIC_LANGUAGE];

export const getMuiLocale = (localeKey: string) => {
  const defaultLocale = ENGLISH_LANGUAGE.muiLocale;
  if (!localeKey) return defaultLocale;
  return AVAILABLE_LANGUAGES.find(({ key }) => key === localeKey)?.muiLocale ?? defaultLocale;
};

export const configureI18n = async (
  i18next: i18n,
  module: Module,
  options: Partial<InitOptions>,
): Promise<TFunction> => {
  const i18nWithModule = module ? i18next.use(module) : i18next;

  return i18nWithModule
    .use(LanguageDetector)
    .use(Backend)
    .init({
      fallbackLng: 'en',
      supportedLngs: AVAILABLE_LANGUAGES.map(({ key }) => key),
      ns: ['common', 'entries'],
      defaultNS: 'common',
      returnObjects: true,
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      interpolation: { escapeValue: false },
      ...options,
    });
};

/**
 * Interpolated translation key must be: `"common:interpolated-key{value1:111}{value2:222}"`
 */
export const interpolatedTranslation = (text: string): ArgumentTypes<TFunction> =>
  [
    text.replace(/\{.*\}/g, '').trim(),
    text.match(/\{([^}]+)}/g)?.reduce<TOptions<StringMap>>((prev, curr) => {
      const [key, value] = curr.replace(/\{|}/g, '').split(':');

      return { ...prev, [key]: value };
    }, {}) ?? {},
  ] as unknown as ArgumentTypes<TFunction>;
