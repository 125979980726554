import { IdentityType as DEFAULT_IDENTITY_TYPES } from '../schemas';
import { SOUTH_AFRICA_ALPHA2_CODE } from './southAfrica';
import {
  IDENTITY_EXPIRY_DATE_DISABLED_LIST as IDENTITY_EXPIRY_DATE_DISABLED_LIST_FOR_INDIA,
  IDENTITY_TYPES as INDIAN_IDENTITY_TYPES,
} from './india';

export const IDENTITY_EXPIRY_DATE_NOT_REQUIRED_LIST: Array<DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES> = [
  ...IDENTITY_EXPIRY_DATE_DISABLED_LIST_FOR_INDIA,
];

export const isIdentityExpiryDateRequired = ({
  country,
  identityType,
}: {
  country: string;
  identityType: DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES;
}) => {
  const notRequiredForCountry = country === SOUTH_AFRICA_ALPHA2_CODE;
  const notRequiredForIdentityType = IDENTITY_EXPIRY_DATE_NOT_REQUIRED_LIST.includes(identityType);

  if (notRequiredForCountry || notRequiredForIdentityType) return false;

  return identityType !== DEFAULT_IDENTITY_TYPES.IDCard;
};

export const isIdentityExpiryDateDisabled = ({
  country,
  identityType,
}: {
  country: string;
  identityType: DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES;
}) => {
  const disabledForCountry = country === SOUTH_AFRICA_ALPHA2_CODE;
  const disabledForIdentityType = [
    ...(IDENTITY_EXPIRY_DATE_DISABLED_LIST_FOR_INDIA as Array<DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES>),
  ].includes(identityType);

  return disabledForCountry || disabledForIdentityType;
};
