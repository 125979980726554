import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { IDownloadPdfByTemplateRequest, TemplateResponse } from './types';

export class TemplateControllerApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async templateList(orderId: string) {
    return await this.axios.get<TemplateResponse[]>(`/pos/api/template/${orderId}`);
  }

  public async downloadPdfByTemplate(params: IDownloadPdfByTemplateRequest) {
    const { templateId, orderId, fileName, orderProductId } = params;
    const result = await this.axios.post<any>(
      '/pos/api/template/pdf',
      { templateId, orderId, orderProductId },
      {
        responseType: 'arraybuffer',
      },
    );
    fileDownload(result.data, `${fileName}.pdf`);

    return result;
  }
}
