export type SimType = 'eSIM' | 'SIM';

export enum DeliveryType {
  // Physical Delivery Required for physical SIM (Home Delivery)
  PhysicalDelivery = 'PHYSICAL_DELIVERY',
  // No delivery required for physical SIM
  DirectDelivery = 'DIRECT_DELIVERY',
  // Default delivery for eSIM
  EDelivery = 'E_DELIVERY',
  NoDelivery = 'NO_DELIVERY',
  CourierDelivery = 'COURIER_DELIVERY',
}
