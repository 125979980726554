import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Paper, Typography } from '@mui/material';

import { AlertMessage, OverlayedLoader } from './';

interface Props {
  flex?: number;
  title?: string;
  actionText?: string;
  actionIcon?: React.ReactNode;
  minWidth?: number;
  variant?: 'elevation' | 'outlined';
  noBorder?: boolean;
  noPadding?: boolean;
  pt?: number;
  pb?: number;
  error?: string;
  loading?: boolean;
  hideActionBtn?: boolean;
  onAction?(): void;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonColor?: 'error' | 'inherit' | 'secondary' | 'primary' | 'success' | 'info' | 'warning' | undefined;
  actionDisabled?: boolean;
  height?: string;
}

export const BoxContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  flex = 1,
  minWidth,
  variant = 'outlined',
  actionText,
  actionIcon,
  onAction,
  buttonVariant = 'contained',
  buttonColor = 'secondary',
  noBorder,
  error,
  loading = false,
  noPadding,
  pt = 3,
  pb = 3,
  hideActionBtn = false,
  actionDisabled = false,
  height = 'inherit',
}) => {
  const { t } = useTranslation('common');

  return (
    <Box flex={flex} width="100%" minWidth={minWidth} height={height}>
      <Paper
        elevation={noBorder || variant === 'outlined' ? 0 : 4}
        variant={noBorder ? undefined : variant}
        sx={{ height }}
      >
        <Box
          display="flex"
          flexDirection="column"
          p={noPadding ? 0 : 3}
          pt={noPadding ? 0 : pt}
          pb={noPadding ? 0 : pb}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={title ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {title && (
              <Typography color="primary" variant="h6">
                {title}
              </Typography>
            )}
            {(actionText || actionIcon) && onAction && !hideActionBtn && (
              <Box height="100%">
                <Button
                  color={buttonColor}
                  variant={buttonVariant}
                  startIcon={actionIcon}
                  onClick={onAction}
                  disabled={actionDisabled}
                >
                  {actionText}
                </Button>
              </Box>
            )}
          </Box>
          {loading ? <Typography>{t('loading')}</Typography> : children}
          {typeof error === 'string' && (
            <Box marginTop={2}>
              <AlertMessage severity="error" elevation={0}>
                {error}
              </AlertMessage>
            </Box>
          )}
          <OverlayedLoader open={loading} />
        </Box>
      </Paper>
    </Box>
  );
};
