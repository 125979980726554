import { alpha } from '@mui/material';

import worldMobileLogo from './images/WORLD_MOBILE_LOGO.png';
export const tenant1Logo = worldMobileLogo;
export const tenant1DocumentTitle = 'World Mobile Partner';
export const tenant1ThemeOptions = {
  palette: {
    primary: {
      light: '#7d50ee',
      main: '#5A2FBA',
      dark: '#4b2b9f',
      contrastText: '#fff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      light: '#f97a58',
      main: '#F6642D',
      dark: '#be4c24',
      contrastText: '#fff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
      contrastThreshold: 3,
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#000',
      contrastThreshold: 3,
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#000',
      contrastThreshold: 3,
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#000',
      contrastThreshold: 3,
    },
    text: {
      primary: '#000',
      secondary: '#555',
      disabled: '#888',
      hint: '#aaa',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    action: {
      active: alpha('#000', 1),
      hover: alpha('#000', 0.02), // modified to be less bright
      hoverOpacity: 0.02,
      selected: alpha('#000', 0.08),
      disabled: alpha('#000', 0.26),
      disabledBackground: alpha('#000', 0.12),
      focus: alpha('#000', 0.12),
    },
    augmentColor: {
      color: {
        light: '#7d50ee',
        main: '#5A2FBA',
        dark: '#4b2b9f',
        contrastText: '#fff',
      },
      tonalOffset: 0.2,
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaa',
      A400: '#303030',
      A700: '#616161',
    },
  },
};
