import React from 'react';

import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  cols?: number;
}

export const Row: React.FC<React.PropsWithChildren<Props>> = ({ cols, children, ...props }) => {
  const childrenCount = !!children && Array.isArray(children) ? children.length : 1;
  const emptyColsCount = cols ? cols - childrenCount : 0;

  return (
    <Box display="flex" flexDirection="row" width="100%" alignItems="flex-start" {...props}>
      {children}
      {!!emptyColsCount && <Box flex={emptyColsCount} />}
    </Box>
  );
};
