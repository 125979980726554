import { ReactNode } from 'react';
import { UseAsyncReturn } from 'react-async-hook';

import { ColDef, GridApi, ISimpleFilterModel, RowNode } from '@ag-grid-community/core';

import { columnTypes } from './config';

export type GridFieldName<T> = keyof T | NestedKeyOf<T>;

export type GridColumnTypes = (typeof columnTypes)[number];

export interface GridColumn<T> extends Omit<ColDef, 'field'> {
  type: GridColumnTypes;
  field: GridFieldName<T> | Array<GridFieldName<T>>;
  cellRendererParams?: ButtonCellRendererParams<T>;
}

export interface GridColumnParams<T> {
  field: GridFieldName<T> | Array<GridFieldName<T>>;
  headerName: string;
  type: GridColumnTypes;
  sort?: 'asc' | 'desc';
  onlyEqualsFilter?: boolean;
  checkboxEnabled?: boolean;
  onClicked?(data: T): void;
}

export interface GridAction<T> {
  name: FuncOrValue<string, T>;
  icon?: FuncOrValue<ReactNode, T>;
  endIcon?: FuncOrValue<ReactNode, T>;
  startIcon?: FuncOrValue<ReactNode, T>;
  onClick: (rowData: T, node: RowNode, api: GridApi) => void;
  disabled?: (rowData: T) => boolean;
  hidden?: FuncOrValue<boolean, T>;
}

export type FuncOrValue<T, K> = T | ((value: K) => T);

export type GridFilters<T> = Partial<
  Record<
    keyof T,
    {
      type: GridFilterOperation;
      value:
        | { dateFrom: string | null; dateTo: string | null }
        | { filter?: string | null }
        | { filter?: number | null; filterTo?: number | null }
        | { values: string[] };
    }
  >
>;

export type GridActions<T> = Array<GridAction<T>>;

export interface GridContext<T> {
  actions?: Array<GridAction<T>>;
}

export interface GridUtilities {
  excelExport?: boolean;
  excelExportCb?: UseAsyncReturn<void>;
}

export interface ButtonCellRendererParams<T> {
  context: { onClicked: GridColumnParams<T>['onClicked'] };
}

export enum CellRenderer {
  Action = 'Action',
  Button = 'Button',
  Menu = 'Menu',
}

export enum GridFilterOperation {
  Contains = 'contains',
  Empty = 'empty',
  Equals = 'equals',
  InRange = 'inRange',
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
}

export enum FilterOperation {
  Equal = 'equal',
  EqualCaseInsensitive = 'equalcaseinsensitive',
  LessThan = 'lessthan',
  GreaterThan = 'greaterthan',
  Between = 'between',
  Contains = 'contains',
  Blank = 'blank',
  In = 'in',
}

export interface MultiValueFilterModel extends ISimpleFilterModel {
  values: string[];
}

interface Response {
  status: number;
  statusText: string;
}
interface Message {
  response: Response;
}

export interface Error {
  name: string;
  message: Message;
  stack?: string;
}
