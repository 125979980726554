import { ReactNode } from 'react';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Box, Button } from '@mui/material';

import { FuncOrValue, GridContext } from '../types';

interface Props<T> extends ICellRendererParams {
  context: GridContext<T>;
  node: RowNode;
}

export const ActionCellRenderer = <T extends unknown>({ data, api, node, context: { actions = [] } }: Props<T>) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      {actions.map(({ hidden, name, disabled, onClick, endIcon, startIcon }, idx) =>
        (hidden && parseValue(hidden)) || !parseValue(name) ? null : (
          <Box key={idx} marginX={1}>
            <Button
              endIcon={parseValue(endIcon)}
              startIcon={parseValue(startIcon)}
              disabled={typeof disabled === 'function' && disabled(data)}
              onClick={() => onClick(data, node, api)}
              variant="outlined"
              color="primary"
              size="small"
            >
              {parseValue(name)}
            </Button>
          </Box>
        ),
      )}
    </Box>
  );

  function parseValue(value: FuncOrValue<string | boolean | ReactNode, T>) {
    if (typeof value === 'function') {
      return value(data);
    }

    return value;
  }
};
