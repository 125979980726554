export const capitaliseFirstCharOnly = (str: string | undefined) => {
  if (!str) return '';

  const firstChar = str[0];
  const rest = str.slice(1);

  return firstChar + rest.toLowerCase();
};

export const camelCaseToKebab = (inputString: string | undefined = '') =>
  inputString
    .split(/(?=[A-Z])/)
    .map(s => s.toLowerCase())
    .join('-');

export const snakeCaseToSentence = (inputString: string | undefined = '') =>
  inputString
    .split('_')
    .map(s => capitaliseFirstCharOnly(s))
    .join(' ');
