import { FC } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  OnApproveActions,
  OnApproveData,
  OnCancelledActions,
  PayPalButtonsComponentOptions,
} from '@paypal/paypal-js/types/components/buttons';

import { PayPalCheckoutButton } from './index';
import { useApi, useApiCallback } from '../../../../app/api-hooks';
import { Loader } from '../../../Loader';
import { useTenantSecret } from '../../../../app/hooks/useTenantSecret';

interface Props {
  omOrderId: string;
  onPaymentCancel?: PayPalButtonsComponentOptions['onCancel'];
  onPaymentApprove?: PayPalButtonsComponentOptions['onApprove'];
}

export const PayPalCheckoutCard: FC<Props> = ({ omOrderId, onPaymentCancel, onPaymentApprove }) => {
  const payPalApplicationId = useTenantSecret('REACT_APP_PAYPAL_CLIENT_ID');
  const omOrder = useApi(api => api.orderManagement.order(omOrderId));
  const capturePaymentCb = useApiCallback(api => api.paymentApi.capturePayPalPayment(omOrderId));

  const payPalOrderId = omOrder.result?.data?.orderPayment?.clientSessionId;

  if (omOrder.loading) return <Loader />;
  if (!payPalOrderId || !omOrderId || typeof payPalApplicationId !== 'string') return null;

  return (
    <PayPalScriptProvider
      options={{
        clientId: payPalApplicationId,
        currency: omOrder?.result?.data?.orderPayment?.amount?.currency,
        components: 'buttons',
      }}
    >
      {capturePaymentCb.loading ? (
        <Loader />
      ) : (
        <PayPalCheckoutButton
          onPaymentCancel={handleCancelPayment}
          onPaymentApprove={handlePaymentApprove}
          payPalOrderId={payPalOrderId}
        />
      )}
    </PayPalScriptProvider>
  );

  async function handlePaymentApprove(data: OnApproveData, actions: OnApproveActions) {
    await capturePaymentCb.execute();

    if (typeof onPaymentApprove === 'function') await onPaymentApprove(data, actions);
  }

  function handleCancelPayment(data: Record<string, unknown>, actions: OnCancelledActions) {
    if (typeof onPaymentCancel === 'function') onPaymentCancel(data, actions);
  }
};
