import awsmobile from './aws-exports';

export const awsConfig = {
  ...awsmobile,
  aws_cognito_region: process.env.REACT_APP_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_cognito_poolId,
  aws_user_pools_web_client_id: process.env.REACT_APP_cognito_user_pools_web_client_id,
  oauth: {
    domain: process.env.REACT_APP_cognito_domain,
  },
};
