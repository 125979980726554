import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry } from '../types';
import { CallDetailRecord } from './types';

export class CdrApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async callDetailRecords(subscriptionId: string, params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<CallDetailRecord>>(`/cdr/api/${subscriptionId}`, {
      params,
    });
  }
}
