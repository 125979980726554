import React, { useCallback, useState } from 'react';

import { Drawer } from './Drawer';
import { AppBar } from '../app-bar/AppBar';
import { Toolbar } from '../app-bar/Toolbar';
import { NavigationList } from './NavigationList';

export const NavigationMenu: React.FC = React.memo(() => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setDrawerOpen(prevVal => !prevVal), []);

  return (
    <>
      <AppBar shifted={drawerOpen}>
        <Toolbar />
      </AppBar>
      <Drawer isOpen={drawerOpen}>
        <NavigationList drawerOpen={drawerOpen} handleToggleOpen={handleToggleOpen} />
      </Drawer>
    </>
  );
});
