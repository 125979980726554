import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Language, ExpandLess, ExpandMore } from '@mui/icons-material';
import { AVAILABLE_LANGUAGES } from '../../../../app/i18n';

export const LanguageSelect: FC = () => {
  const { t, i18n, ready } = useTranslation('common');
  const [languageListOpen, setLanguageListOpen] = useState(false);

  return (
    <List disablePadding sx={{ width: '100%' }}>
      <ListItemButton disableRipple disabled={!ready} onClick={() => setLanguageListOpen(!languageListOpen)}>
        <ListItemIcon sx={{ color: 'text.primary' }}>
          <Language />
        </ListItemIcon>
        <ListItemText primary={t('language.button.select-language')} />
        {languageListOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={languageListOpen} timeout="auto">
        <List component="div" disablePadding>
          {AVAILABLE_LANGUAGES.map(language => (
            <ListItemButton key={language.key} onClick={() => handleSelectLanguageClick(language.key)}>
              <ListItemText
                primary={language.nativeLabel}
                primaryTypographyProps={{ color: i18n.language === language.key ? 'primary' : 'neutral' }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </List>
  );

  async function handleSelectLanguageClick(languageKey: string) {
    await i18n.changeLanguage(languageKey);
  }
};
