import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const NewChargeCodePage = React.lazy(() => import('./charge-codes/NewChargeCodePage'));
const EditChargeCodePage = React.lazy(() => import('./charge-codes/EditChargeCodePage'));
const ChargeCodeListPage = React.lazy(() => import('./charge-codes/ChargeCodeListPage/ChargeCodeListPage'));
const OffersListPage = React.lazy(() => import('./offers/OffersListPage/OffersListPage'));
const NewOfferPage = React.lazy(() => import('./offers/NewOfferPage'));
const EditOfferPage = React.lazy(() => import('./offers/EditOfferPage'));
const ProductsListPage = React.lazy(() => import('./products/ProductsListPage/ProductsListPage'));
const NewProductPage = React.lazy(() => import('./products/NewProductPage'));
const EditProductPage = React.lazy(() => import('./products/EditProductPage'));
const ComponentsListPage = React.lazy(() => import('./components/ComponentsListPage/ComponentsListPage'));
const NewComponentPage = React.lazy(() => import('./components/NewComponentPage'));
const EditComponentPage = React.lazy(() => import('./components/EditComponentPage'));
const EligibilityRuleListPage = React.lazy(
  () => import('./eligibility-rules/EligibilityRuleListPage/EligibilityRuleListPage'),
);
const NewEligibilityRulePage = React.lazy(() => import('./eligibility-rules/NewEligibilityRulePage'));
const EditEligibilityRulePage = React.lazy(() => import('./eligibility-rules/EditEligibilityRulePage'));
const ServiceFeeListPage = React.lazy(() => import('./service-fees/ServiceFeeListPage/ServiceFeeListPage'));
const PenaltyFeeListPage = React.lazy(() => import('./penalty-fees/PenaltyFeeListPage/PenaltyFeeListPage'));
const NewPenaltyFeePage = React.lazy(() => import('./penalty-fees/NewPenaltyFeePage/NewPenaltyFeePage'));
const EditPenaltyFeePage = React.lazy(() => import('./penalty-fees/EditPenaltyFeePage/EditPenaltyFeePage'));

export const productCatalogRoutes = () =>
  new RoutesConfig({
    offersList: {
      permission: { type: 'PRODUCT', groups: ['OFFER'], actions: ['VIEW'] },
      path: routePaths.productCatalog.offers.list,
      element: <OffersListPage />,
    },
    newOffer: {
      permission: { type: 'PRODUCT', groups: ['OFFER'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.offers.new,
      element: <NewOfferPage />,
    },
    editOffer: {
      permission: { type: 'PRODUCT', groups: ['OFFER'], actions: ['VIEW'] },
      path: routePaths.productCatalog.offers.edit,
      element: <EditOfferPage />,
    },
    productsList: {
      permission: { type: 'PRODUCT', groups: ['PRODUCT'], actions: ['VIEW'] },
      path: routePaths.productCatalog.products.list,
      element: <ProductsListPage />,
    },
    newProduct: {
      permission: { type: 'PRODUCT', groups: ['PRODUCT'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.products.new,
      element: <NewProductPage />,
    },
    editProduct: {
      permission: { type: 'PRODUCT', groups: ['PRODUCT'], actions: ['VIEW'] },
      path: routePaths.productCatalog.products.edit,
      element: <EditProductPage />,
    },
    componentsList: {
      permission: { type: 'PRODUCT', groups: ['COMPONENT'], actions: ['VIEW'] },
      path: routePaths.productCatalog.components.list,
      element: <ComponentsListPage />,
    },
    newComponent: {
      permission: { type: 'PRODUCT', groups: ['COMPONENT'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.components.new,
      element: <NewComponentPage />,
    },
    editComponent: {
      permission: { type: 'PRODUCT', groups: ['COMPONENT'], actions: ['VIEW'] },
      path: routePaths.productCatalog.components.edit,
      element: <EditComponentPage />,
    },
    eligibilityRulesList: {
      permission: { type: 'PRODUCT', groups: ['ELIGIBILITY_RULE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.eligibilityRules.list,
      element: <EligibilityRuleListPage />,
    },
    newEligibilityRule: {
      permission: { type: 'PRODUCT', groups: ['ELIGIBILITY_RULE'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.eligibilityRules.new,
      element: <NewEligibilityRulePage />,
    },
    editEligibilityRule: {
      permission: { type: 'PRODUCT', groups: ['ELIGIBILITY_RULE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.eligibilityRules.edit,
      element: <EditEligibilityRulePage />,
    },
    chargeCodesList: {
      permission: { type: 'PRODUCT', groups: ['CHARGE_CODE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.chargeCodes.list,
      element: <ChargeCodeListPage />,
    },
    newChargeCode: {
      permission: { type: 'PRODUCT', groups: ['CHARGE_CODE'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.chargeCodes.new,
      element: <NewChargeCodePage />,
    },
    editChargeCode: {
      permission: { type: 'PRODUCT', groups: ['CHARGE_CODE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.chargeCodes.edit,
      element: <EditChargeCodePage />,
    },
    serviceFeeList: {
      permission: { type: 'PRODUCT', groups: ['SERVICE_FEE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.serviceFees.list,
      element: <ServiceFeeListPage />,
    },
    penaltyFeeList: {
      permission: { type: 'PRODUCT', groups: ['PENALTY_RULE'], actions: ['VIEW'] },
      path: routePaths.productCatalog.penaltyFees.list,
      element: <PenaltyFeeListPage />,
    },
    newPenaltyFee: {
      permission: { type: 'PRODUCT', groups: ['PENALTY_RULE'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.penaltyFees.new,
      element: <NewPenaltyFeePage />,
    },
    editPenaltyFee: {
      permission: { type: 'PRODUCT', groups: ['PENALTY_RULE'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.productCatalog.penaltyFees.edit,
      element: <EditPenaltyFeePage />,
    },
  });
