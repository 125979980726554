import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material';

interface Props {
  shouldDisplay: boolean;
  isOpen: boolean;
}

export const ListItemExpandIcon: React.FC<Props> = ({ shouldDisplay, isOpen }) => {
  const theme = useTheme();

  if (!shouldDisplay) {
    return null;
  }

  return isOpen ? (
    <ExpandLess sx={{ fill: theme.palette.primary.contrastText }} />
  ) : (
    <ExpandMore sx={{ fill: theme.palette.primary.contrastText }} />
  );
};
