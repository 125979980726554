import React from 'react';

interface Props {
  src: HTMLImageElement['src'] | undefined;
}

export const MAX_LOGO_CSS_WIDTH = '12rem';

export const Logo: React.FC<Props> = ({ src }) => {
  if (!src) return null;

  return (
    <img
      src={src}
      style={{
        height: '48px',
        maxWidth: MAX_LOGO_CSS_WIDTH,
        objectFit: 'contain',
      }}
      alt="Logo"
    />
  );
};
