import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';

const InventoryTypePage = React.lazy(() => import('./inventory-type/InventoryTypePage'));
const LoadInventoryTypePage = React.lazy(() => import('./load-inventory/LoadInventoryTypePage'));

export const inventoryManagementRoutes = () =>
  new RoutesConfig({
    inventoryTypeList: {
      permission: { type: 'INVENTORY', actions: ['VIEW'] },
      path: routePaths.inventoryManagement.inventoryType.list,
      element: <InventoryTypePage />,
    },
    inventoryTypeLoad: {
      permission: { type: 'INVENTORY', actions: ['VIEW', 'EDIT'] },
      path: routePaths.inventoryManagement.inventoryType.load,
      element: <LoadInventoryTypePage />,
    },
  });
