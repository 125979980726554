import * as yup from 'yup';
export const nullableNumberSchema = yup
  .number()
  .nullable()
  .transform(val => (!isNaN(val) ? parseFloat(val) : undefined));

export const positiveNumberSchema = yup
  .number()
  .transform(val => (!isNaN(val) ? parseFloat(val) : undefined))
  .min(0, `common:errors.min-number{number:${0}}`);
export const requiredPositiveNumberSchema = positiveNumberSchema.required('common:errors.required');

export const isEmptyField = (value: unknown): boolean => value === undefined || value === null || value === '';
