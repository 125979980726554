import React from 'react';

import { Box } from '@mui/material';

import { AlertMessage } from '..';

interface Props {
  shouldDisplay?: boolean;
  gutterBottom?: boolean;
  id?: string;
}

export const FormFieldError: React.FC<React.PropsWithChildren<Props>> = ({
  shouldDisplay = false,
  gutterBottom = false,
  id,
  children,
}) => {
  if (!shouldDisplay) return null;

  return (
    <Box my={2} sx={{ ...(gutterBottom && { marginBottom: '2rem' }) }}>
      <AlertMessage id={id} severity="error" elevation={0}>
        {children}
      </AlertMessage>
    </Box>
  );
};
