import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentModal } from '../../modal';

import { PayPalCheckoutCard } from './components/PayPalCheckoutCard';
import { Stack } from '@mui/material';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js/types/components/buttons';

interface Props {
  open: boolean;
  omOrderId: string | undefined;
  onClose(): void;
  onPaymentApprove?: PayPalButtonsComponentOptions['onApprove'];
}

export const PayPalCheckoutModal: FC<Props> = ({ open, omOrderId, onClose, onPaymentApprove }) => {
  const { t } = useTranslation('common');

  if (!open || !omOrderId) return null;

  return (
    <ContentModal
      titleLabel={t('payments.pay-pal.checkout-modal.title')}
      cancelLabel={t('payments.pay-pal.checkout-modal.button.return')}
      isOpen={open}
      onCancel={onClose}
      minWidth="350px"
    >
      <Stack mb={1}>
        <PayPalCheckoutCard onPaymentApprove={onPaymentApprove} omOrderId={omOrderId} />
      </Stack>
    </ContentModal>
  );
};
