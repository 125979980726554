import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { ListRequestParams, ScrollableEntry, SuccessResponse } from '../types';
import {
  AddAccountBalanceParams,
  ChangeCustomerDataRequestParams,
  ChangeCustomerForm,
  ChangeInventoryRequestParams,
  ChangeProductParams,
  ChangeSubscriptionServicesParams,
  ChangeSubscriptionStatusForm,
  FailedPaymentUpdateParams,
  OrderListItem,
  OrderManagementOrder,
  PortInReversalParams,
  TerminateProductParams,
  TransferBalance,
} from './types';

export class OrderManagementApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async customerOrders(customerId: string, params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<OrderListItem>>(`/om/api/order/for-customer/${customerId}`, { params });
  }

  public async order(orderId: string) {
    return await this.axios.get<OrderManagementOrder>(`/om/api/order/${orderId}`);
  }

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<OrderListItem>>('/om/api/order', { params });
  }

  public async balanceTopUp(form: TransferBalance) {
    return await this.axios.post<SuccessResponse>(`/om/api/order/TOP_UP`, form);
  }

  public async changeInventory(params: ChangeInventoryRequestParams) {
    return await this.axios.post<SuccessResponse>(`om/api/order/CHANGE_INVENTORY`, params);
  }

  public async changeCustomerData(params: ChangeCustomerDataRequestParams) {
    return await this.axios.post<SuccessResponse>(`om/api/order/CHANGE_CUSTOMER_DATA?encrypt=true`, params);
  }

  public async changeSubscriptionStatus(params: ChangeSubscriptionStatusForm) {
    return await this.axios.post<SuccessResponse>('om/api/order/CHANGE_SUBSCRIPTION_STATUS', params);
  }

  public async portInReversal(params: PortInReversalParams) {
    return await this.axios.post<SuccessResponse>('om/api/order/PORT_IN_REVERSAL', params);
  }

  public async retryFailedOrder(orderId: string) {
    return await this.axios.post<OrderManagementOrder>(`/om/api/order/${orderId}/retry-failed`);
  }

  public async updatePayment(orderId: string, params: FailedPaymentUpdateParams) {
    return await this.axios.put<OrderManagementOrder>(`/om/api/order/${orderId}/update-payment`, params);
  }

  public async updateCustomer(orderId: string, params: ChangeCustomerForm) {
    return await this.axios.put<OrderManagementOrder>(`/om/api/order/${orderId}/update-customer`, params);
  }

  public async addAccountBalance(params: AddAccountBalanceParams) {
    return await this.axios.post<SuccessResponse>('/om/api/order/ADD_ACCOUNT_BALANCE', params);
  }

  public async changeProduct(params: ChangeProductParams) {
    return await this.axios.post<SuccessResponse>('/om/api/order/CHANGE_PRODUCT', params);
  }

  public async terminateProduct(params: TerminateProductParams) {
    return await this.axios.post<SuccessResponse>('/om/api/order/TERMINATE_PRODUCT', params);
  }

  public async subscriptionOrders(params: ListRequestParams, subscriptionId: string) {
    return await this.axios.get<ScrollableEntry<OrderListItem>>(`/om/api/order/for-subscription/${subscriptionId}`, {
      params,
    });
  }

  public async changeSubscriptionServices(params: ChangeSubscriptionServicesParams) {
    return await this.axios.post<SuccessResponse>('/om/api/order/CHANGE_SUBSCRIPTION_SERVICES', params);
  }

  public async exportOrders(params: ListRequestParams) {
    const { data } = await this.axios.get('/om/api/order/export', { params, responseType: 'blob' });
    fileDownload(data, 'Orders.csv');
  }
}
