import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from '.';
import { AlertProps } from '@mui/lab';
import { AxiosError, isAxiosError } from 'axios';

interface Props extends AlertProps {
  error: Error | AxiosError | undefined;
}

export const ResponseError: React.FC<Props> = ({ error, ...alertProps }) => {
  const { i18n, t } = useTranslation('common');

  if (!error || typeof error !== 'object') return null;

  const errorCode = isAxiosError(error) ? error?.code : error?.name;
  const message = i18n.exists(`response-errors.${errorCode}`)
    ? t(`response-errors.${errorCode}`)
    : typeof error?.message === 'string' && error.message;

  return (
    <AlertMessage severity="error" elevation={0} {...alertProps}>
      {message || t('error-warning')}
    </AlertMessage>
  );
};
