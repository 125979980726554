import React from 'react';

import { Theme, Typography, useMediaQuery } from '@mui/material';

interface Props {
  tenantName: string | undefined;
}

export const TenantName: React.FC<Props> = ({ tenantName }) => {
  const isDesktopView = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  if (!tenantName || !isDesktopView) return null;

  return (
    <Typography color="primary" noWrap>
      {tenantName}
    </Typography>
  );
};
