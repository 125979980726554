import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ExitToApp, ExpandLess, ExpandMore } from '@mui/icons-material';

import { useAuth } from '../../../app/auth';
import { TenantMenuItem } from './TenantMenuItem';
import { usePermissionsContext } from '../../../app/contexts';
import { LoadingButton } from '../../LoadingButton';

export const AuthButtons: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { user } = usePermissionsContext();
  const { isAuthenticated, login, logout, isLoading } = useAuth();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      {!isAuthenticated && (
        <LoadingButton color="primary" size="medium" aria-label="login" onClick={login} loading={isLoading}>
          <ExitToApp fontSize="large" /> {t('login')}
        </LoadingButton>
      )}
      {isAuthenticated && (
        <>
          <Button disableRipple onClick={handleOpenUserMenu}>
            {user?.username}
            {menuAnchorEl ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <Menu
            keepMounted
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={handleCloseUserMenu}
            transitionDuration={100}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            slotProps={{
              paper: {
                sx: { boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)' },
              },
            }}
          >
            <MenuItem divider disableGutters disableRipple dense sx={{ padding: 0 }}>
              <List disablePadding sx={{ width: '100%' }}>
                <ListItemButton disableRipple onClick={logout}>
                  <ListItemIcon sx={{ color: 'text.primary' }}>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary={t('logout')} />
                </ListItemButton>
              </List>
            </MenuItem>
            <TenantMenuItem onMenuClose={handleCloseUserMenu} />
          </Menu>
        </>
      )}
    </>
  );

  function handleOpenUserMenu({ currentTarget }: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchorEl(currentTarget);
  }

  function handleCloseUserMenu() {
    setMenuAnchorEl(null);
  }
});
