import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry, SuccessResponse, UpdateProductStatusParams } from '../types';
import { ApiOfferForm, Offer, OfferListItem } from './types';

export class OfferApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async offer(id: string) {
    return await this.axios.get<Offer>(`/product/api/offer/${id}`);
  }

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<OfferListItem>>('/product/api/offer', { params });
  }

  public async updateStatus({ id, productStatus }: UpdateProductStatusParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/offer/${id}/update-status`, { productStatus });
  }

  public async create(form: ApiOfferForm) {
    return await this.axios.post<SuccessResponse>('/product/api/offer', form);
  }

  public async update(id: string, form: ApiOfferForm) {
    return await this.axios.put<SuccessResponse>(`/product/api/offer/${id}`, form);
  }

  public async categories() {
    return await this.axios.get<OfferListItem[]>('/product/api/offer-category');
  }
}
