import { AxiosInstance } from 'axios';

import { BalanceResponse } from './types';

export class ProvisioningApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async balance(subscriptionId: string) {
    return await this.axios.get<BalanceResponse>(`/provisioning/api/subscription/${subscriptionId}/balance`);
  }
}
