import { AxiosInstance } from 'axios';

import { ScrollableEntry } from '../types';
import { Balance, BalanceListItem, BalanceListRequestParams, BalanceType } from './types';

export class AccountBalanceApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async balance(balanceType: BalanceType, balanceId: string) {
    return await this.axios.get<Balance>(`/account-balance/api/ledger/${balanceType}/${balanceId}/balance`);
  }

  public async balancesList(params: BalanceListRequestParams) {
    return await this.axios.get<ScrollableEntry<BalanceListItem>>(
      `/account-balance/api/ledger/${params.balanceType}/${params.balanceId}`,
      { params: params },
    );
  }
}
