import { AxiosInstance } from 'axios';

import { ProductComponentForm } from '../../product-catalog/components/common/validation';
import { ListRequestParams, ScrollableEntry, SuccessResponse, UpdateProductStatusParams } from '../types';
import { ProductComponentListItem, ProductComponentTypeById, ProductComponentTypeListItem } from './types';

export class ProductComponentApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<ProductComponentListItem>>('/product/api/product-component', {
      params,
    });
  }

  public async create(form: ProductComponentForm) {
    return await this.axios.post<SuccessResponse>('/product/api/product-component', form);
  }

  public async update(id: string, form: ProductComponentForm) {
    return await this.axios.put<SuccessResponse>(`/product/api/product-component/${id}`, form);
  }

  public async updateStatus({ id, productStatus }: UpdateProductStatusParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/product-component/${id}/update-status`, {
      productStatus,
    });
  }

  public async component(id: string) {
    return await this.axios.get<ProductComponentListItem>(`/product/api/product-component/${id}`);
  }

  public async componentType(id: string) {
    return await this.axios.get<ProductComponentTypeById>(`/product/api/product-component-type/${id}`);
  }

  public async componentTypes() {
    return await this.axios.get<ProductComponentTypeListItem[]>('/product/api/product-component-type');
  }
}
