import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Breadcrumbs as MUIBreadcrumbs, Link as MuiLink } from '@mui/material';

export interface BreadcrumbsLinkProps {
  path: string;
  label: string;
}

interface Props {
  links: BreadcrumbsLinkProps[];
}

export const Breadcrumbs: React.FC<Props> = ({ links }) => {
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {links.map(({ label, path }, idx) => (
        <MuiLink
          key={idx}
          to={path}
          component={RouterLink}
          color={isLast(idx) ? 'primary' : 'inherit'}
          underline="hover"
        >
          {label}
        </MuiLink>
      ))}
    </MUIBreadcrumbs>
  );

  function isLast(index: number) {
    return index + 1 === links.length;
  }
};
