import React from 'react';

import { Modal as MuiModal } from '@mui/material';

import { ActionModalLayout, Props as ActionModalLayoutProps } from './ActionModalLayout';

interface Props extends ActionModalLayoutProps {
  isOpen: boolean;
}

export const ActionModal: React.FC<React.PropsWithChildren<Props>> = ({ isOpen, ...rest }) => {
  return (
    <MuiModal
      aria-labelledby="action modal"
      aria-describedby="confirm your action modal"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={isOpen}
      onClose={rest.onClosed}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <ActionModalLayout {...rest} />
    </MuiModal>
  );
};
