import React, { useCallback, useContext, useState } from 'react';

import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import { Notification } from '../types';

interface NotificationContextValue {
  notification?: Notification;
  showNotification: (notification: Notification) => void;
  hideNotification: () => void;
}

const NotificationContext = React.createContext<NotificationContextValue>({
  showNotification: () => {},
  hideNotification: () => {},
});

export const useNotificationContext = () => {
  const { hideNotification, showNotification } = useContext(NotificationContext);

  return {
    success: (message: string) => showNotification({ type: 'success', message }),
    error: (message: string) => showNotification({ type: 'error', message }),
    warning: (message: string) => showNotification({ type: 'warning', message }),
    info: (message: string) => showNotification({ type: 'info', message }),
    hide: hideNotification,
  };
};

export const NotificationContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [notification, setNotification] = useState<Notification>();

  const showNotification = useCallback((notification: Notification) => setNotification(notification), []);
  const hideNotification = useCallback(() => setNotification(undefined), []);

  return (
    <NotificationContext.Provider value={{ notification, showNotification, hideNotification }}>
      {children}
      <Snackbar
        open={!!notification}
        autoHideDuration={notification?.duration ?? 5000}
        onClose={hideNotification}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={hideNotification} severity={notification?.type}>
          {notification?.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
