import { FC } from 'react';

import { getDefaultOptions } from 'date-fns';

import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const MuiDateCalendar: FC<DateCalendarProps<string | Date>> = props => {
  const { locale }: { locale?: Locale } = getDefaultOptions();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DateCalendar {...props} />
    </LocalizationProvider>
  );
};
