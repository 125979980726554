import { AxiosInstance } from 'axios';
import { ListRequestParams, ProductStatus, ScrollableEntry, SuccessResponse } from '../types';
import { ChargeCodeCategoryListItem, CreateChargeCodeParams, ChargeCodeListItem } from './types';

export class ChargeCodeApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<ChargeCodeListItem>>('/product/api/charge-code', { params });
  }

  public async createChargeCode(params: CreateChargeCodeParams) {
    return await this.axios.post<SuccessResponse>('/product/api/charge-code', params);
  }

  public async updateChargeCode(id: string, params: CreateChargeCodeParams) {
    return await this.axios.put<SuccessResponse>(`/product/api/charge-code/${id}`, params);
  }

  public async chargeCode(id: string) {
    return await this.axios.get<ChargeCodeListItem>(`/product/api/charge-code/${id}`);
  }

  public async listChargeCodeCategories() {
    return await this.axios.get<ChargeCodeCategoryListItem[]>('/product/api/charge-code-category');
  }

  public async updateChargeCodeStatus(id: string, productStatus: ProductStatus) {
    return await this.axios.put<SuccessResponse>(`/product/api/charge-code/${id}/update-status`, {
      productStatus,
    });
  }
}
