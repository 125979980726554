import React from 'react';

import { Box, Stack } from '@mui/material';

import { AlertMessage, Breadcrumbs, BreadcrumbsLinkProps, OverlayedLoader } from './';

interface Props {
  error?: string | object;
  loading?: boolean;
  breadcrumbsLinks?: BreadcrumbsLinkProps[];
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
}

export const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  error,
  loading = false,
  breadcrumbsLinks,
  flexDirection = 'column',
  justifyContent = 'flex-start',
  alignItems = 'center',
}) => {
  return (
    <Stack height="100%" overflow="auto" p={3} sx={{ scrollbarGutter: 'stable' }}>
      {!!breadcrumbsLinks?.length && !loading && (
        <Box mb={1}>
          <Breadcrumbs links={breadcrumbsLinks} />
        </Box>
      )}
      <Stack
        height="100%"
        spacing={2}
        direction={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        {loading || error ? null : children}
      </Stack>
      {error && (
        <Box width="100%">
          <AlertMessage severity="error">{typeof error === 'string' ? error : (error as any)?.message}</AlertMessage>
        </Box>
      )}
      <OverlayedLoader open={loading} />
    </Stack>
  );
};
