import React from 'react';
import { LoadingButton as MuiLoadingButton, LoadingButtonProps } from '@mui/lab';

import { Box, BoxProps } from '@mui/material';

interface Props extends LoadingButtonProps {
  isLoading?: boolean;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  alignSelf?: BoxProps['alignSelf'];
}

export const LoadingButton: React.FC<Props> = ({
  children,
  isLoading = false,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  alignSelf,
  ...props
}) => {
  return (
    <Box
      alignSelf={alignSelf}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      <MuiLoadingButton fullWidth loading={isLoading} {...props}>
        <span>{children}</span>
      </MuiLoadingButton>
    </Box>
  );
};
