import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

import { SelectOption } from '../../app/types';
import { interpolatedTranslation } from '../../app/i18n';
import { useTranslation } from 'react-i18next';

interface Props extends RadioGroupProps {
  required?: boolean;
  name: string;
  options: SelectOption[];
  label?: React.ReactNode;
  disabled?: boolean;
}

export const RadioGroupOptions: React.FC<Props> = ({
  required = false,
  disabled,
  name,
  options,
  label,
  ...radioGroupProps
}) => {
  const { t, i18n } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      rules={{ required }}
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          {!!label && (
            <FormLabel error={!!error} required={required} sx={{ fontSize: 12 }}>
              {label}
            </FormLabel>
          )}
          <RadioGroup {...field} {...radioGroupProps}>
            {options?.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                disabled={disabled}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText variant="standard" error={!!error}>
            {error?.message &&
              i18n.exists(...interpolatedTranslation(error.message)) &&
              t(...interpolatedTranslation(error.message))}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
