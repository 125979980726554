import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';

import { dateComparator, formatDate } from '../../business/date';
import { CellRenderer, GridColumn, GridColumnParams, GridFilterOperation } from './types';

const ENCODED_NULL_VALUE = '{null}';

export const gridCheckboxColumn = <T extends {}>(): GridColumn<T> => {
  return {
    type: 'string',
    headerName: '',
    field: 'id' as GridColumn<T>['field'],
    valueFormatter: () => '',
    checkboxSelection: true,
    suppressFiltersToolPanel: true,
    suppressMenu: true,
    maxWidth: 50,
  };
};

export const gridColumn = <T extends {}>({
  field,
  headerName,
  type,
  sort,
  suppressMenu,
  onlyEqualsFilter,
  onClicked,
  width,
  ...props
}: GridColumnParams<T> & Partial<GridColumn<T>>): GridColumn<T> => {
  const clickable: Pick<GridColumn<T>, 'cellRenderer' | 'cellRendererParams'> | {} = onClicked
    ? {
        cellRenderer: CellRenderer.Button,
        cellRendererParams: { context: { onClicked } },
      }
    : {};

  const stringColumn: GridColumn<T> = {
    field: parseFieldName(field),
    headerName,
    type: 'string',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: onlyEqualsFilter
        ? [GridFilterOperation.Equals]
        : [GridFilterOperation.Contains, GridFilterOperation.Equals],
      defaultOption: onlyEqualsFilter ? GridFilterOperation.Equals : GridFilterOperation.Contains,
      buttons: ['clear'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      newRowsAction: 'keep',
    },
    valueFormatter: ({ value }: any) => (value !== ENCODED_NULL_VALUE ? value : ''),
    suppressMenu,
    sort,
    width,
    maxWidth: width,
    ...props,
    ...clickable,
  };

  const dateColumn: GridColumn<T> = {
    field: parseFieldName(field),
    headerName,
    type: 'date',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: onlyEqualsFilter
        ? [GridFilterOperation.Equals]
        : [
            GridFilterOperation.Equals,
            GridFilterOperation.LessThan,
            GridFilterOperation.GreaterThan,
            GridFilterOperation.InRange,
          ],
      defaultOption: GridFilterOperation.Equals,
      comparator: dateComparator,
      browserDatePicker: true,
      inRangeInclusive: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep',
      buttons: ['clear'],
    },
    suppressFiltersToolPanel: true,
    suppressMenu: suppressMenu === false ? false : true,
    valueGetter: Array.isArray(field) ? (params: any) => field.map(name => params.data?.[name]) : undefined,
    filterValueGetter: Array.isArray(field) ? (params: any) => field.map(name => params.data?.[name]) : undefined,
    valueFormatter: ({ value }: any) =>
      Array.isArray(field) && Array.isArray(value) ? value.map(d => formatDate(d)).join(' - ') : formatDate(value),
    sort,
    width,
    maxWidth: width,
    ...props,
    ...clickable,
  };

  switch (type) {
    case 'string':
      return stringColumn;
    case 'number':
      return {
        field: parseFieldName(field),
        headerName,
        type: 'number',
        cellEditor: 'numericCellEditor',
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: onlyEqualsFilter
            ? [GridFilterOperation.Equals]
            : [
                GridFilterOperation.Equals,
                GridFilterOperation.LessThan,
                GridFilterOperation.GreaterThan,
                GridFilterOperation.InRange,
              ],
          defaultOption: GridFilterOperation.Equals,
          inRangeInclusive: true,
          buttons: ['clear'],
          suppressAndOrCondition: true,
          newRowsAction: 'keep',
        },
        sort,
        width,
        maxWidth: width,
        ...props,
        ...clickable,
      };
    case 'date':
      return dateColumn;
    case 'dateTime':
      return {
        ...dateColumn,
        type: 'dateTime',
        valueFormatter: ({ value }: any) => formatDate(value, 'DateWithTime'),
      };
    case 'datePreciseTime':
      return {
        ...dateColumn,
        type: 'datePreciseTime',
        valueFormatter: ({ value }: any) => formatDate(value, 'DateWithPreciseTime'),
      };
    default:
      return stringColumn;
  }
};

export function gridColumnSelectFilterExtension<T>(
  column: GridColumn<T>,
  values: string[],
  translate: (key: string) => string,
): GridColumn<T> {
  const t = (value: string) => (value && value !== ENCODED_NULL_VALUE ? translate(value) : '');

  return {
    ...column,
    type: 'string',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(values).sort(),
      cellRenderer: ({ value }: ValueFormatterParams) => t(value),
      debounceMs: 500,
      suppressSorting: true,
      suppressMiniFilter: true,
      suppressSelectAll: true,
      defaultToNothingSelected: true,
    },
    valueFormatter: ({ value }: any) => t(value),
  };
}

export const gridActionColumn = (width?: number): ColDef => ({
  colId: 'actionColumn',
  lockVisible: true,
  lockPinned: true,
  sortable: false,
  resizable: false,
  filter: false,
  cellRenderer: CellRenderer.Action,
  cellStyle: { paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', border: 'none' },
  suppressMenu: true,
  suppressAutoSize: true,
  suppressMovable: true,
  suppressFiltersToolPanel: true,
  suppressColumnsToolPanel: true,
  suppressPaste: true,
  suppressSizeToFit: true,
  minWidth: width ?? 200,
  maxWidth: width ?? 200,
  width,
});

const parseFieldName = <T>(field: GridColumnParams<T>['field']): GridColumnParams<T>['field'] =>
  Array.isArray(field) ? (`${field}` as GridColumnParams<T>['field']) : field;
