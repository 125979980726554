import {
  ColumnState,
  DateFilterModel,
  ISimpleFilterModel,
  NumberFilterModel,
  TextFilterModel,
} from '@ag-grid-community/core';

import { dateToIsoDate, normalizeDate } from '../../business/date';
import { gridFilterOperationMap } from './config';
import { FilterOperation, GridColumn, GridFilterOperation, GridFilters, MultiValueFilterModel } from './types';

const ENCODED_COMMA = '{{comma}}';

export const parseSortModel = (sortModel: ColumnState[]) =>
  sortModel.reduce<string>((prev, curr) => (prev ? `${prev},` : '' + Object.values(curr).reverse().toString()), '');

export const parseFilterModel = (filterModel: Record<string, ISimpleFilterModel>): string[] =>
  Object.entries(filterModel).reduce<string[]>((acc, [field, model]) => {
    const fieldNames = field.includes(',') ? field.split(',') : [field];
    const { type, filterType } = model;
    const operation = gridFilterOperationMap[type as GridFilterOperation];

    if (filterType === 'text') {
      const { filter } = model as TextFilterModel;
      const query = fieldNames.map(name => [name, operation, filter ? encodeFilterValue(filter) : filter].join(','));

      return [...acc, ...query];
    }

    if (filterType === 'date') {
      const { dateFrom, dateTo } = model as DateFilterModel;
      const query = fieldNames.map(name =>
        extractDefinedValues([
          name,
          operation,
          dateFrom ? dateToIsoDate(normalizeDate(new Date(dateFrom))) : null,
          dateTo ? dateToIsoDate(normalizeDate(new Date(dateTo))) : null,
        ]).join(','),
      );

      return [...acc, ...query];
    }

    if (filterType === 'number') {
      const { filter, filterTo } = model as NumberFilterModel;
      const query = fieldNames.map(name => extractDefinedValues([name, operation, filter, filterTo]).join(','));

      return [...acc, ...query];
    }

    if (filterType === 'set') {
      const { values } = model as MultiValueFilterModel;
      const query = fieldNames.map(name =>
        [name, FilterOperation.In, values.map(value => encodeFilterValue(value)).join(',')].join(','),
      );

      return values.length ? [...acc, ...query] : acc;
    }

    return acc;
  }, []);

const extractDefinedValues = (data: Array<string | number | null | undefined>): string[] =>
  data.filter(val => val !== undefined && val !== null).map(v => v?.toString()) as string[];

const replaceAll = (value: string, search: string, replacement?: string): string =>
  value.split(search).join(replacement);

export const encodeFilterValue = (value: string): string =>
  replaceAll(replaceAll(value, ENCODED_COMMA), ',', ENCODED_COMMA);

export const decodeFilterValue = (value: string): string => replaceAll(value, ENCODED_COMMA, ',');

export const parseDefaultFilters = <T>(
  filters: GridFilters<T>,
  columns: Array<GridColumn<T>>,
): Record<string, ISimpleFilterModel> => {
  return Object.entries(filters).reduce((acc, entry) => {
    const [field, model] = entry as [keyof T, GridFilters<T>[keyof T]];
    const fieldType = columns.find(c => c.field === field)?.type;
    if (!model) {
      return { ...acc };
    }
    if (fieldType === 'string') {
      const { filter } = model.value as TextFilterModel;

      return { ...acc, [field]: { type: model?.type, filterType: fieldType, filter } };
    }
    if (fieldType === 'date' || fieldType === 'dateTime' || fieldType === 'datePreciseTime') {
      const { dateFrom, dateTo } = model.value as DateFilterModel;

      return { ...acc, [field]: { type: model?.type, filterType: fieldType, dateFrom, dateTo } };
    }
    if (fieldType === 'number') {
      const { filter, filterTo } = model.value as NumberFilterModel;

      return { ...acc, [field]: { type: model?.type, filterType: fieldType, filter, filterTo } };
    }

    return { ...acc };
  }, {});
};
