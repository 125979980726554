import { PermissionType } from '../business/permissions';
import { TenantListItem } from './tenant/types';

export type ErrorType = 'login_required';

export interface ListRequestParams {
  startRow?: number;
  endRow?: number;
  sort?: string;
  filter?: string[];
}

export interface ScrollableEntry<T> {
  content: T[];
  startRow: number;
  endRow: number;
  lastRow?: number;
}

export interface ConsumerSuccessResponse {
  version: string;
  requestId: string;
  message: string;
}

export interface SuccessResponse {
  id: string;
  tenantId: string;
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Draft = 'DRAFT',
  Released = 'RELEASED',
  Retired = 'RETIRED',
  Suspend = 'SUSPEND',
  Terminated = 'TERMINATED',
  NotAvailable = 'NOT_AVAILABLE',
}

export interface UpdateProductStatusParams {
  id: string;
  productStatus: ProductStatusForm['productStatus'];
}

export interface ProductStatusForm {
  productStatus: ProductStatus.Draft | ProductStatus.Released | ProductStatus.Retired;
}

export type ClassifierValue = string;

export interface ClassifierEntry<T extends string> {
  id: T;
  values: Array<ClassifierEntry<any>>;
}

export interface Tenant extends TenantListItem {
  modules: TenantModules;
}

export type TenantModules = { [key in PermissionType]: { enabled: boolean; features: any } };

export interface ErrorValidation {
  message: Message;
}

interface Message {
  response: Response;
}

interface Response {
  fieldErrors: FieldError[];
  status: number;
  statusText: string;
}

export interface FieldError {
  codes: string[];
  arguments: Argument[];
  defaultMessage: string;
  objectName: string;
  field: string;
  rejectedValue: Argument;
  bindingFailure: boolean;
  code: string;
}

export interface ErrorWithFieldErrors {
  message: string;
  name: string;
  fieldErrors?: FieldError[];
  stack?: string;
}

interface Argument {}

export enum SourceSystem {
  OM = 'OM',
  Payment = 'PAYMENT',
  Provisioning = 'PROVISIONING',
  Billing = 'BILLING',
  Task = 'TASK',
  Digital = 'DIGITAL',
  USSD = 'USSD',
  POS = 'POS',
  CRM = 'CRM',
  Ticket = 'TICKET',
  Inventory = 'INVENTORY',
  Notification = 'NOTIFICATION',
  Unknown = 'UNKNOWN',
}
