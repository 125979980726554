import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../app/types';
import { ClassifierEntry } from '../../api/types';
import { useApplicationContext } from '../../app/contexts';
import { selectedClassifiers, SOURCE_SYSTEM } from '../../business';
import { POS_CLASSIFIER, PosClassifierEntry } from '../../api/pos-classifier/types';
import { CRM_CLASSIFIER, CrmClassifierEntry } from '../../api/crm-classifier/types';
import { SelectField, SelectFieldProps } from './';

interface Props extends Omit<SelectFieldProps, 'options'> {
  country: string;
  sourceSystem: SOURCE_SYSTEM;
}

export const DocumentIdentityTypeSelect: FC<Props> = ({ country, sourceSystem, ...selectProps }) => {
  const { t } = useTranslation();
  const appClassifiers = useApplicationContext();
  const identityTypesBySystem = getIdentityClassifiersBySystem(sourceSystem, appClassifiers);
  const identityTypesByCountry = getIdentityClassifiersByCountry(country, identityTypesBySystem);
  const identityTypeOptions: SelectOption[] = identityTypesByCountry?.map(c => ({
    value: c.id,
    label: t(`entries:classifiers.identity-types.${c.id}`),
  }));

  return <SelectField {...selectProps} options={identityTypeOptions} />;
};

function getIdentityClassifiersBySystem(
  system: SOURCE_SYSTEM,
  { crmClassifiers, posClassifiers }: { crmClassifiers: CrmClassifierEntry[]; posClassifiers: PosClassifierEntry[] },
) {
  if (system === 'CRM') {
    return selectedClassifiers(crmClassifiers, CRM_CLASSIFIER.IDENTITY_TYPE);
  }
  if (system === 'POS') {
    return selectedClassifiers(posClassifiers, POS_CLASSIFIER.IDENTITY_TYPE);
  }
  return [];
}

function getIdentityClassifiersByCountry(
  country: string,
  identityTypesBySystem: Array<ClassifierEntry<string | 'default'>>,
) {
  const identityTypesByCountry = identityTypesBySystem.find(classifier => classifier.id === country);

  if (identityTypesByCountry?.values?.length) return identityTypesByCountry.values;

  return identityTypesBySystem.find(classifier => classifier.id === 'default')?.values || [];
}
