import { ThemeOptions } from '@mui/material';

import { GITHUB_ENVIRONMENT_NAME } from '../../app/environments';

import defaultOcbLogo from './images/ocb.png';
import {
  OCB_DOCUMENT_TITLE_BY_TENANT_MAP,
  OCB_LOGO_BY_TENANT_MAP,
  OCB_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/demo/themeDemo';
import { MMD_PROD_LOGO_BY_TENANT_MAP, MMD_PROD_THEME_OPTIONS_BY_TENANT_MAP } from './instances/mmd-prod/themeMmdProd';
import {
  MMD_STAGING_LOGO_BY_TENANT_MAP,
  MMD_STAGING_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/mmd-staging/themeMmdStaging';
import { defaultOcbThemeOptions, defaultTypography } from './defaultThemeOptions';
import {
  STAGING_DOCUMENT_TITLE_BY_TENANT_MAP,
  STAGING_LOGO_BY_TENANT_MAP,
  STAGING_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/staging/themeStaging';
import {
  PROD_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  PROD_LOGO_BY_TENANT_MAP,
  PROD_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/prod/themeProd';
import {
  PROD_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  PROD_US_LOGO_BY_TENANT_MAP,
  PROD_US_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/prod-us/themeProdUs';
import {
  DEMO_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  DEMO_US_LOGO_BY_TENANT_MAP,
  DEMO_US_THEME_OPTIONS_BY_TENANT_MAP,
} from './instances/demo-us/themeDemoUs';

export const MUI_APP_BAR_HEIGHT = 64;
export const drawerWidthExpanded = 320;
export const drawerWidthClosed = 56;

export const THEME_OPTIONS_BY_ENVIRONMENT: Record<GITHUB_ENVIRONMENT_NAME, Record<string, ThemeOptions>> = {
  [GITHUB_ENVIRONMENT_NAME.DEV]: OCB_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO]: OCB_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO_US]: DEMO_US_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.E2E]: OCB_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.STAGING]: STAGING_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD]: PROD_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD_US]: PROD_US_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.MMD_STAGING]: MMD_STAGING_THEME_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.MMD_PROD]: MMD_PROD_THEME_OPTIONS_BY_TENANT_MAP,
};

export const TENANT_LOGO_BY_ENVIRONMENT: Record<GITHUB_ENVIRONMENT_NAME, Record<string, string>> = {
  [GITHUB_ENVIRONMENT_NAME.DEV]: OCB_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO]: OCB_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO_US]: DEMO_US_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.E2E]: OCB_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.STAGING]: STAGING_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD]: PROD_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD_US]: PROD_US_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.MMD_STAGING]: MMD_STAGING_LOGO_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.MMD_PROD]: MMD_PROD_LOGO_BY_TENANT_MAP,
};

export const TENANT_DOCUMENT_TITLE_BY_ENVIRONMENT: Record<GITHUB_ENVIRONMENT_NAME, Record<string, string>> = {
  [GITHUB_ENVIRONMENT_NAME.DEV]: OCB_DOCUMENT_TITLE_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO]: OCB_DOCUMENT_TITLE_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.DEMO_US]: DEMO_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.E2E]: OCB_DOCUMENT_TITLE_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.STAGING]: STAGING_DOCUMENT_TITLE_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD]: PROD_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.PROD_US]: PROD_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP,
  [GITHUB_ENVIRONMENT_NAME.MMD_STAGING]: {},
  [GITHUB_ENVIRONMENT_NAME.MMD_PROD]: {},
};

export const getTenantThemeOptions = (tenantId: string | undefined) => {
  const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

  if (!environment || typeof tenantId !== 'string') {
    return defaultOcbThemeOptions;
  }

  const themeOptions = THEME_OPTIONS_BY_ENVIRONMENT[environment as GITHUB_ENVIRONMENT_NAME] || {};
  const themeOptionsMap = new Map(Object.entries(themeOptions));
  const themePalette = themeOptionsMap.has(tenantId) ? themeOptionsMap.get(tenantId) : defaultOcbThemeOptions;

  return {
    ...themePalette,
    typography: defaultTypography,
  };
};

export const getTenantLogo = (tenantId: string | undefined) => {
  const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

  if (!environment || typeof tenantId !== 'string') {
    return defaultOcbLogo;
  }

  const tenantsLogo = TENANT_LOGO_BY_ENVIRONMENT[environment as GITHUB_ENVIRONMENT_NAME] || {};
  const tenantsLogoMap = new Map(Object.entries(tenantsLogo));

  return tenantsLogoMap.has(tenantId) ? tenantsLogoMap.get(tenantId) : defaultOcbLogo;
};

export const getTenantDocumentTitle = (tenantId: string | undefined) => {
  const environment = process.env.REACT_APP_ENVIRONMENT_NAME;
  const defaultOcbTitle = 'OCB';
  if (!environment || typeof tenantId !== 'string') return defaultOcbTitle;
  const tenantsDocumentTitleByInstance =
    TENANT_DOCUMENT_TITLE_BY_ENVIRONMENT[environment as GITHUB_ENVIRONMENT_NAME] || {};
  const tenantsDocumentTitleMap = new Map(Object.entries(tenantsDocumentTitleByInstance));
  const documentTitleFromMap = tenantsDocumentTitleMap.has(tenantId)
    ? tenantsDocumentTitleMap.get(tenantId)
    : defaultOcbTitle;
  return documentTitleFromMap || defaultOcbTitle;
};
