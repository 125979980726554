import React, { useMemo } from 'react';
import { Params } from 'react-router-dom';

import { Backdrop, Box, Stack } from '@mui/material';

import { Loader } from '../Loader';
import { useWizardStepperParams } from './hooks';
import { WizardStepper } from './WizardStepper';

interface StepActions {
  onBack: () => void;
  onNext: () => void;
  toStep: (stepIndex: number) => void;
  onOrderIdUpdate: (orderId: string) => void;
}

interface Props {
  loading?: boolean;
  allowAnyStepNavigation?: boolean;
  steps: (actions: StepActions) => Array<{ index: number; title: string; component: JSX.Element }>;
}

export interface WizardStepProps {
  onBack: () => void;
  onNext: () => void;
  toStep: (stepIndex: number) => void;
  onOrderIdUpdate: (orderId: string) => void;
}

export interface WizardStepRouteParams extends Params {
  orderId: string;
}

export const Wizard: React.FC<React.PropsWithChildren<Props>> = ({
  steps,
  loading = false,
  allowAnyStepNavigation,
}) => {
  const { activeStep, navigateToStep, navigateToNextStep, navigateToPrevStep, onOrderIdUpdate } =
    useWizardStepperParams(allowAnyStepNavigation);
  const stepsToDisplay = useMemo(
    () =>
      steps({ onBack: navigateToPrevStep, onNext: navigateToNextStep, toStep: navigateToStep, onOrderIdUpdate }).sort(
        (a, b) => a.index - b.index,
      ),
    [steps, navigateToPrevStep, navigateToNextStep, navigateToStep, onOrderIdUpdate],
  );
  const step = stepsToDisplay.find(({ index }) => index === activeStep)?.component ?? stepsToDisplay[0]?.component;

  return (
    <Stack p={3} height="100%">
      <Box height="5%" width="100%">
        <WizardStepper steps={stepsToDisplay.map(step => step.title)} allowAnyStep={allowAnyStepNavigation} />
      </Box>
      <Box height="95%" width="100%">
        {loading ? null : step}
        <Backdrop open={loading}>
          <Loader size="large" />
        </Backdrop>
      </Box>
    </Stack>
  );
};
