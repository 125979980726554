import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '@mui/material';

import { LoadingButton } from '../LoadingButton';

interface Props {
  nextDisabled?: boolean;
  nextLoading?: boolean;
  nextLabel?: string;
  backLabel?: string;
  backDisabled?: boolean;
  justify?: 'flex-end' | 'flex-start' | 'center' | 'space-evenly' | 'space-around' | 'space-between';
  type?: 'button' | 'submit' | 'reset';
  onNext?(): void;
  onBack(): void;
}

export const WizardActions: React.FC<React.PropsWithChildren<Props>> = ({
  nextDisabled,
  nextLoading,
  nextLabel,
  backLabel,
  backDisabled,
  type = 'submit',
  onNext,
  onBack,
}) => {
  const { t } = useTranslation('common');

  return (
    <Stack direction="row" justifyContent="flex-end" gap={1}>
      <Button name="back" color="primary" variant="outlined" disabled={backDisabled} onClick={onBack}>
        {backLabel || t('back')}
      </Button>
      <LoadingButton
        name="next"
        color="primary"
        variant="contained"
        onClick={onNext}
        isLoading={nextLoading}
        disabled={nextDisabled}
        type={onNext ? 'button' : type}
      >
        {nextLabel || t('next')}
      </LoadingButton>
    </Stack>
  );
};
