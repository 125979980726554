import React from 'react';
import { Controller, Path, PathValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  useTheme,
} from '@mui/material';

import { interpolatedTranslation } from '../../app/i18n';
import { SelectOption } from '../../app/types';
import { EMPTY_STR_OPTION } from './SelectField';

export interface MultipleSelectFieldProps<T = Record<string, any>> {
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  disabled?: boolean;
  isRequired?: boolean;
  label?: string;
  options: SelectOption[];
  variant?: SelectProps['variant'];
  onChanged?(value: string[]): void;
  marginBottom?: number;
}

export const MultipleSelectField = <T extends Record<string, any>>({
  name,
  label,
  options,
  defaultValue,
  disabled = false,
  isRequired = false,
  onChanged,
  variant = 'standard',
}: MultipleSelectFieldProps<T>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ([EMPTY_STR_OPTION.value] as unknown as typeof defaultValue)}
        rules={{ required: isRequired }}
        render={({ field, fieldState: { error } }) => (
          <FormControl
            variant={variant}
            sx={{
              flex: 1,
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                '& label': {
                  fontSize: 12,
                },
              },
            }}
          >
            {label && (
              <InputLabel disabled={disabled} required={isRequired} error={!!error}>
                {label}
              </InputLabel>
            )}
            <Select
              {...field}
              id={name}
              label={label}
              name={name}
              fullWidth
              error={!!error}
              disabled={disabled}
              required={isRequired}
              multiple
              renderValue={(selected: string[]) => (
                <Stack direction="row" gap={0.5} overflow="hidden">
                  {selected?.map((selectedValue: string, index) => (
                    <Chip
                      key={index}
                      label={options?.find(option => option?.value === selectedValue)?.label}
                      size="small"
                    />
                  ))}
                </Stack>
              )}
              onChange={e => {
                field.onChange(e as React.ChangeEvent);
                handleChange(e);
              }}
              MenuProps={{ sx: { maxWidth: 300 } }}
              variant={variant}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value} sx={{ whiteSpace: 'normal' }} dense>
                  <Checkbox
                    checked={
                      Array.isArray(field?.value)
                        ? field?.value?.some((selectedValue: string) => selectedValue === value)
                        : false
                    }
                  />
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!error?.message && (
              <FormHelperText error={!!error} sx={{ marginLeft: 0 }}>
                {t(...interpolatedTranslation(error.message))}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );

  function handleChange(event: SelectChangeEvent<string[]>) {
    const value = event.target.value;
    onChanged && onChanged(Array.isArray(value) ? value : [value]);
  }
};
