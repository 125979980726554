export const defaultOcbThemeOptions = {
  palette: {
    primary: {
      main: '#035da1',
      light: '#E8F5FF',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#4eb83a',
      light: '#71a05d',
      dark: '#2e7d32',
      contrastText: '#f0f0f0',
    },
    text: {
      primary: '#212121',
    },
    success: {
      main: '#4eb83a',
    },
    error: {
      main: '#ed1c24',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
    border: {
      main: 'rgba(0, 0, 0, 0.12)',
    },
  },
};

export const defaultTypography = {
  h1: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  h2: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  h3: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  h4: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  h5: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  h6: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  body1: {
    letterSpacing: 'normal',
  },
  body2: {
    letterSpacing: 'normal',
  },
  button: {
    letterSpacing: 'normal',
  },
  caption: {
    letterSpacing: 'normal',
  },
  subtitle1: {
    letterSpacing: 'normal',
  },
  subtitle2: {
    letterSpacing: 'normal',
  },
  fontFamily: [
    '"IBM Plex Sans"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};
