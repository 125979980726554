import { AxiosInstance } from 'axios';

import { SuccessResponse } from '../types';
import {
  AddCommonBalanceGroupParams,
  AddOrderOfferParams,
  CommonBalanceGroupMember,
  Compliance,
  ExistingProductParams,
  IAddProductFieldValues,
  IOrderDocuments,
  ModifySubscriptionParams,
  Order,
  ORDER_TYPE,
  OrderConsent,
  OrderCustomer,
  OrderResource,
  PosPayment,
  SetTerminatingProductParams,
} from './types';

export class OrderApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async createOrder(orderType: ORDER_TYPE) {
    return await this.axios.post<SuccessResponse>('/pos/api/order', { orderType });
  }

  public async order(orderId: string) {
    return await this.axios.get<Order>(`/pos/api/order/${orderId}`);
  }
  public async setTerminatingProduct(orderId: string, params: SetTerminatingProductParams) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/terminating-product`, params);
  }

  public async createPayment(orderId: string, paymentData: PosPayment) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/payment`, paymentData);
  }

  public async submitOrder(orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/submit`);
  }

  public async createCustomer(customerData: OrderCustomer, orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/customer`, customerData);
  }

  public async addExistingCustomer(customerId: string, orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/existing-customer`, { customerId });
  }

  public async addOrderOffers(params: AddOrderOfferParams, orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/offers`, params);
  }

  public async addAddonOffer(orderId: string, offerId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/add-on-offer`, { offerId });
  }

  public async addResources(resources: OrderResource[], orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/resources`, resources);
  }

  public async setConsents(consentData: OrderConsent, orderId: string) {
    return await this.axios.post<SuccessResponse>(`pos/api/order/${orderId}/consents`, consentData);
  }

  public async setExistingProduct(orderId: string, params: ExistingProductParams) {
    return await this.axios.post<SuccessResponse>(`pos/api/order/${orderId}/existing-product`, params);
  }

  public async modifySubscription(orderId: string, params: ModifySubscriptionParams) {
    return await this.axios.post<SuccessResponse>(`pos/api/order/${orderId}/modify-subscription`, params);
  }

  public async removeOrderResources(orderId: string) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/remove-resources`);
  }

  public async addProductFieldValues(orderId: string, params: IAddProductFieldValues[]) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/product-fields`, params);
  }

  public async getCommonBalanceGroupMembers(orderId: string) {
    return await this.axios.get<CommonBalanceGroupMember[]>(`/pos/api/order/${orderId}/cug-member`);
  }

  public async addCommonBalanceGroup(orderId: string, params: AddCommonBalanceGroupParams[]) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/cug-member`, params);
  }

  public async removeCommonBalanceGroupMember(orderId: string, params: AddCommonBalanceGroupParams[]) {
    return await this.axios.post<SuccessResponse>(`/pos/api/order/${orderId}/remove-cug-member`, params);
  }

  public async complianceList() {
    return await this.axios.get<Compliance[]>('/pos/api/compliance/');
  }

  public async getOrderDocuments(orderId: string) {
    return await this.axios.get<IOrderDocuments>(`/pos/api/order/${orderId}/documents`);
  }

  public async updateOrderDocuments(orderId: string, documents: IOrderDocuments) {
    return await this.axios.put<SuccessResponse>(`/pos/api/order/${orderId}/documents`, documents);
  }
}
