import { AxiosInstance } from 'axios';

import { SuccessResponse } from '../types';
import { CreateOrganizationData, EditOrganizationData, Organization, UpdateOrganizationStatusData } from './types';

export class OrganizationsApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async rootOrganisations() {
    return await this.axios.get<Organization[]>('/sso/api/organisation');
  }

  public async childOrganisations(parentId: string) {
    return await this.axios.get<Organization[]>(`/sso/api/organisation/${parentId}`);
  }

  public async createOrganisation(data: CreateOrganizationData) {
    return await this.axios.post<SuccessResponse>('/sso/api/organisation', data);
  }

  public async editOrganisation({ organisationId, ...data }: EditOrganizationData) {
    return await this.axios.put<SuccessResponse>(`/sso/api/organisation/${organisationId}`, data);
  }

  public async updateOrganisationStatus({ organisationId, status }: UpdateOrganizationStatusData) {
    return await this.axios.put<SuccessResponse>(`/sso/api/organisation/${organisationId}/status`, { status });
  }
}
