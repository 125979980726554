import React, { PropsWithChildren, useMemo } from 'react';

import { Drawer as MuiDrawer, useTheme } from '@mui/material';

import { drawerWidthClosed, drawerWidthExpanded } from '../../theme';

interface Props {
  isOpen: boolean;
}

export const Drawer: React.FC<PropsWithChildren<Props>> = React.memo(({ isOpen, children }) => {
  const theme = useTheme();
  const drawerDynamicStyles = useMemo(
    () => ({
      ...(isOpen && {
        width: drawerWidthExpanded,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(!isOpen && {
        width: drawerWidthClosed,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }),
    }),
    [isOpen, theme.transitions],
  );

  return (
    <MuiDrawer
      variant="permanent"
      sx={drawerDynamicStyles}
      PaperProps={{
        sx: {
          border: 'none',
          overflowX: 'hidden',
          background: theme.palette.primary.main,
          boxShadow: '2px 0px 30px 0px rgba(0, 0, 0, 0.16)',
          ...drawerDynamicStyles,
        },
      }}
    >
      {children}
    </MuiDrawer>
  );
});
