import { AxiosInstance } from 'axios';

import { EligibilityRuleForm } from '../../product-catalog/eligibility-rules/common/types';
import { ListRequestParams, ProductStatusForm, ScrollableEntry, SuccessResponse } from '../types';
import { EligibilityRule, EligibilityRuleListItem } from './types';

export class EligibilityRuleApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async listEligibilityRule(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<EligibilityRuleListItem>>('/product/api/eligibility-rule', { params });
  }

  public async createEligibilityRule(form: EligibilityRuleForm) {
    return await this.axios.post<SuccessResponse>('/product/api/eligibility-rule', form);
  }

  public async updateEligibilityRule(id: string, form: EligibilityRuleForm) {
    return await this.axios.put<SuccessResponse>(`/product/api/eligibility-rule/${id}`, form);
  }

  public async updateStatus(id: string, form: ProductStatusForm) {
    return await this.axios.put<SuccessResponse>(`/product/api/eligibility-rule/${id}/update-status`, form);
  }

  public async eligibilityRule(id: string) {
    return await this.axios.get<EligibilityRule>(`/product/api/eligibility-rule/${id}`);
  }
}
