import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { FieldPath, FieldValue, FieldValues, UseFormResetField, UseFormSetValue } from 'react-hook-form';
import { isEmptyField } from '../app/validation';
import { PriceForm } from '../product-catalog/common/PricingOptionForm/productPricingValidation';
import { Price, PriceForm as ApiPriceForm } from '../api/product/types';
import { CustomerType } from './schemas';

export type PriceFormWithVat = PriceForm & { vat?: number; configurePriceWithVat?: boolean };

const ONE_HUNDRED_PERCENT = 1;

export const roundToTwoDecimalPlaces = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const floatToPercent = (number: number) => Math.floor(number * 100);

export const getPriceWithVat = (priceNoVat: number, vat: number) => priceNoVat * (ONE_HUNDRED_PERCENT + vat);

export const getPriceNoVat = (price: number, vat: number) => price / (ONE_HUNDRED_PERCENT + vat);

export const apiFeeToForm = (fee: Price | undefined): PriceForm | undefined => {
  if (!fee || typeof fee?.price !== 'number' || typeof fee?.priceNoVat !== 'number') return undefined;

  return {
    price: typeof fee?.price === 'number' ? fee.price + (fee.discount || 0) : undefined,
    priceNoVat: typeof fee?.priceNoVat === 'number' ? fee.priceNoVat + (fee.discount || 0) : undefined,
    //we ignore discount if it's 0, it's same as no discount.
    discountedFee: fee?.discount ? fee.price : undefined,
    discountedFeeNoVat: fee?.discount ? fee.priceNoVat : undefined,
    currency: fee?.currency,
    ...(fee?.chargeCode && { chargeCode: fee?.chargeCode?.id }),
    ...(fee?.chargeCode && { chargeCodeName: fee?.chargeCode?.name }),
  };
};

export const formFeeToApi = ({ vat, ...feePricing }: PriceFormWithVat): ApiPriceForm | undefined => {
  if (typeof vat !== 'number' || typeof feePricing.price !== 'number' || typeof feePricing.priceNoVat !== 'number')
    return undefined;

  return {
    vat,
    price: feePricing.price,
    priceNoVat: feePricing.priceNoVat,
    discount: 0,
    discountNoVat: 0,
    ...(feePricing?.chargeCode && { chargeCode: feePricing?.chargeCode }),
  };
};

export const discountedFormFeeToApi = ({ vat, ...feePricing }: PriceFormWithVat): ApiPriceForm | undefined => {
  if (typeof vat !== 'number' || typeof feePricing.price !== 'number' || typeof feePricing.priceNoVat !== 'number')
    return undefined;
  return {
    vat,
    price: feePricing.discountedFee || 0,
    priceNoVat: feePricing.discountedFeeNoVat || 0,
    discount: feePricing.price - (feePricing.discountedFee || 0),
    discountNoVat: feePricing.priceNoVat - (feePricing.discountedFeeNoVat || 0),
    ...(feePricing?.chargeCode && { chargeCode: feePricing?.chargeCode }),
  };
};

export function handleFillPriceField<TForm extends FieldValues>({
  pricePath,
  pricePathNoVat,
  setValue,
  getValues,
  resetField,
  configurePriceWithVat,
  vat,
}: {
  pricePath: FieldPath<TForm>;
  pricePathNoVat: FieldPath<TForm>;
  setValue: UseFormSetValue<TForm>;
  getValues: UseFormGetValues<TForm>;
  resetField: UseFormResetField<TForm>;
  configurePriceWithVat: boolean;
  vat: number;
}) {
  const fieldPriceValue = getValues(pricePath);
  const fieldPriceNoVatValue = getValues(pricePathNoVat);

  if (isEmptyField(configurePriceWithVat ? fieldPriceValue : fieldPriceNoVatValue)) {
    resetField(configurePriceWithVat ? pricePathNoVat : pricePath, { defaultValue: '' as FieldValue<TForm> });
  } else {
    const price = configurePriceWithVat
      ? getPriceNoVat(fieldPriceValue as number, vat as number)
      : getPriceWithVat(fieldPriceNoVatValue as number, vat as number);
    setValue(configurePriceWithVat ? pricePathNoVat : pricePath, +price.toFixed(2) as FieldValue<TForm>);
  }
}

export function getPriceByCustomerType({
  price,
  customerType = 'BUSINESS',
  tenantCurrency,
}: {
  price: Price;
  customerType?: CustomerType;
  tenantCurrency: string;
}) {
  if (typeof price?.price !== 'number') return '';

  const priceValue = customerType === 'BUSINESS' ? price?.priceNoVat : price?.price;

  return `${priceValue ?? ''} ${price?.currency ?? tenantCurrency}`;
}

export function getDiscountedPriceByCustomerType({
  price,
  customerType = 'BUSINESS',
  tenantCurrency,
}: {
  price: Price;
  customerType?: CustomerType;
  tenantCurrency: string;
}) {
  if (typeof price?.price !== 'number' || !price?.discount) return '';

  const priceValue = customerType === 'BUSINESS' ? price?.priceNoVat : price?.price;
  const discountValue = customerType === 'BUSINESS' ? price?.discountNoVat : price?.discount;

  return `${(priceValue ?? 0) + (discountValue ?? 0)} ${price?.currency ?? tenantCurrency}`;
}
