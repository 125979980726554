import { ThemeOptions } from '@mui/material';
import {
  demoUsTenantWorldMobileDocumentTitle,
  demoUsTenantWorldMobileLogo,
  demoUsTenantWorldMobileThemeOptions,
} from './tenants/tenantWorldMobile';

export const DEMO_US_TENANTS_ID_MAP = {
  WORLD_MOBILE: 'WORLD_MOBILE',
};

export const DEMO_US_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [DEMO_US_TENANTS_ID_MAP.WORLD_MOBILE]: demoUsTenantWorldMobileThemeOptions,
};

export const DEMO_US_LOGO_BY_TENANT_MAP: Record<string, string> = {
  [DEMO_US_TENANTS_ID_MAP.WORLD_MOBILE]: demoUsTenantWorldMobileLogo,
};

export const DEMO_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP: Record<string, string> = {
  [DEMO_US_TENANTS_ID_MAP.WORLD_MOBILE]: demoUsTenantWorldMobileDocumentTitle,
};
