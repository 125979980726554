import { ThemeOptions } from '@mui/material';

import { mmdStagingTenant1Logo, mmdStagingTenant1ThemeOptions } from './tenants/tenant1';
import { mmdStagingTenantCMobileLogo, mmdStagingTenantCMobileThemeOptions } from './tenants/tenantCMobile';

export const MMD_STAGING_TENANTS_ID_MAP = {
  '1': '1',
  C_MOBILE: '2',
};

export const MMD_STAGING_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [MMD_STAGING_TENANTS_ID_MAP['1']]: mmdStagingTenant1ThemeOptions,
  [MMD_STAGING_TENANTS_ID_MAP.C_MOBILE]: mmdStagingTenantCMobileThemeOptions,
};

export const MMD_STAGING_LOGO_BY_TENANT_MAP: Record<string, string> = {
  [MMD_STAGING_TENANTS_ID_MAP['1']]: mmdStagingTenant1Logo,
  [MMD_STAGING_TENANTS_ID_MAP.C_MOBILE]: mmdStagingTenantCMobileLogo,
};
