import { useAsync, useAsyncCallback, UseAsyncCallbackOptions, UseAsyncOptions } from 'react-async-hook';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { AccountBalanceApi } from '../api/account-balance/api';
import { Api } from '../api/api';
import { BillingApi } from '../api/billing/api';
import { BillingClassifierApi } from '../api/billing-classifier/api';
import { CdrApi } from '../api/cdr/api';
import { CrmApi } from '../api/crm/api';
import { CrmClassifierApi } from '../api/crm-classifier/api';
import { DocumentApi } from '../api/document/api';
import { EligibilityRuleApi } from '../api/eligibility-rule/api';
import { InventoryApi } from '../api/inventory/api';
import { OfferApi } from '../api/offer/api';
import { OrderApi } from '../api/order/api';
import { OrderManagementApi } from '../api/order-management/api';
import { OrderManagementClassifierApi } from '../api/order-management-classifier/api';
import { OrganizationsApi } from '../api/organizations/api';
import { PaymentClassifierApi } from '../api/payment-classifier/api';
import { PosClassifierApi } from '../api/pos-classifier/api';
import { ProductApi } from '../api/product/api';
import { ProductClassifierApi } from '../api/product-classifier/api';
import { ProductComponentApi } from '../api/product-component/api';
import { ProvisioningApi } from '../api/provisioning/api';
import { ProvisioningClassifierApi } from '../api/provisioning-classifier/api';
import { ProvisioningTataApi } from '../api/provisioning-tata/api';
import { SearchApi } from '../api/search/api';
import { SsoApi } from '../api/sso/api';
import { TaskApi } from '../api/task/api';
import { TaskClassifierApi } from '../api/task-classifier/api';
import { TemplateControllerApi } from '../api/template-controller/api';
import { TenantApi } from '../api/tenant/api';
import { TicketApi } from '../api/ticket/api';
import { TicketClassifierApi } from '../api/ticket-classifier/api';
import { ErrorType } from '../api/types';
import { WalletApi } from '../api/wallet/api';
import { PaymentApi } from '../api/payment/api';
import { delay } from '../business/delay';
import { useAuth } from './auth';
import { ChargeCodeApi } from '../api/charge-code/api';

export const useApi = <R, D extends unknown[]>(
  asyncFn: (api: Api) => Promise<R>,
  deps?: D,
  useAsyncOptions?: UseAsyncOptions<R>,
) => {
  const auth = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAsync(configureAsyncFn(asyncFn, auth), deps ?? [], useAsyncOptions);
};

export const useApiCallback = <R, A extends unknown>(
  asyncFn: (api: Api, args: A) => Promise<R>,
  useAsyncCallbackOptions?: UseAsyncCallbackOptions<R>,
) => {
  const auth = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAsyncCallback(configureAsyncFn(asyncFn, auth), useAsyncCallbackOptions);
};

const configureAsyncFn =
  <R, A extends unknown>(asyncFn: (api: Api, args: A) => Promise<R>, auth: ReturnType<typeof useAuth>) =>
  async (args?: A) => {
    let accessToken;

    try {
      accessToken = await auth.getAccessToken();
    } catch (e) {
      auth.logout();

      return Promise.reject(e);
    }

    try {
      const client = createClient(accessToken);

      return await asyncFn(createApi(client), args as A);
    } catch (e) {
      const error = (e as { error: ErrorType }).error;
      const axiosResponse = e as AxiosError<{ description?: string; reason?: string }>;
      if (error === 'login_required' || axiosResponse.response?.status === 401) {
        auth.login();
        await delay(5000);
      }
      if (axiosResponse.response?.status === 412) {
        return Promise.reject({
          message: axiosResponse.message ?? e,
          fieldErrors: axiosResponse.response?.data,
        });
      }

      return Promise.reject({
        message: axiosResponse.response?.data?.description ?? e,
        name: axiosResponse.response?.data?.reason,
      });
    }
  };

const createApi = (client: AxiosInstance) =>
  new Api(
    new TenantApi(client),
    new OfferApi(client),
    new ProductApi(client),
    new ProductComponentApi(client),
    new PosClassifierApi(client),
    new ProductClassifierApi(client),
    new TicketClassifierApi(client),
    new OrderManagementClassifierApi(client),
    new CrmClassifierApi(client),
    new EligibilityRuleApi(client),
    new OrderApi(client),
    new SearchApi(client),
    new TicketApi(client),
    new CrmApi(client),
    new BillingApi(client),
    new BillingClassifierApi(client),
    new TaskApi(client),
    new TaskClassifierApi(client),
    new SsoApi(client),
    new OrderManagementApi(client),
    new PaymentClassifierApi(client),
    new ProvisioningClassifierApi(client),
    new ProvisioningTataApi(client),
    new OrganizationsApi(client),
    new ProvisioningApi(client),
    new InventoryApi(client),
    new CdrApi(client),
    new WalletApi(client),
    new DocumentApi(client),
    new AccountBalanceApi(client),
    new TemplateControllerApi(client),
    new ChargeCodeApi(client),
    new PaymentApi(client),
  );

export const createClient = (accessToken: string) =>
  axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/demo-api' : '/',
    paramsSerializer: params => stringify(params, { strictNullHandling: true, arrayFormat: 'repeat' }),
    headers: { Authorization: accessToken ? `Bearer ${accessToken}` : '' },
  });
