import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry, Tenant } from '../types';
import { TenantListItem } from './types';

export class TenantApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<TenantListItem>>('/tenant/api/tenant', { params });
  }

  public async tenant(tenantId: string | undefined) {
    if (!tenantId) {
      return Promise.reject();
    }

    return await this.axios.get<Tenant>(`/tenant/api/tenant/${tenantId}`);
  }
}
