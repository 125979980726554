import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry, SuccessResponse } from '../types';
import {
  ApiRoleForm,
  ApiUserForm,
  ApiUserPoolForm,
  CurrentUser,
  ListItem,
  Role,
  SupportedUserTenant,
  UpdateUserStatusParams,
  User,
  UserListItem,
  UserPermissions,
  UserPool,
} from './types';

export class SsoApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async currentUser() {
    return await this.axios.get<CurrentUser>('/sso/api/me');
  }

  public async permissions() {
    return await this.axios.get<UserPermissions>('/sso/api/permission');
  }

  public async role(roleId: string) {
    return await this.axios.get<Role>(`/sso/api/role/${roleId}`);
  }

  public async roles(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<ListItem>>(`/sso/api/role`, { params });
  }

  public async roleEdit(roleId: string, form: ApiRoleForm) {
    return await this.axios.put<ScrollableEntry<ListItem>>(`/sso/api/role/${roleId}`, form);
  }

  public async roleCreate(form: ApiRoleForm) {
    return await this.axios.post<ScrollableEntry<ListItem>>(`/sso/api/role`, form);
  }

  public async users(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<UserListItem>>(`/sso/api/user`, { params });
  }

  public async user(userId: string) {
    return await this.axios.get<User>(`/sso/api/user/${userId}`);
  }

  public async userEdit(roleId: string, form: ApiUserForm) {
    return await this.axios.put<UserListItem>(`/sso/api/user/${roleId}`, form);
  }

  public async userCreate(form: ApiUserForm) {
    return await this.axios.post<UserListItem>(`/sso/api/user`, form);
  }

  public async updateUserStatus({ id, status }: UpdateUserStatusParams) {
    return await this.axios.put<UserListItem>(`/sso/api/user/${id}/status`, { status });
  }

  public async resetPassword(userId: string) {
    return await this.axios.put<SuccessResponse>(`sso/api/user/${userId}/reset-password`);
  }

  public async userPools(params?: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<ListItem>>(`/sso/api/user-pool`, { params });
  }

  public async userPool(poolId: string) {
    return await this.axios.get<UserPool>(`/sso/api/user-pool/${poolId}`);
  }

  public async userPoolEdit(poolId: string, params: ApiUserPoolForm) {
    return await this.axios.put<SuccessResponse>(`/sso/api/user-pool/${poolId}`, params);
  }

  public async userPoolCreate(params: ApiUserPoolForm) {
    return await this.axios.post<SuccessResponse>(`/sso/api/user-pool`, params);
  }

  public async supportedUserTenantList() {
    return await this.axios.get<SupportedUserTenant[]>(`/sso/api/me/tenant`);
  }

  public async changeUserTenant(tenantId: string) {
    return await this.axios.post<SuccessResponse>(`/sso/api/me/tenant`, {
      tenantId,
    });
  }
}
