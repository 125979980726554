import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routePaths, useRouter } from '../../app/router';

export const useWizardStepperParams = (allowAnyStep?: boolean) => {
  const router = useRouter<{}, { step?: string }>();
  const activeStep = Number(router.query.step ?? 0);
  const navigate = useNavigate();
  const customerType = router.query.customerType;

  const navigateToStep = useCallback(
    (step: number) =>
      (step < activeStep || allowAnyStep) &&
      navigate({ search: `?step=${step}&customerType=${customerType}` }, { replace: false }),
    [navigate, activeStep, allowAnyStep, customerType],
  );

  const navigateToPrevStep = useCallback(
    () => navigate({ search: `?step=${activeStep - 1}&customerType=${customerType}` }, { replace: true }),
    [navigate, activeStep, customerType],
  );

  const navigateToNextStep = useCallback(
    () => navigate({ search: `?step=${activeStep + 1}&customerType=${customerType}` }, { replace: true }),
    [navigate, activeStep, customerType],
  );

  const onOrderIdUpdate = useCallback(
    (orderId: string) => navigate(routePaths.pos.orderWithId, { state: { orderId }, replace: true }),
    [navigate],
  );

  return { activeStep, navigateToStep, navigateToPrevStep, navigateToNextStep, onOrderIdUpdate };
};
