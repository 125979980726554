import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, FormHelperText } from '@mui/material';

import { interpolatedTranslation } from '../../app/i18n';

interface Props {
  name: string;
  disabled?: boolean;
  required?: boolean;
  label: string;
}

export const FileField: React.FC<Props> = ({ name, label, disabled = false, required = false }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box flex={1} display="flex" marginRight={1}>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Box>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircle />}
                component="label"
                disabled={disabled}
              >
                {label}
                <input
                  type="file"
                  name={field.name}
                  ref={field.ref}
                  onChange={e => {
                    field.onChange(e.target.files);
                  }}
                  onBlur={field.onBlur}
                  id={name}
                  hidden
                  disabled={disabled}
                  required={required}
                />
              </Button>
              <FormHelperText error={!!error}>
                {error?.message && t(...interpolatedTranslation(error.message))}
              </FormHelperText>
            </Box>
          );
        }}
      />
    </Box>
  );
};
