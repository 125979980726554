import { AxiosInstance } from 'axios';

import { PaymentClassifierEntry } from './types';

export class PaymentClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<PaymentClassifierEntry[]>('/payment/api/classifiers');
  }
}
