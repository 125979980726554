import { isValid } from 'date-fns';

export const removeEmptyStringFromObject = <T extends { [key: string]: any }>(object: T): T => {
  const appliedObject = { ...object };

  Object.keys(appliedObject).forEach((key: keyof T) => {
    const value = appliedObject[key];

    if (value === null || value === '' || value === undefined) {
      delete appliedObject[key];
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value) && !isValid(value)) {
      appliedObject[key] = removeEmptyStringFromObject(value);
    }
  });

  return appliedObject;
};
