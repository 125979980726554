import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WizardStepRouteParams } from '../../ui';
import { useApi, useApiCallback } from '../api-hooks';
import { Order } from '../../api/order/types';
import { ProvisioningClassifier } from '../../api/provisioning-classifier/types';

interface ResourcesAndDetailsContextValue {
  isPaymentFailed: boolean;
  membersRequiredError?: string;
  setMembersRequiredError: (value?: string) => void;
  checkOrderManagementStatus: () => void;
  loading: boolean;
  posOrder: Order | undefined;
  provisioningClassifiers: ProvisioningClassifier[];
}

const ResourcesAndDetailsContext = createContext<ResourcesAndDetailsContextValue>({
  isPaymentFailed: false,
  membersRequiredError: undefined,
  setMembersRequiredError: () => {},
  checkOrderManagementStatus: () => {},
  loading: true,
  posOrder: undefined,
  provisioningClassifiers: [],
});

export const ResourcesAndDetailsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { orderId = '' } = useParams<WizardStepRouteParams>();

  const [isPaymentFailed, setPaymentFailed] = useState(false);
  const [membersRequiredError, setMembersRequiredError] = useState<string>();

  const posOrder = useApi(api => api.pos.order(orderId));
  const provisioningClassifiers = useApi(api => api.provisioningClassifier.list());
  const orderManagementOrderCb = useApiCallback((api, omOrderId: string) => api.orderManagement.order(omOrderId));
  const checkOrderManagementStatus = useCallback(async () => {
    const omOrder = await orderManagementOrderCb.execute(posOrder?.result?.data?.omOrderId);
    setPaymentFailed(omOrder?.data?.orderStatus === 'PAYMENT_FAILED');
  }, [orderManagementOrderCb, posOrder?.result?.data?.omOrderId]);

  const contextValue = useMemo(
    () => ({
      loading: posOrder.loading || provisioningClassifiers.loading,
      posOrder: posOrder.result?.data,
      provisioningClassifiers: provisioningClassifiers.result?.data || [],
      isPaymentFailed,
      checkOrderManagementStatus,
      membersRequiredError,
      setMembersRequiredError,
    }),
    [
      membersRequiredError,
      setMembersRequiredError,
      checkOrderManagementStatus,
      isPaymentFailed,
      provisioningClassifiers,
      posOrder,
    ],
  );

  return <ResourcesAndDetailsContext.Provider value={contextValue}>{children}</ResourcesAndDetailsContext.Provider>;
};

export const useResourcesAndDetailsContext = () => useContext(ResourcesAndDetailsContext);
