import * as yup from 'yup';

import { consentsSchema } from './consents';
import { businessCustomerValidationSchema, privateCustomerValidationSchema } from './customer';
import { networkServicesFormSchema } from './networkServices';

export type BusinessCustomer = yup.InferType<typeof businessCustomerValidationSchema>;
export type PrivateCustomer = yup.InferType<typeof privateCustomerValidationSchema>;
export type Consents = yup.InferType<typeof consentsSchema>;
export type NetworkServices = yup.InferType<typeof networkServicesFormSchema>;

export type CustomerType = 'PRIVATE' | 'BUSINESS';

export enum IdentityType {
  IDCard = 'ID_CARD',
  Passport = 'PASSPORT',
}

export enum ConsentType {
  Marketing = 'MARKETING',
  Segmentation = 'SEGMENTATION',
}
