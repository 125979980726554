import { AxiosInstance } from 'axios';

import { ProductClassifierEntry } from './types';

export class ProductClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<ProductClassifierEntry[]>('/product/api/classifiers');
  }
}
