import React from 'react';
import { Controller, Path, PathValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  useTheme,
} from '@mui/material';

import { interpolatedTranslation } from '../../app/i18n';
import { SelectOption } from '../../app/types';

export interface SelectFieldProps<T = Record<string, any>> {
  name: Path<T>;
  value?: PathValue<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
  disabled?: boolean;
  isRequired?: boolean;
  label?: string;
  options: SelectOption[];
  variant?: SelectProps['variant'];
  onChanged?(value: string): void;
  marginBottom?: number;
}

export const SelectField = <T extends Record<string, any>>({
  name,
  label,
  options,
  defaultValue,
  disabled = false,
  isRequired = false,
  onChanged,
  variant = 'standard',
}: SelectFieldProps<T>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || (EMPTY_STR_OPTION.value as typeof defaultValue)}
        rules={{ required: isRequired }}
        render={({ field, fieldState: { error } }) => (
          <FormControl
            variant={variant}
            sx={{
              flex: 1,
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                '& label': {
                  fontSize: 12,
                },
              },
            }}
          >
            {label && (
              <InputLabel disabled={disabled} required={isRequired} error={!!error}>
                {label}
              </InputLabel>
            )}
            <Select
              {...field}
              id={name}
              label={label}
              name={name}
              fullWidth
              error={!!error}
              disabled={disabled}
              required={isRequired}
              onChange={e => {
                field.onChange(e as React.ChangeEvent);
                handleChange(e);
              }}
              MenuProps={{ sx: { maxWidth: 300 } }}
              variant={variant}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value} sx={{ minHeight: 36, whiteSpace: 'normal' }}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!error?.message && (
              <FormHelperText error={!!error} sx={{ marginLeft: 0 }}>
                {t(...interpolatedTranslation(error.message))}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );

  function handleChange(event: SelectChangeEvent) {
    onChanged && onChanged(event.target.value);
  }
};

export const EMPTY_STR_OPTION: SelectOption = { label: '', value: '' };
