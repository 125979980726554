import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../Loader';
import { useApi } from '../../../app/api-hooks';
import { RadioGroupOptions } from '../../form/RadioGroupOptions';
import { PaymentCapability } from '../../../api/payment/types';
import { SelectOption } from '../../../app/types';
import { PaymentType } from '../../../api/payment-classifier/types';

interface Props {
  containsOneTimeFee?: PaymentCapability['oneOff'];
  containsRecurringFee?: PaymentCapability['recurring'];
}

export const PaymentMethods: FC<Props> = ({ containsOneTimeFee, containsRecurringFee }) => {
  const { t } = useTranslation();
  const tenantPaymentMethods = useApi(api => api.paymentApi.tenantPaymentCapabilities());
  const paymentMethods = filterByFeeType(tenantPaymentMethods.result?.data);
  const radioOptions =
    paymentMethods?.map(({ paymentType }) => ({
      value: paymentType,
      label: t(`entries:classifiers.payment-type.${paymentType}`),
    })) || [];

  if (tenantPaymentMethods.loading) return <Loader />;
  if (!paymentMethods?.length) return null;

  return <RadioGroupOptions name="paymentMethod" options={radioOptions as Array<SelectOption<PaymentType>>} />;

  function filterByFeeType(allowedCapabilities: PaymentCapability[] | undefined) {
    if (!allowedCapabilities?.length) return [];

    let filteredMethods = allowedCapabilities.filter(paymentCapability => !!paymentCapability?.paymentType);

    if (containsOneTimeFee) {
      filteredMethods = filteredMethods.filter(paymentCapability =>
        filterByOneOff(paymentCapability, containsOneTimeFee),
      );
    }

    if (containsRecurringFee) {
      filteredMethods = filteredMethods.filter(paymentCapability =>
        filterByRecurring(paymentCapability, containsRecurringFee),
      );
    }

    return filteredMethods;
  }
};

function filterByOneOff(paymentCapability: PaymentCapability, oneOffEnabled: boolean | undefined) {
  return paymentCapability?.oneOff === oneOffEnabled;
}

function filterByRecurring(paymentCapability: PaymentCapability, recurringEnabled: boolean | undefined) {
  return paymentCapability?.recurring === recurringEnabled;
}
