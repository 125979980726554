import { AxiosInstance } from 'axios';

import { ListRequestParams, ScrollableEntry } from '../types';
import {
  AccountUpcomingInvoiceAmount,
  BillRun,
  BillRunDetails,
  InvoiceDetails,
  PaymentBatch,
  PrepaidRenewalBatch,
} from './types';
import { Invoice } from '../crm/types';

export class BillingApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async getAccountUpcomingInvoiceAmount(customerId: string, accountId: string) {
    return await this.axios.get<AccountUpcomingInvoiceAmount>(
      `/billing/api/customer/${customerId}/account/${accountId}/upcoming-invoice-amount`,
    );
  }

  public async getInvoice(invoiceId: string) {
    return await this.axios.get<InvoiceDetails>(`/billing/api/invoice/${invoiceId}`);
  }

  public async notifyInvoice(invoiceId: Invoice['invoiceId']) {
    return await this.axios.post<string>(`/billing/api/invoice/${invoiceId}/notify`, {
      invoiceId,
    });
  }

  public async generateBillCycle(billCycleIdentifier: string) {
    return await this.axios.post<ScrollableEntry<BillRun>>('/billing/api/billing-cycle/generate', {
      billCycleIdentifier,
    });
  }

  public async confirmBillCycle(billCycleId: BillRun['id']) {
    return await this.axios.post<ScrollableEntry<BillRun>>('/billing/api/billing-cycle/confirm', {
      billCycleId,
    });
  }

  public async notifyBillCycle(billCycleId: BillRun['id']) {
    return await this.axios.post<ScrollableEntry<BillRun>>('/billing/api/billing-cycle/notify', {
      billCycleId,
    });
  }

  public async getBillingCycle(billCycleId: BillRun['id']) {
    return await this.axios.get<BillRunDetails>(`/billing/api/billing-cycle/${billCycleId}`);
  }

  public async getPastMonthsBillingCycle({
    identifier,
    monthDiff,
  }: {
    identifier: BillRun['identifier'];
    monthDiff?: number;
  }) {
    return await this.axios.get<BillRunDetails>('/billing/api/billing-cycle/previous-month', {
      params: {
        identifier,
        monthDiff,
      },
    });
  }

  public async getBillingCycles(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<BillRun>>('/billing/api/billing-cycle', { params });
  }

  public async getPaymentBatchList(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<PaymentBatch>>('/billing/api/payment-batch', { params });
  }

  public async getPaymentBatch(id: PaymentBatch['id']) {
    return await this.axios.get<PaymentBatch>(`/billing/api/payment-batch/${id}`);
  }

  public async getPrepaidRenewalBatch(id: string) {
    return await this.axios.get<PrepaidRenewalBatch>(`/billing/api/prepaid-renewal/${id}`);
  }
}
