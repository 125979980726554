import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api-hooks';
import { CustomerOrderSelectedProduct, Order, OrderOffer } from '../../api/order/types';
import { WizardStepRouteParams } from '../../ui';
import { PaymentClassifierEntry } from '../../api/payment-classifier/types';
import { productHasOneTimeFee, productHasRecurringFee } from '../../business';

interface ConfirmationStepContextValue {
  loading: boolean;
  hidePaymentMethods: boolean;
  order: Order | undefined;
  paymentClassifiers: PaymentClassifierEntry[];
  containsRecurringFeeProduct: boolean;
  containsOneTimeFeeProduct: boolean;
}

const ConfirmationStepContext = React.createContext<ConfirmationStepContextValue>({
  loading: true,
  hidePaymentMethods: false,
  order: undefined,
  paymentClassifiers: [],
  containsRecurringFeeProduct: false,
  containsOneTimeFeeProduct: false,
});

export const useConfirmationStepContext = () => useContext(ConfirmationStepContext);

export const ConfirmationStepProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { orderId = '' } = useParams<WizardStepRouteParams>();
  const posOrder = useApi(api => api.pos.order(orderId));
  const paymentClassifiers = useApi(api => api.paymentClassifier.list());
  const contextValue = useMemo(
    () => ({
      loading: posOrder.loading || paymentClassifiers.loading,
      hidePaymentMethods: hidePaymentMethods(posOrder?.result?.data),
      order: posOrder.result?.data,
      paymentClassifiers: paymentClassifiers.result?.data || [],
      containsRecurringFeeProduct: containsRecurringFeeProduct(posOrder.result?.data?.offers),
      containsOneTimeFeeProduct: containsOneTimeFeeProduct(posOrder.result?.data?.offers),
    }),
    [paymentClassifiers, posOrder],
  );

  return <ConfirmationStepContext.Provider value={contextValue}>{children}</ConfirmationStepContext.Provider>;
};

function flatten(selectedProducts?: CustomerOrderSelectedProduct[]): CustomerOrderSelectedProduct[] {
  if (!selectedProducts?.length) return [];

  return selectedProducts?.reduce(
    (result, product) => [
      ...result,
      { name: product?.commercialName, existing: product.existing, ...product },
      ...flatten(product.children),
    ],
    [] as CustomerOrderSelectedProduct[],
  );
}

function containsRecurringFeeProduct(offers?: OrderOffer[]) {
  if (!offers?.length) return false;

  return offers.some(offer => {
    return flatten(offer.selectedProducts).find(
      product =>
        !product.existing &&
        !!product.productPricing &&
        productHasRecurringFee({ productPricing: product.productPricing }),
    );
  });
}

function containsOneTimeFeeProduct(offers?: OrderOffer[]) {
  if (!offers?.length) return false;

  return offers.some(offer => {
    return flatten(offer.selectedProducts).find(
      product =>
        !product.existing &&
        !!product?.productPricing &&
        productHasOneTimeFee({ productPricing: product.productPricing }),
    );
  });
}

function hidePaymentMethods(order: Order | undefined) {
  if (!order) return true;

  const refund = order?.refund;
  const upfrontPayment = order?.upfrontPayment;
  const refundBiggerThanUpfront = upfrontPayment?.amount && refund?.amount && refund?.amount >= upfrontPayment?.amount;

  return upfrontPayment?.amount === 0 || !!refundBiggerThanUpfront;
}
