import { AxiosInstance, AxiosResponse } from 'axios';

import { ScrollableEntry } from '../types';
import { CustomerSearchResult, SearchParams, SubscriptionSearchParams, SubscriptionSearchResult } from './types';

export class SearchApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async searchByCustomerCode(customerCode: string) {
    return await this.axios.get<CustomerSearchResult[]>('/search/api/customer/search-by-customer-code', {
      params: { query: customerCode },
    });
  }

  public async searchByCustomerEmail(email: string) {
    return await this.axios.get<CustomerSearchResult[]>('/search/api/customer/search-by-email', {
      params: { query: email },
    });
  }

  public async customer(params: SearchParams): Promise<AxiosResponse<ScrollableEntry<CustomerSearchResult>>> {
    const { startRow, endRow, query } = params;

    return await this.axios.get<ScrollableEntry<CustomerSearchResult>>('/search/api/customer/search', {
      params: { query, scroll: { startRow, endRow } },
    });
  }

  public async subscriptionSearch(
    params: SubscriptionSearchParams,
  ): Promise<AxiosResponse<ScrollableEntry<SubscriptionSearchResult>>> {
    const { query, accountId } = params;

    return await this.axios.get<ScrollableEntry<SubscriptionSearchResult>>('/search/api/subscription/search', {
      params: { query, accountId },
    });
  }
}
