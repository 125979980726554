import * as yup from 'yup';

import { NetworkServices } from '../../api/provisioning-tata/types';

export const networkServicesFormSchema = yup.object<NetworkServices>({
  roaming: yup.boolean().default(false),
  network: yup.boolean().default(false),
  data: yup.boolean().default(false),
  voice: yup.boolean().default(false),
  ims: yup.boolean().default(false),
  premiumNumbers: yup.boolean().default(false),
  outgoingVoice: yup.boolean().default(false),
  incomingVoice: yup.boolean().default(false),
  outgoingSms: yup.boolean().default(false),
  incomingSms: yup.boolean().default(false),
  international: yup.boolean().default(false),
});
