import React from 'react';
import { Box, Button, Fade, Grid, Paper, Typography } from '@mui/material';
import { LoadingButton } from '../..';

interface Props {
  titleLabel: string;
  submitLabel?: string;
  cancelLabel: string;
  minWidth?: string;
  isLoading?: boolean;
  onSubmit?(data?: unknown): void;
  onCancel(): void;
  isSubmitDisabled?: boolean;
}

export const ContentModalLayout = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      titleLabel,
      submitLabel,
      cancelLabel,
      isLoading,
      onSubmit,
      onCancel,
      children,
      minWidth = '40vw',
      isSubmitDisabled = false,
    },
    ref,
  ) => {
    return (
      <Fade in>
        <Paper ref={ref} tabIndex={-1}>
          <Box
            maxWidth="80vw"
            minWidth={minWidth}
            display="flex"
            flexDirection="column"
            padding={[2, 4, 3]}
            sx={{ overflowY: 'auto', maxHeight: '90vh' }}
          >
            <Box flex={1}>
              <Typography id="modal-title" variant="h5" color="primary" align="center" mb={2}>
                {titleLabel}
              </Typography>
              {children}
            </Box>
            <Grid container spacing={2} justifyContent={!submitLabel ? 'flex-end' : 'flex-start'}>
              <Grid item md={submitLabel ? 6 : 12} xs={12}>
                <Button variant="outlined" color="primary" fullWidth onClick={onCancel} name="cancel">
                  {cancelLabel}
                </Button>
              </Grid>
              {submitLabel && (
                <Grid item md xs>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    onClick={onSubmit}
                    name="save"
                    disabled={isSubmitDisabled}
                  >
                    {submitLabel}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Fade>
    );
  },
);
