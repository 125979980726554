import React, { useMemo } from 'react';

import { Grid } from '@mui/material';

import { useAuth } from '../app/auth';
import { useApplicationContext } from '../app/contexts/ApplicationContext';
import { usePermissionsContext } from '../app/contexts/PermissionsContext';
import { AlertMessage, OverlayedLoader } from './';

export const GlobalContextsLoader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoading: authLoading, error: authError } = useAuth();
  const { loading: permissionsLoading, error: permissionsError } = usePermissionsContext();
  const { loading: appDataLoading, errors: appDataErrors } = useApplicationContext();

  const errors = useMemo(
    () => [authError, permissionsError, ...appDataErrors].filter(error => typeof error === 'string'),
    [authError, permissionsError, appDataErrors],
  );

  if (errors.length) {
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center">
        {errors.map((error, index) => (
          <Grid key={index} item xs={12}>
            <AlertMessage severity="error">{error}</AlertMessage>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      {children}
      <OverlayedLoader open={authLoading || permissionsLoading || appDataLoading} />
    </>
  );
};
