import { AxiosInstance } from 'axios';

import { CrmClassifierEntry } from './types';

export class CrmClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<CrmClassifierEntry[]>('/crm/api/classifiers');
  }
}
