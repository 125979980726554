import { AxiosInstance } from 'axios';

import { OrderManagementClassifierEntry } from './types';

export class OrderManagementClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<OrderManagementClassifierEntry[]>('/om/api/classifiers');
  }
}
