import { AxiosInstance } from 'axios';

import { ConsumerSuccessResponse } from '../types';

export class WalletApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async createDigitalWallet(userId: string) {
    return await this.axios.post<ConsumerSuccessResponse>('/wallet/api/consumer/invite-by-user-id', { userId });
  }
}
