import React, { useCallback, useContext, useEffect, useState } from 'react';

import { CurrentUser, UserPermissions } from '../../api/sso/types';
import { SuccessResponse } from '../../api/types';
import { PermissionAction, PermissionModel, Permissions } from '../../business/permissions';
import { useApi, useApiCallback } from '../api-hooks';
import { AppRoutes, RoutePermission } from '../router';
import { getTenantDocumentTitle } from '../../ui';

interface Props {
  routes: AppRoutes;
}

interface PermissionsContextValue {
  userPermissions?: UserPermissions;
  tenantId?: string;
  changeUserTenant(tenantId: string): Promise<SuccessResponse | undefined>;
  user?: CurrentUser;
  loading: boolean;
  error?: string;
  permissionGranted(permission: RoutePermission): boolean;
  isRoutePermitted(path: string): boolean;
  currentRoutePermitsActions(actions: PermissionAction[]): boolean;
}

const PermissionsContext = React.createContext<PermissionsContextValue>({
  userPermissions: undefined,
  tenantId: undefined,
  changeUserTenant: () => new Promise(() => {}),
  loading: true,
  permissionGranted: () => false,
  isRoutePermitted: () => false,
  currentRoutePermitsActions: () => false,
});

export const usePermissionsContext = () => useContext(PermissionsContext);

export const PermissionsContextProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, routes }) => {
  const location = window.location;
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [tenantId, setTenantId] = useState<string>();
  const currentUser = useApiCallback(api => api.sso.currentUser());
  const changeUserTenantCb = useApiCallback(async (api, tenantId: string) => api.sso.changeUserTenant(tenantId));

  const mergePermissions = useCallback((permission1: any, permission2: any) => {
    const result: any = {};
    for (const key in permission1) {
      if (permission1[key] && typeof permission1[key] === 'object') {
        result[key] = mergePermissions(permission1[key], permission2?.[key]);
      } else if (typeof permission1[key] === 'boolean' && typeof permission2?.[key] === 'boolean') {
        result[key] = permission1[key] || permission2?.[key];
      } else {
        result[key] = permission1[key];
      }
    }

    return result;
  }, []);

  const permissions = useApi(async () => {
    const user = await currentUser.execute();
    setTenantId(user.data.tenantId);
    const authorities = user.data.authorities
      .filter(authority => authority.permissions)
      .map(authority => authority.permissions);
    const result = authorities.reduce((previousPermission, currentPermission) => {
      return mergePermissions(currentPermission, previousPermission);
    }, {});

    setUserPermissions(result as UserPermissions);

    return parsePermissions(result! as UserPermissions) ?? {};
  }, []);

  const permissionGranted = useCallback(
    (permission: RoutePermission) => {
      if (!permissions.result) return false;
      const userPermissions = permissions.result;
      const permissionEntries = Object.entries(userPermissions);

      return permissionEntries.some(
        p => (p[0] === permission.type && p[1].hasPermission(permission.actions, permission.groups)) ?? false,
      );
    },
    [permissions],
  );

  const isRoutePermitted = useCallback(
    (path: string) => {
      const routePermission = routes.route(path)?.permission;

      return routePermission ? permissionGranted(routePermission) : true;
    },
    [routes, permissionGranted],
  );

  const currentRoutePermitsActions = useCallback(
    (actions: PermissionAction[]) => {
      const routePermission = routes.route(location.pathname)?.permission;

      return routePermission ? permissionGranted({ ...routePermission, actions }) : true;
    },
    [routes, location.pathname, permissionGranted],
  );

  const updateDocumentTitle = useCallback((tenantId: string) => {
    document.title = getTenantDocumentTitle(tenantId);
  }, []);

  const changeUserTenant = useCallback(
    async (tenantId: string): Promise<SuccessResponse | undefined> => {
      if (!tenantId) return;

      const {
        data: { tenantId: updatedTenantId },
      } = await changeUserTenantCb.execute(tenantId);

      if (updatedTenantId) {
        await permissions.execute();
      }
    },
    [changeUserTenantCb, permissions],
  );

  useEffect(() => {
    if (tenantId) updateDocumentTitle(tenantId);
  }, [tenantId, updateDocumentTitle]);

  return (
    <PermissionsContext.Provider
      value={{
        permissionGranted,
        isRoutePermitted,
        currentRoutePermitsActions,
        userPermissions,
        tenantId,
        changeUserTenant,
        user: currentUser.result?.data,
        loading: permissions.loading,
        error: permissions.error?.message,
      }}
    >
      {!permissions.loading && children}
    </PermissionsContext.Provider>
  );
};

export const convertPermissionsToArrays = (permissions: UserPermissions): any[] => {
  return Object.entries(permissions).map(permission => {
    return {
      permissionName: permission[0],
      permissionValues: { ...permission[1], groups: [] },
      groups: permission[1].groups ? convertPermissionsToArrays(permission[1].groups || []) : [],
    };
  });
};

const parsePermissions = (permissions: UserPermissions): Permissions =>
  Object.fromEntries(
    Object.entries(permissions ?? {}).map(e => [
      e[0],
      new PermissionModel({ ...e[1], groups: e[1].groups ? parsePermissions(e[1].groups) : {} }),
    ]),
  ) as Permissions;
