export const INDIA_ALPHA2_CODE = 'IN';

export enum IDENTITY_TYPES {
  AADHAAR = 'AADHAAR',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  PAN_CARD = 'PAN_CARD',
  PASSPORT = 'PASSPORT',
  ID_CARD = 'ID_CARD',
}

export const IDENTITY_EXPIRY_DATE_DISABLED_LIST = [IDENTITY_TYPES.AADHAAR, IDENTITY_TYPES.PAN_CARD];
