import { ThemeOptions } from '@mui/material';

import defaultOcbLogo from '../../images/ocb.png';
import { demoTenantLogo, demoTenantThemeOptions } from './tenants/tenantDemo';
import { defaultOcbThemeOptions } from '../../defaultThemeOptions';
import { tenant1DocumentTitle, tenant1Logo, tenant1ThemeOptions } from './tenants/tenant1';
import { tenantDemo3Logo, tenantDemo3ThemeOptions } from './tenants/tenantDemo3';

export const OCB_TENANTS_ID_MAP = {
  '1': '1',
  DEMO: '2',
  DEMO_3: '3',
  E2E_TESTING_1: 'e2e_testing_1',
};

export const OCB_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [OCB_TENANTS_ID_MAP['1']]: tenant1ThemeOptions,
  [OCB_TENANTS_ID_MAP.DEMO]: demoTenantThemeOptions,
  [OCB_TENANTS_ID_MAP.DEMO_3]: tenantDemo3ThemeOptions,
  [OCB_TENANTS_ID_MAP.E2E_TESTING_1]: defaultOcbThemeOptions,
};

export const OCB_LOGO_BY_TENANT_MAP = {
  [OCB_TENANTS_ID_MAP['1']]: tenant1Logo,
  [OCB_TENANTS_ID_MAP.DEMO]: demoTenantLogo,
  [OCB_TENANTS_ID_MAP.DEMO_3]: tenantDemo3Logo,
  [OCB_TENANTS_ID_MAP.E2E_TESTING_1]: defaultOcbLogo,
};

export const OCB_DOCUMENT_TITLE_BY_TENANT_MAP = {
  [OCB_TENANTS_ID_MAP['1']]: tenant1DocumentTitle,
};
