import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import { isNavigationItem, NavigationItem, NavigationItemChild } from '../../../app/navigation';
import { ListItemExpandIcon } from './ListItemExpandIcon';

interface Props {
  item: NavigationItem | NavigationItemChild;
  selected?: boolean;
  visible?: boolean;
  isOpen?: boolean;
  isDrawerOpen?: boolean;
  onItemClicked(): void;
}

export const ListItem: React.FC<Props> = ({
  item,
  isDrawerOpen,
  selected,
  isOpen = false,
  visible = true,
  onItemClicked,
}) => {
  const { t } = useTranslation('menu');
  const theme = useTheme();

  if (!visible) {
    return null;
  }

  if (isNavigationItem(item)) {
    return (
      <ListItemButton
        disableRipple
        selected={selected}
        onClick={onItemClicked}
        sx={{
          height: theme.spacing(6),
          ...(selected && { boxShadow: `inset 5px 0 0 0 ${theme.palette.primary.contrastText}` }),
        }}
      >
        <ListItemIcon
          sx={{
            '& svg': {
              fill: theme.palette.primary.contrastText,
            },
            '& span': {
              color: theme.palette.primary.contrastText,
            },
          }}
        >
          <item.icon />
        </ListItemIcon>
        <ListItemText
          sx={{
            '& span': {
              color: theme.palette.primary.contrastText,
              width: '20rem',
            },
          }}
          primary={t(item.name)}
        />
        <ListItemExpandIcon shouldDisplay={!!item.items?.length} isOpen={isOpen} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton
      disableRipple
      selected={selected}
      onClick={onItemClicked}
      sx={{
        paddingLeft: theme.spacing(9),
        ...(selected && { boxShadow: `inset 5px 0 0 0 ${theme.palette.primary.contrastText}` }),
      }}
    >
      <ListItemText
        sx={{
          '& p': {
            color: theme.palette.secondary.contrastText,
          },
          ...(!isDrawerOpen && { display: 'none' }),
        }}
        secondary={t(item.name)}
      />
    </ListItemButton>
  );
};
