import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { drawerWidthClosed, MUI_APP_BAR_HEIGHT } from '../theme';

export const ContentWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        height: '100vh',
        width: `calc(100% - ${drawerWidthClosed}px)`,
        flexGrow: 1,
        pt: `${MUI_APP_BAR_HEIGHT}px`,
        background: theme => theme.palette.background.default,
      }}
    >
      {children}
    </Box>
  );
};
