import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ag-grid-theme-builder.css';

import { AxiosResponse } from 'axios';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  IServerSideGetRowsParams,
  ISimpleFilterModel,
  ModelUpdatedEvent,
  RowClickedEvent,
  ToolPanelVisibleChangedEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ListRequestParams, ScrollableEntry } from '../../api/types';
import { AlertMessage, LoadingButton } from '..';
import { ActionCellRenderer } from './renderers/ActionCellRenderer';
import { ButtonCellRenderer } from './renderers/ButtonCellRenderer';
import { gridActionColumn } from './columns';
import { columnTypes, defaultColDef, sideBarDef } from './config';
import { parseDefaultFilters, parseFilterModel, parseSortModel } from './parsers';
import { CellRenderer, Error, GridActions, GridColumn, GridFilters, GridUtilities } from './types';

interface Props<T> {
  rows?: T[];
  actions?: GridActions<T>;
  columns: Array<GridColumn<T>>;
  height?: string;
  actionsWidth?: number;
  disableSideBar?: boolean;
  defaultFilters?: GridFilters<T>;
  disableVerticalScroll?: boolean;
  disableHorizontalScroll?: boolean;
  shouldHideRow?(row: T): boolean;
  onRowsFetch?(params: ListRequestParams): Promise<AxiosResponse<ScrollableEntry<T>>>;
  externalFilter?: Record<string, ISimpleFilterModel>;
  colDef?: ColDef;
  allowClearFilter?: boolean;
  showTotalRows?: boolean;
  requestParams?: ListRequestParams;
  onRowClicked?(event: RowClickedEvent): void;
  gridUtilities?: GridUtilities;
}

export interface DataGridRef<T = any> {
  refresh(): void;
  getSelectedRows(): T[];
}

const DataGridComponent = <T extends unknown>(
  {
    rows,
    actions,
    columns,
    height,
    onRowsFetch,
    actionsWidth,
    shouldHideRow,
    disableSideBar,
    defaultFilters,
    disableVerticalScroll,
    disableHorizontalScroll,
    externalFilter,
    colDef = {},
    allowClearFilter = false,
    showTotalRows = false,
    requestParams,
    onRowClicked,
    gridUtilities,
  }: Props<T>,
  ref: ForwardedRef<DataGridRef<T>>,
) => {
  const gridApi = useRef<GridApi>();
  const gridColumnApi = useRef<ColumnApi>();
  const lastRow = useRef<number>(0);
  const [error, setError] = useState<string>();
  const { t } = useTranslation('common');
  const rowsCountRef = useRef(0);
  const [isFetching, setIsFetching] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);

  const getRows = useCallback(
    async ({ request, success, fail }: IServerSideGetRowsParams) => {
      if (!onRowsFetch) {
        return;
      }
      try {
        setIsFetching(true);
        const result = await onRowsFetch({
          ...requestParams,
          startRow: request.startRow,
          endRow: requestParams?.endRow ?? request.endRow,
          sort: parseSortModel(request.sortModel),
          filter: parseFilterModel(
            externalFilter ? { ...request.filterModel, ...externalFilter } : request.filterModel,
          ),
        });
        if (shouldHideRow) {
          const filteredResults = result.data.content.filter(row => !shouldHideRow(row));
          success({
            rowData: filteredResults,
            rowCount: filteredResults.length,
          });
          rowsCountRef.current = filteredResults.length ?? 0;
        } else {
          rowsCountRef.current = result?.data?.lastRow ?? 0;
          success({ rowData: result.data.content, rowCount: result.data.lastRow });
        }
      } catch (e: unknown) {
        setError((e as Error).message?.response?.statusText ?? (e as Error).message);
        fail();
        throw e;
      } finally {
        setIsFetching(false);
      }
    },
    [externalFilter, onRowsFetch, shouldHideRow, requestParams],
  );

  const clearFilters = useCallback(() => {
    gridApi?.current?.setFilterModel(null);
  }, [gridApi]);
  const utilityButtonsAvailable = useMemo(
    () => !!gridUtilities?.excelExport || !!allowClearFilter,
    [gridUtilities?.excelExport, allowClearFilter],
  );

  useImperativeHandle<DataGridRef<T>, DataGridRef<T>>(ref, () => ({
    refresh() {
      gridApi.current?.refreshServerSideStore({});
    },
    getSelectedRows() {
      return gridApi.current?.getSelectedRows() ?? [];
    },
  }));

  return (
    <Stack gap={1} className="ag-theme-custom">
      {utilityButtonsAvailable && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          {gridUtilities?.excelExport && (
            <LoadingButton
              disabled={isFetching || !rowsCountRef.current}
              isLoading={isExporting}
              onClick={handleExportClick}
              endIcon={<FileDownloadIcon />}
              loadingPosition="end"
              variant="outlined"
              color="primary"
            >
              {t('export')}
            </LoadingButton>
          )}
          {allowClearFilter && (
            <Button onClick={clearFilters} variant="text" color="primary">
              {t('clear-filters')}
            </Button>
          )}
        </Box>
      )}
      <Box maxHeight="60vh" height={height ?? '60vh'} width="100%" boxSizing="border-box" className="ag-theme-custom">
        {error ? (
          <Box marginTop={2}>
            <AlertMessage severity="error" elevation={0}>
              {error}
            </AlertMessage>
          </Box>
        ) : (
          <AgGridReact
            modules={[
              ServerSideRowModelModule,
              SetFilterModule,
              GridChartsModule,
              ClipboardModule,
              ColumnsToolPanelModule,
              ExcelExportModule,
              FiltersToolPanelModule,
              MenuModule,
              MasterDetailModule,
              MultiFilterModule,
              RangeSelectionModule,
              RichSelectModule,
              RowGroupingModule,
              SideBarModule,
              SparklinesModule,
              StatusBarModule,
              ViewportRowModelModule,
              ClientSideRowModelModule,
            ]}
            getServerSideGroupKey={item => item.id}
            defaultColDef={{ ...defaultColDef, ...colDef }}
            components={{
              [CellRenderer.Action]: ActionCellRenderer,
              [CellRenderer.Button]: ButtonCellRenderer,
            }}
            context={{ actions }}
            onRowClicked={onRowClicked}
            rowData={rows}
            rowModelType={onRowsFetch ? 'serverSide' : 'clientSide'}
            suppressServerSideInfiniteScroll={!onRowsFetch}
            cacheBlockSize={30}
            blockLoadDebounceMillis={500}
            maxBlocksInCache={shouldHideRow ? undefined : 100}
            defaultExcelExportParams={{
              processCellCallback: ({ column, value }) => {
                const formatter = column.getUserProvidedColDef()?.valueFormatter;

                return typeof formatter === 'function' ? formatter({ value } as ValueFormatterParams) : value;
              },
            }}
            suppressContextMenu
            colResizeDefault="shift"
            columnDefs={actions ? [...(columns as ColDef[]), gridActionColumn(actionsWidth)] : (columns as ColDef[])}
            columnTypes={columnTypes.reduce((ac, a) => ({ ...ac, [a]: {} }), {})}
            onGridReady={onGridReady}
            onModelUpdated={resizeColumns}
            onGridSizeChanged={resizeColumns}
            onFilterChanged={handleFilterChange}
            onSortChanged={handleSortChange}
            onToolPanelVisibleChanged={resizeColumns}
            rowSelection="multiple"
            rowHeight={48}
            headerHeight={48}
            suppressRowClickSelection
            suppressMovableColumns
            suppressColumnMoveAnimation
            sideBar={disableSideBar ? false : sideBarDef}
            alwaysShowVerticalScroll={!disableVerticalScroll}
            alwaysShowHorizontalScroll={!disableHorizontalScroll}
            scrollbarWidth={disableVerticalScroll ? 0 : undefined}
            gridOptions={
              shouldHideRow
                ? {
                    getRowHeight: params => {
                      if (params.data && shouldHideRow && shouldHideRow(params.data)) {
                        return 0;
                      }

                      return null;
                    },
                    getRowStyle: params => {
                      return { display: params.data && shouldHideRow && shouldHideRow(params.data) ? 'none' : 'auto' };
                    },
                  }
                : undefined
            }
          />
        )}
      </Box>
      {showTotalRows && !error && (
        <Typography variant="caption">{`${t('ag-grid.rows')} ${rowsCountRef.current}`}</Typography>
      )}
    </Stack>
  );

  function onGridReady({ api, columnApi }: GridReadyEvent) {
    gridApi.current = api;
    gridColumnApi.current = columnApi;
    if (onRowsFetch) {
      api.setServerSideDatasource({ getRows });
    }
    api.sizeColumnsToFit();
    api.setFilterModel(defaultFilters ? parseDefaultFilters(defaultFilters, columns) : {});
  }

  function resizeColumns({ api }: ModelUpdatedEvent | ToolPanelVisibleChangedEvent | GridSizeChangedEvent) {
    api.sizeColumnsToFit();
  }

  function handleFilterChange() {
    lastRow.current = 0;
    gridApi.current?.deselectAll();
  }

  function handleSortChange() {
    gridApi.current?.deselectAll();
  }

  async function handleExportClick() {
    if (typeof gridUtilities?.excelExportCb?.execute === 'function') {
      try {
        setIsExporting(true);
        const filterModel = externalFilter
          ? { ...gridApi.current?.getFilterModel(), ...externalFilter }
          : gridApi.current?.getFilterModel();
        const sortModel = gridColumnApi.current
          ?.getColumnState()
          ?.filter(col => !!col?.sort)
          .map(({ colId, sort }) => `${colId},${sort}`);
        await gridUtilities.excelExportCb.execute({
          filter: filterModel && parseFilterModel(filterModel),
          sort: sortModel,
        });
      } finally {
        setIsExporting(false);
      }
    }
  }
};

export const DataGrid = React.memo(forwardRef(DataGridComponent), (prevProps, nextProps) => {
  const sameNumberOfActions = prevProps.actions?.length === nextProps.actions?.length;
  const sameNumberOfColumns = prevProps.columns.length === nextProps.columns.length;
  const sameNumberOfRows = prevProps.rows?.length === nextProps.rows?.length;
  const onRowsFetchAlreadyExisted = !!prevProps.onRowsFetch && !!nextProps.onRowsFetch;

  return sameNumberOfActions && sameNumberOfColumns && sameNumberOfRows && onRowsFetchAlreadyExisted;
}) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<DataGridRef<T> | undefined> },
) => ReturnType<typeof DataGridComponent>;
