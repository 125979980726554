import { AxiosInstance } from 'axios';

import { PaymentCapability, PaymentState } from './types';

export class PaymentApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async capturePayPalPayment(orderId: string) {
    return await this.axios.post<PaymentState>(`/payment/api/paypal/${orderId}/capture`);
  }

  public async tenantPaymentCapabilities() {
    return await this.axios.get<PaymentCapability[]>(`/payment/api/tenant-payment-capabilities`);
  }
}
