import React from 'react';

import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

interface Props extends CircularProgressProps {
  size?: 'small' | 'medium' | 'large';
}

export const Loader: React.FC<Props> = ({ size = 'medium', color = 'primary', ...restCircularProgressProps }) => (
  <Box display="flex" alignItems="center" justifyContent="center" alignSelf="center" justifySelf="center">
    <CircularProgress size={circularProgressSize(size)} color={color} {...restCircularProgressProps} />
  </Box>
);

function circularProgressSize(size: Props['size']) {
  switch (size) {
    case 'small':
      return 20;
    case 'medium':
      return 60;
    case 'large':
      return 100;
    default:
      return undefined;
  }
}
