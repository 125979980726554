import { useApplicationContext } from '../contexts';

export const useTenantSecret = (reactAppSecret: string): string | Record<any, any> | undefined => {
  const { tenant } = useApplicationContext();
  const tenantId = tenant?.id;
  const secretJsonString = process.env[reactAppSecret];

  if (!tenantId || !secretJsonString) return undefined;
  const secretObject = getParsedString(secretJsonString);
  if (typeof secretObject !== 'object') return undefined;

  return secretObject[tenantId];
};

const getParsedString = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return undefined;
  }
};
