import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Row as GridRow } from './Row';

export type CollapsibleTableRow<T> = {
  [K in keyof Partial<T>]: T[K] | React.ReactNode;
} & { items?: Array<CollapsibleTableRow<T>> };

interface Props<T> {
  rows: Array<CollapsibleTableRow<T>>;
  columns: { [K in keyof Partial<T>]: string };
  openByDefault?: boolean;
  firstColumnWidth?: number;
  lastColumnWidth?: number;
}

interface RowProps<T> {
  columns: { [K in keyof Partial<T>]: string };
  row: Props<T>['rows'][number];
  openByDefault?: boolean;
  level: number;
  firstColumnWidth?: number;
  lastColumnWidth?: number;
}

export const CollapsibleTable = <T extends {}>({
  rows,
  columns,
  openByDefault,
  firstColumnWidth,
  lastColumnWidth,
}: Props<T>) => {
  const theme = useTheme();

  return (
    <TableContainer sx={{ overflowY: 'hidden', overflowX: 'auto' }} component={Paper} variant="elevation" elevation={0}>
      <MuiTable
        aria-label="collapsible table"
        sx={{
          minWidth: 650,
        }}
      >
        <TableHead>
          <TableRow>
            {Object.values(columns).map((header, idx) => (
              <TableCell
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: theme.typography.subtitle2,
                }}
                key={idx}
                align="left"
              >
                {`${header}`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <Row<T>
              key={`parent-${idx}`}
              row={row}
              columns={columns}
              openByDefault={openByDefault}
              level={1}
              firstColumnWidth={firstColumnWidth}
              lastColumnWidth={lastColumnWidth}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

const Row = <T extends {}>({
  row,
  columns,
  firstColumnWidth,
  lastColumnWidth,
  openByDefault = true,
  level = 0,
}: RowProps<T>) => {
  const [open, setOpen] = useState(openByDefault);
  const cols = Object.keys(columns);
  const colsCount = cols.length;
  const cellWidth =
    (100 - (firstColumnWidth || 0) - (lastColumnWidth || 0)) /
    (colsCount - (firstColumnWidth ? 1 : 0) - (lastColumnWidth ? 1 : 0));

  return (
    <>
      <TableRow>
        {cols.map((field, index) => (
          <React.Fragment key={index}>
            {index === 0 ? (
              <TableCell
                align="left"
                width={`${firstColumnWidth ?? cellWidth}%`}
                sx={{
                  padding: '6px 16px',
                  paddingLeft: !row.items?.length ? level * 2 : 0,
                }}
              >
                <GridRow cols={2} alignItems="center">
                  {!!row.items?.length && (
                    <Box sx={{ width: 36, minWidth: 36 }}>
                      <IconButton aria-label="expand row" size="small" onClick={handleCollapseClick}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </Box>
                  )}
                  <>{row[field as keyof T]}</>
                </GridRow>
              </TableCell>
            ) : (
              <TableCell
                sx={{ padding: '6px 16px' }}
                align="left"
                width={`${index === colsCount - 1 ? lastColumnWidth ?? cellWidth : cellWidth}%`}
              >
                <>{row[field as keyof T]}</>
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
      {open &&
        row.items?.map((childRow, idx) => (
          <Row
            key={`collapsed-${idx}`}
            row={childRow}
            columns={columns}
            level={level + 1}
            firstColumnWidth={firstColumnWidth}
            lastColumnWidth={lastColumnWidth}
          />
        ))}
    </>
  );

  function handleCollapseClick() {
    setOpen(!open);
  }
};
