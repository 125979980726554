import React, { createContext, Key, useCallback, useContext, useEffect, useState } from 'react';
import { ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { AmplifyUser, AuthEventData } from '@aws-amplify/ui';

interface AuthUser {
  name?: string;
  email?: string;
}

interface AuthContextValue {
  logout: () => void;
  login: () => void;
  user?: AuthUser;
  isLoading: boolean;
  error?: Key | null | undefined;
  isAuthenticated: boolean;
  getAccessToken: () => Promise<string>;
}

interface AuthProviderProps {
  cognitoUser?: AmplifyUser;
  logout?: (data?: AuthEventData | undefined) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  logout: () => {},
  login: () => {},
  isLoading: true,
  error: undefined,
  isAuthenticated: false,
  getAccessToken: () => Promise.resolve(''),
});

export const useAuth = () => useContext(AuthContext);

const e2e = process.env.REACT_APP_ENVIRONMENT === 'e2e';

export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({
  children,
  cognitoUser,
  logout,
}) => {
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const [authError, setAuthError] = useState<Error | undefined>(undefined);
  const [cognitoUserAttributes, setCognitoUserAttributes] = useState<
    Record<ICognitoUserAttributeData['Name'], ICognitoUserAttributeData['Value']>
  >({});
  const notFunctional = useCallback(() => alert('Not functional in e2e testing'), []);

  useEffect(() => {
    if (!cognitoUser) return;

    setAuthIsLoading(true);
    getCognitoUserAttributes(cognitoUser)
      .then(attributes => {
        setCognitoUserAttributes(attributes);
        setAuthIsLoading(false);
      })
      .catch(error => {
        setAuthError(new Error(error));
      });
  }, [cognitoUser]);

  const authIsAuthenticated = !!cognitoUser;

  const getUserAccessToken = useCallback(async () => {
    const currentSession = await Auth.currentSession();

    return Promise.resolve(currentSession.getAccessToken().getJwtToken() || '');
  }, []);

  const e2eGetApiTokenCallback = useCallback(() => Promise.resolve(e2eGetApiToken()!), []);
  const getAccessToken = e2e ? e2eGetApiTokenCallback : getUserAccessToken;
  const isAuthenticated = e2e || authIsAuthenticated;
  const login = e2e ? notFunctional : Auth.currentSession;
  const user: AuthUser | undefined = e2e
    ? { name: 'e2e test user' }
    : { name: cognitoUserAttributes?.name, email: cognitoUserAttributes?.email };
  const isLoading = !e2e && authIsLoading;
  const error = e2e ? resolveE2eError() : authError;

  return (
    <AuthContext.Provider
      value={{
        logout: logout ? logout : () => {},
        user,
        isLoading,
        error: error?.message,
        login,
        isAuthenticated,
        getAccessToken,
      }}
    >
      {authIsAuthenticated && children}
    </AuthContext.Provider>
  );
};

function resolveE2eError() {
  return e2eGetApiToken()
    ? undefined
    : new Error('E2e testing: no access token found (which is OK if the app is started not from cypress)');
}

function e2eGetApiToken(): string | undefined {
  return localStorage.getItem('e2e.accessToken') ?? undefined;
}

function getCognitoUserAttributes(
  cognitoUser: AmplifyUser,
): Promise<Record<ICognitoUserAttributeData['Name'], ICognitoUserAttributeData['Value']>> {
  return new Promise((resolve, reject) => {
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      }
      const attributes: Record<ICognitoUserAttributeData['Name'], ICognitoUserAttributeData['Value']> = {};
      result?.forEach(attribute => {
        if (attribute.getName()) {
          attributes[attribute.getName()] = attribute.getValue();
        }
      });
      resolve(attributes);
    });
  });
}
