import { AxiosInstance } from 'axios';

import { PosClassifierEntry } from './types';

export class PosClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<PosClassifierEntry[]>('/pos/api/classifiers');
  }
}
