import React from 'react';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary';

import { Alert, AlertTitle, Box, Button, Paper, Stack } from '@mui/material';

import { routePaths, useRouter } from '../../app/router';

type Props = FallbackProps;

export const ErrorFallback: React.FC<Props> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper variant="outlined" sx={{ p: 3 }}>
        <Stack gap={1}>
          <Alert severity="error">
            <AlertTitle>{t('error-warning')}</AlertTitle>
            {error.message}
          </Alert>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" color="primary" onClick={resetErrorBoundary}>
              {t('error-action')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleHomeClick}>
              {t('go-home')}
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );

  function handleHomeClick() {
    router.push(routePaths.pos.order);
    resetErrorBoundary();
  }
};
