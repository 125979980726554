import { PricedProductReference, ServiceFee } from '../../api/product/types';
import { productPricingToForm } from '../../product-catalog/common/productPricing';

export const serviceFeeToForm = (serviceFee: ServiceFee) => ({
  trigger: serviceFee?.trigger,
  product: serviceFee?.product?.product?.name
    ? {
        name: serviceFee?.product?.product?.name,
        product: serviceFee?.product?.product?.id,
        productCategory: serviceFee?.product?.product?.productCategory,
        productPricing: serviceFee?.product?.productPricing
          ? productPricingToForm(serviceFee?.product?.productPricing)
          : [],
      }
    : undefined,
});

export const hiddenProductsToForm = ({ product, productPricing }: PricedProductReference) => ({
  name: product?.name,
  product: product?.id,
  productPricing: productPricing?.length ? productPricingToForm(productPricing) : [],
});
