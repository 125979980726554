import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Collapse, IconButton, List, Stack } from '@mui/material';

import { ListItem } from './ListItem';
import { useRouter } from '../../../app/router';
import { MUI_APP_BAR_HEIGHT } from '../../theme';
import { usePermissionsContext } from '../../../app/contexts/PermissionsContext';
import { isNavigationItem, NavigationItem, NavigationItemChild, navigationItems } from '../../../app/navigation';

interface Props {
  drawerOpen: boolean;
  handleToggleOpen(): void;
}

export const NavigationList: React.FC<Props> = React.memo(({ drawerOpen, handleToggleOpen }) => {
  const router = useRouter();
  const { isRoutePermitted } = usePermissionsContext();
  const [openItems, setOpenItems] = useState<number[]>([]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={drawerOpen ? 'flex-end' : 'center'}
        sx={{
          px: drawerOpen ? 1 : 0,
          height: MUI_APP_BAR_HEIGHT,
        }}
      >
        {
          <IconButton disableRipple onClick={handleToggleOpen} sx={{ color: 'white' }}>
            {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon fontSize="large" />}
          </IconButton>
        }
      </Stack>
      <List component="nav">
        {navigationItems.map((item, idx) => (
          <React.Fragment key={idx}>
            <ListItem
              item={item}
              visible={isVisible(item.path, item.items)}
              isDrawerOpen={drawerOpen}
              isOpen={isItemOpen(idx)}
              selected={
                item.path?.includes(router.pathname) ||
                (!drawerOpen && item.items?.some(i => i.path?.includes(router.pathname)))
              }
              onItemClicked={handleMenuItemClick(item, idx)}
            />
            <Collapse in={isItemOpen(idx)} timeout="auto">
              <List component="div" disablePadding>
                {item.items?.map((nestedItem, idx) => (
                  <ListItem
                    key={idx}
                    isDrawerOpen={drawerOpen}
                    item={nestedItem}
                    visible={isVisible(nestedItem.path)}
                    selected={router.pathname === nestedItem.path}
                    onItemClicked={handleMenuItemClick(nestedItem, idx)}
                  />
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </>
  );

  function handleMenuItemClick(item: NavigationItem | NavigationItemChild, idx: number) {
    return () => {
      if (item.path) {
        return router.push(item.path);
      }
      if (isNavigationItem(item) && item.items?.length) {
        !drawerOpen && handleToggleOpen();

        return isItemOpen(idx) ? setOpenItems(openItems.filter(id => id !== idx)) : setOpenItems([...openItems, idx]);
      }
    };
  }

  function isItemOpen(idx: number) {
    return drawerOpen && openItems.includes(idx);
  }

  function isVisible(path?: string, items?: NavigationItemChild[]) {
    const routePermitted = path && isRoutePermitted(path);
    const itemRoutesPermitted = items && items.some(item => item.path && isRoutePermitted(item.path));

    return !!routePermitted || !!itemRoutesPermitted;
  }
});
