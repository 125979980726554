import { ColDef, SideBarDef } from '@ag-grid-community/core';

import { FilterOperation, GridFilterOperation } from './types';

export const columnTypes = ['string', 'number', 'date', 'dateTime', 'datePreciseTime'] as const;

export const defaultColDef: ColDef = {
  flex: 1,
  sortable: true,
  filter: false,
  resizable: true,
  menuTabs: ['filterMenuTab'],
  minWidth: 150,
};

export const sideBarDef: SideBarDef = {
  hiddenByDefault: false,
  position: 'right',
  toolPanels: [
    'filters',
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
};

export const gridFilterOperationMap: Record<GridFilterOperation, FilterOperation> = {
  [GridFilterOperation.InRange]: FilterOperation.Between,
  [GridFilterOperation.Empty]: FilterOperation.Blank,
  [GridFilterOperation.Contains]: FilterOperation.Contains,
  [GridFilterOperation.Equals]: FilterOperation.Equal,
  [GridFilterOperation.GreaterThan]: FilterOperation.GreaterThan,
  [GridFilterOperation.LessThan]: FilterOperation.LessThan,
};
