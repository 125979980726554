import { format, formatISO, isAfter, isBefore, isValid, parseISO, setDefaultOptions } from 'date-fns';

export const setDefaultDateFnsLocale = async (tenantLocale: string) => {
  try {
    const locale = await import(`date-fns/locale/${tenantLocale.replace('_', '-')}`);
    setDefaultOptions({ locale: locale.default });
  } catch (error) {
    setDefaultOptions({});
  }
};

export const dateComparator = (filterDate: Date, dateWithTime: string) => {
  if (!dateWithTime) {
    return 0;
  }

  const dateOnly = parseISO(dateWithTime.substr(0, 10));

  if (isBefore(dateOnly, filterDate)) {
    return -1;
  }

  if (isAfter(dateOnly, filterDate)) {
    return 1;
  }

  return 0;
};

export const getDateFormatFromLocale = (
  dateFormatType: 'Date' | 'DateWithMonthName' | 'DateWithTime' | 'DateWithPreciseTime' = 'Date',
): string => {
  switch (dateFormatType) {
    case 'Date': {
      return 'P';
    }
    case 'DateWithMonthName': {
      return 'PP';
    }
    case 'DateWithTime': {
      return 'Pp';
    }
    case 'DateWithPreciseTime': {
      return 'Ppp';
    }
  }
};

export const formatDate = (
  date: Date | string | undefined,
  dateFormat: 'Date' | 'DateWithMonthName' | 'DateWithTime' | 'DateWithPreciseTime' = 'Date',
): string => {
  if (!date) return '';

  const value = date instanceof Date ? date.toISOString() : date;
  const localeFormat = getDateFormatFromLocale(dateFormat);

  return format(normalizeDate(parseISO(value)), localeFormat);
};

export const normalizeDate = (date: Date) => new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));

export const normalizeDateOnly = (date: Date) => dateToIsoDate(normalizeDate(new Date(date)));

/**
 * Allow empty string validation.
 * https://github.com/jquense/yup/issues/764
 */
export const normalizeOptionalDate = (curr: Date | undefined, orig: Date | string) => {
  if (orig === '') {
    return null;
  }

  return curr ? normalizeDate(curr) : null;
};

export const dateToIsoDate = (date: Date | undefined) => {
  if (isValid(date)) {
    return formatISO(date as Date | number, { representation: 'date' });
  }

  return '';
};

export const isoStringToDate = (isoString: string | undefined) => {
  if (typeof isoString !== 'string') {
    return undefined;
  }

  return parseISO(isoString);
};
