import {
  AccountBox,
  MoneyOff,
  Receipt,
  ShoppingCart,
  Speed,
  SupervisedUserCircle,
  Work,
  Inventory,
} from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { routePaths } from './router';

export interface NavigationItem {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  path?: string;
  items?: NavigationItemChild[];
}

export type NavigationItemChild = Omit<NavigationItem, 'items' | 'icon'>;

export const navigationItems: NavigationItem[] = [
  {
    name: 'pos',
    icon: ShoppingCart,
    path: routePaths.pos.order,
  },
  {
    name: 'order-management.management',
    icon: Speed,
    items: [
      { name: 'order-management.tasks', path: routePaths.orderManagement.tasksList },
      { name: 'order-management.orders', path: routePaths.orderManagement.ordersList },
    ],
  },
  { name: 'customers', icon: AccountBox, path: routePaths.crm.customer.search },
  {
    name: 'product.catalog',
    icon: MoneyOff,
    items: [
      { name: 'product.offers', path: routePaths.productCatalog.offers.list },
      { name: 'product.products', path: routePaths.productCatalog.products.list },
      { name: 'product.components', path: routePaths.productCatalog.components.list },
      {
        name: 'product.eligibility-rules',
        path: routePaths.productCatalog.eligibilityRules.list,
      },
      { name: 'product.charge-codes', path: routePaths.productCatalog.chargeCodes.list },
      { name: 'product.service-fees', path: routePaths.productCatalog.serviceFees.list },
      { name: 'product.penalty-fees', path: routePaths.productCatalog.penaltyFees.list },
    ],
  },
  {
    name: 'user-management.title',
    icon: SupervisedUserCircle,
    items: [
      { name: 'user-management.organisations', path: routePaths.userManagement.organisations },
      { name: 'user-management.roles', path: routePaths.userManagement.roles.list },
      { name: 'user-management.users', path: routePaths.userManagement.users.list },
      { name: 'user-management.users-pools', path: routePaths.userManagement.userPools.list },
    ],
  },
  {
    name: 'tickets',
    icon: Work,
    path: routePaths.crm.ticket.list,
  },
  {
    name: 'billing.title',
    icon: Receipt,
    items: [
      { name: 'billing.bill-runs.title', path: routePaths.billing.billRuns.list },
      { name: 'billing.payments.title', path: routePaths.billing.payments.list },
    ],
  },
  {
    name: 'inventory-management.title',
    icon: Inventory,
    items: [
      {
        name: 'inventory-management.inventory-type.list.title',
        path: routePaths.inventoryManagement.inventoryType.list,
      },
    ],
  },
];

export const isNavigationItem = (object: NavigationItem | NavigationItemChild): object is NavigationItem =>
  'icon' in object;
