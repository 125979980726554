import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const TasksList = React.lazy(() => import('./task/TasksListPage/TasksListPage'));
const ViewTaskPage = React.lazy(() => import('./task/ViewTaskPage/ViewTaskPage'));
const OrdersListPage = React.lazy(() => import('./order/OrdersListPage/OrdersListPage'));
const CustomerOrderPage = React.lazy(() => import('./order/CustomerOrderPage'));

export const orderManagementRoutes = () =>
  new RoutesConfig({
    tasksList: {
      permission: { type: 'OM', actions: ['VIEW'], groups: ['TASK'] },
      path: routePaths.orderManagement.tasksList,
      element: <TasksList />,
    },
    task: {
      permission: { type: 'OM', actions: ['VIEW'], groups: ['TASK'] },
      path: routePaths.orderManagement.task,
      element: <ViewTaskPage />,
    },
    ordersList: {
      permission: {
        type: 'OM',
        groups: ['ORDER'],
        actions: ['VIEW'],
      },
      path: routePaths.orderManagement.ordersList,
      element: <OrdersListPage />,
    },
    order: {
      permission: { type: 'OM', actions: ['VIEW'] },
      path: routePaths.orderManagement.order,
      element: <CustomerOrderPage />,
    },
  });
