import React from 'react';
import { Controller, Path, PathValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField as MuiTextField, TextFieldProps, useTheme } from '@mui/material';

import { interpolatedTranslation } from '../../app/i18n';

interface Props<T> {
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  noMarginBottom?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}

export const TextField = <T extends Record<string, any>>({
  name,
  defaultValue,
  noMarginBottom,
  textAlign,
  variant = 'standard',
  required = false,
  ...props
}: Props<T> & TextFieldProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ('' as any)}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <MuiTextField
          sx={{
            flex: 1,
            marginBottom: noMarginBottom ? 0 : theme.spacing(3),
            align: textAlign,
            '& input': {
              ...(textAlign && { textAlign: textAlign ?? 'left' }),
            },
            [theme.breakpoints.down('xs')]: {
              '& label': {
                fontSize: 12,
              },
            },
          }}
          helperText={
            error?.message &&
            i18n.exists(...interpolatedTranslation(error.message)) &&
            t(...interpolatedTranslation(error.message))
          }
          required={required}
          variant={variant}
          error={!!error}
          {...field}
          {...props}
        />
      )}
    />
  );
};
