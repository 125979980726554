import React from 'react';

import { Backdrop } from '@mui/material';

import { Loader } from '.';

interface Props {
  open?: boolean;
}

export const OverlayedLoader: React.FC<Props> = ({ open = true }) => {
  return open ? (
    <Backdrop
      open
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Loader size="large" />
    </Backdrop>
  ) : null;
};
