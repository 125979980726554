import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const OrganisationsPage = React.lazy(() => import('./organisations/OrganisationsPage'));
const RolesListPage = React.lazy(() => import('./roles/RolesListPage/RolesListPage'));
const NewRolePage = React.lazy(() => import('./roles/NewRolePage'));
const EditRolePage = React.lazy(() => import('./roles/EditRolePage'));
const UsersListPage = React.lazy(() => import('./users/UsersListPage/UsersListPage'));
const NewUserPage = React.lazy(() => import('./users/NewUserPage'));
const EditUserPage = React.lazy(() => import('./users/EditUserPage'));
const UserPoolsPage = React.lazy(() => import('./user-pools/UserPoolsListPage/UserPoolsPage'));
const NewUserPoolPage = React.lazy(() => import('./user-pools/NewUserPoolPage'));
const EditUserPoolPage = React.lazy(() => import('./user-pools/EditUserPoolPage'));

export const userManagementRoutes = () =>
  new RoutesConfig({
    organisations: {
      permission: { type: 'SSO', groups: ['ORGANISATION'], actions: ['VIEW'] },
      path: routePaths.userManagement.organisations,
      element: <OrganisationsPage />,
    },
    roles: {
      permission: { type: 'SSO', groups: ['ROLE'], actions: ['VIEW'] },
      path: routePaths.userManagement.roles.list,
      element: <RolesListPage />,
    },
    newRole: {
      permission: { type: 'SSO', groups: ['ROLE'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.userManagement.roles.new,
      element: <NewRolePage />,
    },
    editRole: {
      permission: { type: 'SSO', groups: ['ROLE'], actions: ['VIEW'] },
      path: routePaths.userManagement.roles.edit,
      element: <EditRolePage />,
    },
    users: {
      permission: { type: 'SSO', groups: ['USER'], actions: ['VIEW'] },
      path: routePaths.userManagement.users.list,
      element: <UsersListPage />,
    },
    newUser: {
      permission: { type: 'SSO', groups: ['USER'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.userManagement.users.new,
      element: <NewUserPage />,
    },
    editUser: {
      permission: { type: 'SSO', groups: ['USER'], actions: ['VIEW'] },
      path: routePaths.userManagement.users.edit,
      element: <EditUserPage />,
    },
    userPools: {
      permission: { type: 'SSO', groups: ['USER_POOL'], actions: ['VIEW'] },
      path: routePaths.userManagement.userPools.list,
      element: <UserPoolsPage />,
    },
    newUserPool: {
      permission: { type: 'SSO', groups: ['USER_POOL'], actions: ['VIEW', 'EDIT'] },
      path: routePaths.userManagement.userPools.new,
      element: <NewUserPoolPage />,
    },
    editUserPool: {
      permission: { type: 'SSO', groups: ['USER_POOL'], actions: ['VIEW'] },
      path: routePaths.userManagement.userPools.edit,
      element: <EditUserPoolPage />,
    },
  });
