import { AxiosInstance } from 'axios';

import { TaskClassifierEntry } from './types';

export class TaskClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<TaskClassifierEntry[]>('/task/api/classifiers');
  }
}
