import React from 'react';

import { Typography, Box, Button, Stack } from '@mui/material';
import { routePaths, useRouter } from '../../app/router';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
      }}
    >
      <Stack gap={1} maxWidth="500px">
        <Typography variant="h1" textAlign="center">
          404
        </Typography>
        <Typography variant="h5" textAlign="center">
          {t('page-not-found.title')}
        </Typography>
        <Box>
          <Typography>{t('page-not-found.message.requested-url-not-valid')}</Typography>
          <Typography>{t('page-not-found.message.contact-support')}</Typography>
          <Typography>{t('page-not-found.message.we-appreciate-your-understanding')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleHomeClick}>
            {t('go-home')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );

  function handleHomeClick() {
    router.push(routePaths.pos.order);
  }
};
