import { AxiosInstance } from 'axios';

import { TicketClassifierEntry } from './types';

export class TicketClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<TicketClassifierEntry[]>('/ticket/api/classifiers');
  }
}
