export enum GITHUB_ENVIRONMENT_NAME {
  DEV = 'dev',
  DEMO = 'demo-next',
  DEMO_US = 'demo-us',
  E2E = 'e2e',
  STAGING = 'staging',
  MMD_STAGING = 'mmd-staging',
  MMD_PROD = 'mmd-prod',
  PROD = 'prod',
  PROD_US = 'prod-us',
}
