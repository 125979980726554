import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, useTheme } from '@mui/material';

import { SelectOption } from '../../app/types';
import { EMPTY_STR_OPTION } from './SelectField';

export interface MuiSelectFieldProps {
  name: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  isRequired?: boolean;
  label: string;
  options: SelectOption[];
  variant?: SelectProps['variant'];
  onChanged?(value: string): void;
  marginBottom?: number;
}

export const MuiSelectField: React.FC<MuiSelectFieldProps> = ({
  name,
  label,
  options,
  value,
  defaultValue,
  disabled = false,
  isRequired = false,
  variant = 'standard',
  marginBottom = 3,
  onChanged,
}) => {
  const theme = useTheme();

  return (
    <FormControl
      variant={variant}
      sx={{
        flex: 1,
        width: '100%',
        marginBottom: theme.spacing(marginBottom),
        [theme.breakpoints.down('sm')]: {
          '& label': {
            fontSize: 12,
          },
        },
      }}
    >
      <InputLabel disabled={disabled} required={isRequired}>
        {label}
      </InputLabel>
      <Select
        id={name}
        label={label}
        data-test={name}
        fullWidth
        value={value}
        defaultValue={defaultValue || EMPTY_STR_OPTION.value}
        disabled={disabled}
        required={isRequired}
        onChange={handleChange}
        variant={variant}
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function handleChange(event: SelectChangeEvent) {
    onChanged && onChanged(event.target.value);
  }
};
