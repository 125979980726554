import { ThemeOptions } from '@mui/material';

import { mmdProdTenantDemoLogo, mmdProdTenantDemoThemeOptions } from './tenants/tenantMmdDemo';
import { mmdProdTenantCMobileLogo, mmdProdTenantCMobileThemeOptions } from './tenants/tenantCMobile';

export const MMD_PROD_TENANTS_ID_MAP = {
  C_MOBILE: '1',
  MMD_DEMO: '2',
};

export const MMD_PROD_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [MMD_PROD_TENANTS_ID_MAP.C_MOBILE]: mmdProdTenantCMobileThemeOptions,
  [MMD_PROD_TENANTS_ID_MAP.MMD_DEMO]: mmdProdTenantDemoThemeOptions,
};

export const MMD_PROD_LOGO_BY_TENANT_MAP = {
  [MMD_PROD_TENANTS_ID_MAP.C_MOBILE]: mmdProdTenantCMobileLogo,
  [MMD_PROD_TENANTS_ID_MAP.MMD_DEMO]: mmdProdTenantDemoLogo,
};
