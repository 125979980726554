import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { useApplicationContext } from '../../app/contexts';
import { getTenantThemeOptions } from './themeUtils';
import { getMuiLocale } from '../../app/i18n';

export const ThemeProvider: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
  const { i18n } = useTranslation();
  const { tenant } = useApplicationContext();
  const muiLocale = getMuiLocale(i18n.language);
  const currentTheme = useMemo(
    () => responsiveFontSizes(createTheme(getTenantThemeOptions(tenant?.id), muiLocale)),
    [muiLocale, tenant?.id],
  );

  return <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>;
});
