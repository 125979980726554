import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { SuccessResponse } from '../types';
import { DOCUMENT_STATUS, DocumentUploadResponse } from './types';

export class DocumentApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async downloadDocument(id: string, fileName: string) {
    const result = await this.axios.get(`/doc-upload/api/document/download/${id}`, { responseType: 'blob' });
    fileDownload(result.data, fileName);

    return result;
  }

  public async uploadDocument(file: File) {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('metadata', JSON.stringify({ documentName: file.name, description: 'test' }));

    return await this.axios.post<DocumentUploadResponse>('/doc-upload/api/document/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async updateDocumentStatus(id: string, status: DOCUMENT_STATUS) {
    return await this.axios.put<SuccessResponse>(`/doc-upload/api/document/status/${id}`, { status });
  }
}
