import React from 'react';

import { routePaths, RoutesConfig } from '../app/router';
const OrderPage = React.lazy(() => import('./OrderPage/OrderPage'));
const ManageAddOnPage = React.lazy(() => import('./ManageAddOnPage/ManageAddOnPage'));
const ProductChangePage = React.lazy(() => import('./ProductChangePage/ProductChangePage'));
const MainProductTerminationPage = React.lazy(() => import('./MainProductTerminationPage/MainProductTerminationPage'));

export const posRoutes = () =>
  new RoutesConfig({
    homePage: {
      permission: { type: 'POS', actions: ['VIEW'] },
      path: routePaths.homePage,
      element: <OrderPage />,
    },
    orderWithId: {
      permission: { type: 'POS', actions: ['VIEW'] },
      path: routePaths.pos.orderWithId,
      element: <OrderPage />,
    },
    order: {
      permission: { type: 'POS', actions: ['VIEW'] },
      path: routePaths.pos.order,
      element: <OrderPage />,
    },
    manageAddOn: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.pos.manageAddOn,
      element: <ManageAddOnPage />,
    },
    change: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.pos.change,
      element: <ProductChangePage />,
    },
    mainProductTermination: {
      permission: { type: 'CRM', actions: ['VIEW', 'EDIT'] },
      path: routePaths.pos.mainProductTermination,
      element: <MainProductTerminationPage />,
    },
  });
