import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Fade, Paper, Typography, useTheme } from '@mui/material';

import { LoadingButton, Row } from '../..';

export interface Props {
  description: string;
  title?: string;
  agreeLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
  onAgreed(): void;
  onClosed(): void;
}

export const ActionModalLayout = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ description, title, agreeLabel, cancelLabel, isLoading = false, onAgreed, onClosed }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <Fade in>
        <Paper
          sx={{
            minHeight: 160,
            minWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 4, 3),
          }}
          ref={ref}
          tabIndex={-1}
        >
          <Box flex={1}>
            <Typography id="modal-title" variant="h5" color="primary" align="center">
              {title ?? t('modal.title')}
            </Typography>
            <Box marginY={1}>
              <Typography id="modal-description" component="p" variant="body1" align="center">
                {description}
              </Typography>
            </Box>
          </Box>
          <Row marginTop={4} justifyContent="center">
            <Button variant="outlined" color="primary" onClick={onClosed} name="confirmation.no">
              {cancelLabel ?? t('modal.no')}
            </Button>
            <Box marginRight={1} />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={onAgreed}
              isLoading={isLoading}
              name="confirmation.yes"
            >
              {agreeLabel ?? t('modal.yes')}
            </LoadingButton>
          </Row>
        </Paper>
      </Fade>
    );
  },
);
