import { ThemeOptions } from '@mui/material';

import cMobileLogo from './images/c-mobile.png';

export const mmdStagingTenant1ThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#01311f',
    },
    secondary: {
      main: '#99742c',
    },
    success: {
      main: '#4caf50',
    },
  },
};

export const mmdStagingTenant1Logo = cMobileLogo;
