import { OrderCustomer } from '../../api/order/types';
import { Customer } from '../../api/crm/types';
import { DefaultValues } from 'react-hook-form';
import { BusinessCustomer, PrivateCustomer } from '../schemas';
import { isoStringToDate } from '../date';
import { consentsToForm } from './utils';

export const orderCustomerToForm = (
  customer: OrderCustomer | Customer | undefined,
): DefaultValues<BusinessCustomer | PrivateCustomer> | undefined => {
  if (!customer) return undefined;

  if (customer?.customerType === 'BUSINESS') {
    return {
      ...customer,
      consents: consentsToForm(customer),
      legalRepresentative: {
        ...customer?.legalRepresentative,
        birthDate: isoStringToDate(customer?.legalRepresentative?.birthDate),
        identityExpiryDate: isoStringToDate(customer?.legalRepresentative?.identityExpiryDate),
      },
    };
  }

  return {
    ...customer,
    consents: consentsToForm(customer),
    birthDate: isoStringToDate(customer?.birthDate),
    identityExpiryDate: isoStringToDate(customer?.identityExpiryDate),
  };
};
