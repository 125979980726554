import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Button } from '@mui/material';

import { ButtonCellRendererParams } from '../types';

type Props<T> = ButtonCellRendererParams<T> & ICellRendererParams;

export const ButtonCellRenderer = <T extends unknown>({ data, valueFormatted, context: { onClicked } }: Props<T>) => {
  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      sx={{
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        paddingLeft: 0,
        '&:hover': {
          background: 'none',
        },
      }}
      onClick={handleClick}
    >
      {valueFormatted}
    </Button>
  );

  function handleClick() {
    onClicked(data);
  }
};
