import { AxiosInstance } from 'axios';

import { NetworkServices, ProvisioningTataClassifier, TataSubscription } from './types';

export class ProvisioningTataApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<ProvisioningTataClassifier[]>('/provisioning-tata/api/classifiers');
  }

  public async subscription(subscriptionId: string) {
    return await this.axios.get<TataSubscription>(`/provisioning-tata/api/subscription/${subscriptionId}`);
  }

  public async networkServices(subscriptionId: string) {
    return await this.axios.get<NetworkServices>(
      `/provisioning-tata/api/subscription/${subscriptionId}/network-services`,
    );
  }
}
