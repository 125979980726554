import React, { useContext, useEffect, useRef } from 'react';

import { Customer } from '../../api/crm/types';
import { useApiCallback } from '../../app/api-hooks';
import { useRouter } from '../../app/router';

interface RouteParams {
  customerId?: string;
}

interface CustomerContextValue {
  loading: boolean;
  error?: string;
  customer?: Customer;
  customerId?: string;
}

const CustomerContext = React.createContext<CustomerContextValue>({ loading: true });

export const useCustomerContext = () => useContext(CustomerContext);

export const CustomerContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    params: { customerId },
  } = useRouter<RouteParams>();
  const customerCb = useApiCallback((api, customerId: string) => api.crm.customer(customerId));
  const loadCustomer = useRef(customerCb.execute);

  useEffect(() => {
    customerId && loadCustomer.current(customerId);
  }, [customerId]);

  return (
    <CustomerContext.Provider
      value={{
        customer: customerCb.result?.data,
        customerId,
        loading: !!customerId && customerCb.loading,
        error: customerCb.error?.message,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
