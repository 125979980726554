import { AxiosInstance } from 'axios';

import { ProvisioningClassifier } from './types';

export class ProvisioningClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<ProvisioningClassifier[]>('/provisioning/api/classifiers');
  }
}
