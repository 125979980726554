import { createContext, useContext, useState } from 'react';

import { Modal, ModalProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type EventModalProps = Omit<ModalProps, 'open'>;

interface ModalContextValues {
  isOpen: boolean;
  open: (modalProps: EventModalProps) => void;
  close: () => void;
}

interface UseModalReturnType {
  isOpen: boolean;
  open: (eventModalProps?: EventModalProps) => void;
  close: () => void;
}

const ModalContext = createContext<ModalContextValues>({
  isOpen: false,
  open: () => {},
  close: () => {},
});

const DEFAULT_CONFIG: ModalProps = { open: false, children: <></> };

export const ModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();
  const [config, setConfig] = useState<ModalProps>(DEFAULT_CONFIG);

  const open = (config: EventModalProps) => setConfig({ ...config, open: true });
  const close = () => setConfig(prev => ({ ...prev, open: false }));

  return (
    <ModalContext.Provider
      value={{
        isOpen: config.open,
        open,
        close,
      }}
    >
      {children}
      <Modal className={classes.modal} {...config} />
    </ModalContext.Provider>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const useModal = (modalProps: ModalProps = DEFAULT_CONFIG): UseModalReturnType => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  const open = (eventConfig?: EventModalProps) => context.open(eventConfig ?? modalProps);

  return { open, close: context.close, isOpen: context.isOpen };
};
