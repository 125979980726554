import { Amount } from '../crm/types';
import { OfferCategory } from '../offer/types';
import { CustomerType, DeliveryType, IdentityType as DEFAULT_IDENTITY_TYPES } from '../../business';
import { ActivationDateOption } from '../../common';
import { ProductPricing } from '../product/types';
import { IDENTITY_TYPES as INDIAN_IDENTITY_TYPES } from '../../business/countries/india';

export interface ExistingProductParams {
  subscriptionId: string;
  orderProductId: string;
  terminationDate?: string;
}

export interface SetTerminatingProductParams {
  subscriptionId: string;
  orderProductId: string;
  terminationDate?: string;
}

export interface ModifySubscriptionParams {
  subscriptionId: string;
  orderProductIds: string[];
  terminationDate?: string;
}

export interface OrderResource {
  subscriptionId: string;
  orderProductId: string;
  inventoryTypeId: string;
  inventoryTypeVariantId?: string;
  serial?: string;
  donorMsisdn?: string;
  donorOperator?: string;
  donorServiceType: 'POSTPAID' | 'PREPAID';
}

export interface IOrderDocuments {
  documents: Document[];
}

interface Document {
  documentMetadataId: string;
  documentName: string;
  templateId?: string;
  orderProductId?: string;
}

export interface CommonBalanceGroupMember {
  orderProductFieldId: string;
  subscriptionId: string;
  customerName: string;
  subscriptionIdentifier: string;
  subscriptionType: string;
  status: string;
  customerId: string;
}

export interface AddCommonBalanceGroupParams {
  orderProductFieldId: string;
  subscriptionId: string;
}

export interface MatchingOfferParams {
  customer?: OfferCustomer;
  subscription?: SubscriptionParams;
  subscriptionTerm?: string;
  subscriptionType?: SUBSCRIPTION_TYPE;
  offerCategories?: string[];
  name?: string;
  activationDate?: string;
  activationDateOption?: ActivationDateOption;
  salesChannel: 'POS';
  orderType: ORDER_TYPE;
}

export interface SubscriptionParams {
  selectedProduct?: SubscriptionSelectedProduct;
  existingSubscriptionId?: string;
}

export interface SubscriptionSelectedProduct {
  productId?: string;
  pricingId?: string;
  parentId?: string;
  orderProductId?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractRenewalFrom?: string;
  productPricing?: ProductPricing;
  childProduct?: SubscriptionSelectedProduct;
  selectedProductOperation: ProductOperation;
}

interface OfferCustomer {
  existingCustomerId?: string;
  age?: number;
  addressRule?: Address;
  customerType?: CustomerType;
}

export interface MatchingOfferResult {
  id: string;
  name: string;
  commercialName: string;
  description: string;
  offerCategories: OfferCategory[];
  subscriptionTerm: number;
  subscriptionType: string;
  validFrom: string;
  validTill: string;
  productStatus: string;
  createdAt: string;
  groups: OfferGroup[];
  priceCases: CustomerOrderPriceCase[];
  offerRules: OfferRule[];
}

interface OfferRule {
  ruleId: string;
  name: string;
  description: string;
}

export interface CustomerOrderPriceCase {
  name: string;
  products: PosProducts[];
}

export interface PosProducts {
  product: Product;
  productPricing: ProductPricing[];
  min?: number;
  max?: number;
}

export interface OfferGroup {
  groupId: string;
  dependsOn: OfferCategory;
  name: string;
  rule: Rule;
  products: PosProducts[];
}
interface Product {
  existing?: boolean;
  id: string;
  name: string;
  commercialName: string;
  categoryName: string;
}

export interface Rule {
  type: string;
  from: number;
  to: number;
}

export interface Order {
  id: string;
  omOrderId: string;
  tenantId: string;
  orderStatus: string;
  orderType: ORDER_TYPE;
  customer: OrderCustomer;
  account: Account;
  consents: Consents;
  orderItems: OrderItem[];
  deliveryAddress: Address;
  offers: OrderOffer[];
  payment?: Partial<PosPayment>;
  upfrontPayment?: Amount;
  totalPayment?: Amount;
  refund?: Amount;
  penalty?: Amount;
  existingSubscription?: OrderSubscription;
  terminatingSubscription?: OrderSubscription;
  documents?: Document[];
}

export interface OrderSubscription {
  subscriptionId: string;
  nextBillCycleDate?: string;
  products: CustomerOrderSelectedProduct[];
}

export interface PosPayment {
  payerAccountId?: string;
  callbackUrl: string;
  paymentType: string;
  msisdn?: string;
}

interface OrderItem {
  subscriptionId: string;
  identifier: string;
  primaryOffer: PrimaryOffer;
  supplementaryOffers: PrimaryOffer[];
  terminatingPrimaryOffer: PrimaryOffer;
  existing: boolean;
}

interface PrimaryOffer {
  orderOfferId: string;
  productId: string;
  name: string;
  commercialName: string;
  offerCategoryType: string;
  basic: Basic[];
  addOn: Basic[];
  activationDate: string;
  subscriptionTerm: number;
  subscriptionType: string;
}

interface Basic {
  productId: string;
  name: string;
  commercialName: string;
  description: string;
  productPricing: ProductPricing;
  orderServiceItems: OrderServiceItem[];
}

interface OrderServiceItem {
  serviceItemId: string;
  name: string;
  inventoryTypes: InventoryType[];
}

interface InventoryType {
  id: string;
  inventoryType: string;
  name: string;
  commercialName: string;
  dataType: string;
  item: InventorItem;
}

export interface InventoryTypeVariant {
  id?: string;
  name?: string;
  deliveryType?: DeliveryType.PhysicalDelivery | DeliveryType.EDelivery;
  sku?: string;
}

export interface InventorItem {
  serial?: string;
  inventoryId?: string;
  inventoryStatus?: string;
  inventoryTypeId: string;
  inventoryTypeName: string;
  selectedInventoryTypeVariantId?: string;
  inventoryTypeVariants?: InventoryTypeVariant[];
  inputType: string;
  orderInventoryTypeId?: string;
  delivery?: boolean;
  donorMsisdn?: string;
  donorOperator?: string;
  donorServiceType: 'POSTPAID' | 'PREPAID';
}

export interface OrderConsent {
  items: Consent[];
}

interface Consent {
  consentType: string;
  enabled: boolean;
}

interface Consents {
  items: Item[];
}

interface Item {
  consentType: string;
  enabled: boolean;
}

interface Account {
  id: string;
  contactPhoneNr: string;
  contactEmail: string;
  language: string;
  invoiceDeliveryType: string;
  iban: string;
  existing: boolean;
}
interface LegalRepresentative {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  country?: string;
  customerCode?: string;
  identityType?: DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES;
  identityNumber?: string;
  identityExpiryDate?: string;
  contactPhoneNo?: string;
  contactEmail?: string;
  address?: Address;
}

export interface OrderCustomer {
  id?: string;
  firstName?: string;
  lastName?: string;
  country?: string;
  companyName?: string;
  additionalName?: string;
  birthDate?: string;
  customerCode?: string;
  identityNumber?: string;
  identityType?: DEFAULT_IDENTITY_TYPES | INDIAN_IDENTITY_TYPES;
  identityExpiryDate?: string;
  contactPhoneNo?: string;
  contactEmail?: string;
  existing?: boolean;
  address?: Address;
  customerType?: CustomerType;
  tradingAs?: string;
  vatNumber?: string;
  taxNumber?: string;
  faxNo?: string;
  legalRepresentative?: LegalRepresentative;
}

export interface Address {
  region?: string;
  zipCode?: string;
  city?: string;
  district?: string;
  addressLine1?: string;
  addressLine2?: string;
}
export interface CustomerOrder {
  offers?: OrderOffer[];
}

export interface AddOrderOfferParams {
  offers: NewOfferRequest[];
}

interface NewOfferRequest {
  offerId: string;
  activationDate?: string;
  productGroups?: NewProductGroupRequest[];
}

export interface NewProductGroupRequest {
  productGroupId?: string;
  selectedProducts?: NewProductRequest[];
}

interface NewProductRequest {
  productId?: string;
  subscriptionId: string;
  orderProductId?: string;
  parentId?: string;
  pricingId?: string;
  existing: boolean;
}

export interface OrderOffer {
  offerId: string;
  productGroups?: CustomerOrderProductGroup[];
  name?: string;
  commercialName?: string;
  description?: string;
  subscriptionType: SUBSCRIPTION_TYPE;
  selectedProducts?: CustomerOrderSelectedProduct[];
  activationDate?: string;
}

export interface CustomerOrderProductGroup {
  rule?: Rule;
  productGroupId?: string;
  selectedProducts?: CustomerOrderSelectedProduct[];
}

export interface CustomerOrderSelectedProduct {
  productId?: string;
  parentId?: string;
  productPricing?: ProductPricing;
  pricingId?: string;
  subscriptionId?: string;
  orderProductId?: string;
  productGroupId?: string;
  name?: string;
  isChecked?: boolean;
  commercialName?: string;
  description?: string;
  inventoryFields: InventorItem[];
  existing?: boolean;
  subscriptionType?: SUBSCRIPTION_TYPE;
  productFields?: ProductField[];
  contractStartDate?: string;
  contractEndDate?: string;
  contractRenewalFrom?: string;
  children?: CustomerOrderSelectedProduct[];
}

export interface IAddProductFieldValues {
  orderProductFieldId?: string;
  value?: string;
}

export interface Compliance {
  id: string;
  name: string;
}

export interface ProductField {
  orderProductFieldId: string;
  code: string;
  name: string;
  type: string;
  identifier: boolean;
  value: string;
}

export type ProductOperation = 'CHANGE' | 'ADD_CHILD';

export enum ORDER_TYPE {
  TERMINATE_PRODUCT = 'TERMINATE_PRODUCT',
  MODIFY_SUBSCRIPTION = 'MODIFY_SUBSCRIPTION',
  NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
  CHANGE = 'CHANGE',
}

export enum SUBSCRIPTION_TYPE {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
}
