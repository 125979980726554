import { AxiosInstance, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';

import { CreateTicketForm, EditTicketForm } from '../../crm/ticket/common/Form/types';
import { TicketCommentForm } from '../../crm/ticket/common/TicketComment/validation';
import { ListRequestParams, ScrollableEntry, SuccessResponse } from '../types';
import {
  ChangeStatusForm,
  Ticket,
  TicketCategoryWithSubcategories,
  TicketHistoryList,
  TicketListItem,
  TicketUserForAssignment,
} from './types';

export class TicketApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async ticket(id: string): Promise<AxiosResponse<Ticket>> {
    return await this.axios.get<Ticket>(`/ticket/api/ticket/${id}`);
  }

  public async createTicket(form: CreateTicketForm) {
    return await this.axios.post<SuccessResponse>('/ticket/api/ticket', form);
  }

  public async createComment(ticketId: string, form: TicketCommentForm) {
    return await this.axios.post<SuccessResponse>(`/ticket/api/ticket/${ticketId}/comment`, form);
  }

  public async updateTicket(id: string, form: EditTicketForm) {
    return await this.axios.put<SuccessResponse>(`/ticket/api/ticket/${id}`, form);
  }

  public async assignTicketToMe(id: string) {
    return await this.axios.put<SuccessResponse>(`/ticket/api/ticket/${id}/assign-to-current-user`);
  }

  public async assignTicketToUser(ticketId: string, userId: string) {
    return await this.axios.put<SuccessResponse>(`/ticket/api/ticket/${ticketId}/assign`, { userId });
  }

  public async usersForAssignment(ticketCategoryId: string) {
    return await this.axios.get<TicketUserForAssignment[]>(
      `/ticket/api/ticket/users-for-assignment/${ticketCategoryId}`,
    );
  }

  public async unassignTicket(id: string) {
    return await this.axios.put<SuccessResponse>(`/ticket/api/ticket/${id}/unassign`);
  }

  public async limitedTicketsList(
    count: number,
    sortAndFilter: Pick<ListRequestParams, 'sort' | 'filter'>,
  ): Promise<AxiosResponse<ScrollableEntry<TicketListItem>>> {
    const result = await this.listTickets({ startRow: 0, endRow: count, ...sortAndFilter });

    return {
      ...result,
      data: {
        ...result.data,
        endRow: count,
        lastRow: result.data.lastRow ? Math.min(count, result.data.lastRow) : 0,
      },
    };
  }

  public async listTickets(params: ListRequestParams): Promise<AxiosResponse<ScrollableEntry<TicketListItem>>> {
    return await this.axios.get<ScrollableEntry<TicketListItem>>('/ticket/api/ticket', { params });
  }

  public async listCategories(): Promise<AxiosResponse<TicketCategoryWithSubcategories[]>> {
    return await this.axios.get<TicketCategoryWithSubcategories[]>('/ticket/api/ticket-category');
  }

  public async downloadAttachment(ticketId: string, filename: string) {
    const response = await this.axios.get<Blob>(`/ticket/api/ticket/${ticketId}/attachment`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    saveAs(blob, filename);
  }

  public async createAttachment(ticketId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await this.axios.post<SuccessResponse>(`/ticket/api/ticket/${ticketId}/attachment`, formData);
  }

  public async deleteAttachment(ticketId: string) {
    return await this.axios.delete<SuccessResponse>(`/ticket/api/ticket/${ticketId}/attachment`);
  }

  public async changeStatus(ticketId: string, form: ChangeStatusForm) {
    return await this.axios.put<SuccessResponse>(`/ticket/api/ticket/${ticketId}/status`, form);
  }

  public async ticketHistory(tickedId: string, params?: ListRequestParams) {
    return await this.axios.get<TicketHistoryList>(`/ticket/api/ticket/${tickedId}/history`, { params });
  }

  public async exportTickets(params: ListRequestParams) {
    const { data } = await this.axios.get('/ticket/api/ticket/export', { params, responseType: 'blob' });
    fileDownload(data, 'Tickets.csv');
  }
}
