import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid } from '@mui/material';

import { LoadingButton } from '../LoadingButton';

interface Props {
  actionDisabled?: boolean;
  actionLoading?: boolean;
  actionLabel?: string;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  cancelDisabled?: boolean;
  justify?: 'flex-end' | 'flex-start' | 'center' | 'space-evenly' | 'space-around' | 'space-between';
  type?: 'button' | 'submit' | 'reset';
  cancelButtonVariant?: 'text' | 'outlined' | 'contained';
  cancelButtonColor?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning' | undefined;
  onAction?(): void;
  onSubmit?(): void;
  onCancel(): void;
}

export const FormActions: React.FC<React.PropsWithChildren<Props>> = ({
  actionDisabled,
  actionLoading,
  actionLabel,
  submitDisabled,
  submitLoading,
  cancelButtonVariant = 'outlined',
  cancelButtonColor = 'primary',
  submitLabel,
  cancelLabel,
  cancelDisabled,
  type = 'submit',
  justify,
  onAction,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('common');

  return (
    <Grid container direction="row" justifyContent={justify ?? 'flex-end'} spacing={1}>
      <Grid item xs={4} sm={3} md={2}>
        {onAction && (
          <LoadingButton
            fullWidth
            isLoading={actionLoading}
            disabled={actionDisabled}
            variant="contained"
            color="primary"
            type="button"
            onClick={onAction}
            data-test="action"
          >
            {actionLabel || t('action')}
          </LoadingButton>
        )}
      </Grid>
      <Grid item xs={8} sm={6} md={6}>
        <Grid container spacing={1} justifyContent={'flex-end'}>
          <Grid item xs={6} md={4}>
            <Button
              fullWidth
              variant={cancelButtonVariant}
              disabled={cancelDisabled}
              color={cancelButtonColor}
              onClick={onCancel}
              name="cancel"
            >
              {cancelLabel || t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <LoadingButton
              fullWidth
              isLoading={submitLoading}
              disabled={submitDisabled}
              variant="contained"
              color="primary"
              type={onSubmit ? 'button' : type}
              onClick={onSubmit}
              name="submit"
            >
              {submitLabel || t('submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
