import { ClassifierEntry } from '../types';

export enum POS_CLASSIFIER {
  INVOICE_DELIVERY_TYPE = 'invoiceDeliveryType',
  CONSENT_TYPE = 'consentType',
  IDENTITY_TYPE = 'identityType',
  COUNTRIES = 'countries',
  CONTACT_PHONE_TYPE = 'contactPhoneType',
}

export type PosClassifierEntry = ClassifierEntry<POS_CLASSIFIER>;
