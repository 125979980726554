import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandLess, ExpandMore, Person } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { useApi } from '../../../app/api-hooks';
import { useApplicationContext } from '../../../app/contexts/ApplicationContext';
import { usePermissionsContext } from '../../../app/contexts/PermissionsContext';
import { routePaths, useRouter } from '../../../app/router';
import { OverlayedLoader } from '../../OverlayedLoader';
import { LanguageSelect } from './components';

interface Props {
  onMenuClose(): void;
}

export const TenantMenuItem: React.FC<Props> = ({ onMenuClose }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { changeUserTenant } = usePermissionsContext();
  const { tenant: currentTenant, loading: applicationContextLoading } = useApplicationContext();
  const [isTenantListOpen, setTenantListOpen] = useState(false);
  const [tenantChangeLoading, setTenantChangeLoading] = useState(false);

  const { result: supportedUserTenantListResult, loading: supportedUserTenantListLoading } = useApi(api =>
    api.sso.supportedUserTenantList(),
  );
  const isFetching = applicationContextLoading || supportedUserTenantListLoading;
  const showTenantSwitch = !!supportedUserTenantListResult?.data?.length;

  return (
    <>
      {showTenantSwitch && (
        <MenuItem divider disableGutters disableRipple dense sx={{ padding: 0 }}>
          <List disablePadding sx={{ width: '100%' }}>
            <ListItemButton disableRipple disabled={isFetching} onClick={() => setTenantListOpen(!isTenantListOpen)}>
              <ListItemIcon sx={{ color: 'text.primary' }}>
                <Person />
              </ListItemIcon>
              <ListItemText primary={t('switch-tenant')} />
              {isTenantListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isTenantListOpen} timeout="auto">
              <List component="div" disablePadding>
                {supportedUserTenantListResult?.data?.map(({ name, tenantId }) => (
                  <ListItemButton key={tenantId} disabled={isFetching} onClick={() => handleTenantChange(tenantId)}>
                    <ListItemText
                      primary={name || tenantId}
                      primaryTypographyProps={{ color: currentTenant?.id === tenantId ? 'primary' : 'neutral' }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        </MenuItem>
      )}
      <MenuItem disableGutters disableRipple dense sx={{ padding: 0 }}>
        <LanguageSelect />
      </MenuItem>
      <OverlayedLoader open={tenantChangeLoading} />
    </>
  );

  async function handleTenantChange(tenantId: string) {
    if (tenantId === currentTenant?.id) return;

    try {
      setTenantChangeLoading(true);
      await changeUserTenant(tenantId);
      router.push(routePaths.pos.order);
      setTenantListOpen(false);
      onMenuClose();
    } finally {
      setTenantChangeLoading(false);
    }
  }
};
