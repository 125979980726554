import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { usePermissionsContext } from '../app/contexts/PermissionsContext';
import { navigationItems } from '../app/navigation';
import { routePaths } from '../app/router';

interface Props {
  path: string;
}

export const ProtectedRoute: React.FC<React.PropsWithChildren<Props>> = ({ path, children }) => {
  const { isRoutePermitted } = usePermissionsContext();

  const findPermittedRoutes = useCallback(
    () =>
      navigationItems
        .flatMap(item => {
          const items = [];
          if (item.path) {
            items.push(item);
          }
          if (item.items) {
            items.push(...item.items);
          }

          return items;
        })
        .filter(item => item.path && isRoutePermitted(item.path)),
    [isRoutePermitted],
  );

  if (path !== '/' && isRoutePermitted(path)) {
    return children ? <>{children}</> : null;
  }

  if (isRoutePermitted(routePaths.pos.order)) {
    return <Navigate to={routePaths.pos.order} />;
  }

  const permittedRoutes = findPermittedRoutes();

  if (permittedRoutes.length && permittedRoutes[0].path) {
    return <Navigate to={permittedRoutes[0].path!} />;
  }

  return <Navigate to="/" />;
};
