import { AccountBalanceApi } from './account-balance/api';
import { BillingApi } from './billing/api';
import { BillingClassifierApi } from './billing-classifier/api';
import { CdrApi } from './cdr/api';
import { CrmApi } from './crm/api';
import { CrmClassifierApi } from './crm-classifier/api';
import { DocumentApi } from './document/api';
import { EligibilityRuleApi } from './eligibility-rule/api';
import { InventoryApi } from './inventory/api';
import { OfferApi } from './offer/api';
import { OrderApi } from './order/api';
import { OrderManagementApi } from './order-management/api';
import { OrderManagementClassifierApi } from './order-management-classifier/api';
import { OrganizationsApi } from './organizations/api';
import { PaymentClassifierApi } from './payment-classifier/api';
import { PosClassifierApi } from './pos-classifier/api';
import { ProductApi } from './product/api';
import { ProductClassifierApi } from './product-classifier/api';
import { ProductComponentApi } from './product-component/api';
import { ProvisioningApi } from './provisioning/api';
import { ProvisioningClassifierApi } from './provisioning-classifier/api';
import { ProvisioningTataApi } from './provisioning-tata/api';
import { SearchApi } from './search/api';
import { SsoApi } from './sso/api';
import { TaskApi } from './task/api';
import { TaskClassifierApi } from './task-classifier/api';
import { TemplateControllerApi } from './template-controller/api';
import { TenantApi } from './tenant/api';
import { TicketApi } from './ticket/api';
import { TicketClassifierApi } from './ticket-classifier/api';
import { WalletApi } from './wallet/api';
import { ChargeCodeApi } from './charge-code/api';
import { PaymentApi } from './payment/api';

export class Api {
  constructor(
    readonly tenant: TenantApi,
    readonly offer: OfferApi,
    readonly product: ProductApi,
    readonly productComponent: ProductComponentApi,
    readonly posClassifier: PosClassifierApi,
    readonly productClassifier: ProductClassifierApi,
    readonly ticketClassifier: TicketClassifierApi,
    readonly orderManagementClassifier: OrderManagementClassifierApi,
    readonly crmClassifier: CrmClassifierApi,
    readonly eligibilityRule: EligibilityRuleApi,
    readonly pos: OrderApi,
    readonly search: SearchApi,
    readonly ticket: TicketApi,
    readonly crm: CrmApi,
    readonly billing: BillingApi,
    readonly billingClassifier: BillingClassifierApi,
    readonly task: TaskApi,
    readonly taskClassifier: TaskClassifierApi,
    readonly sso: SsoApi,
    readonly orderManagement: OrderManagementApi,
    readonly paymentClassifier: PaymentClassifierApi,
    readonly provisioningClassifier: ProvisioningClassifierApi,
    readonly provisioningTata: ProvisioningTataApi,
    readonly organizations: OrganizationsApi,
    readonly provisioning: ProvisioningApi,
    readonly inventory: InventoryApi,
    readonly cdr: CdrApi,
    readonly wallet: WalletApi,
    readonly document: DocumentApi,
    readonly accountBalance: AccountBalanceApi,
    readonly templateController: TemplateControllerApi,
    readonly chargeCode: ChargeCodeApi,
    readonly paymentApi: PaymentApi,
  ) {}
}
