import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { TaskInventoryForm } from '../../order-management/task/ViewTaskPage/common/SetTaskOrderInventory/validation';
import { ListRequestParams, ScrollableEntry, SuccessResponse } from '../types';
import { ConfirmPortOutParams, Decision, SetInventoryMissingDataForm, Task, TaskListItem } from './types';

export class TaskApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list(params: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<TaskListItem>>('/task/api/task', { params });
  }

  public async task(id: string) {
    return await this.axios.get<Task>(`/task/api/task/${id}`);
  }

  public async setInventory(inventory: TaskInventoryForm, taskId: string) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/SET_INVENTORY/${taskId}`, inventory);
  }

  public async confirmDelivery(taskId: string, decision: Decision) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/CONFIRM_DELIVERY/${taskId}`, { decision });
  }

  public async confirmPortIn(taskId: string, decision: string) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/CONFIRM_PORT_IN/${taskId}`, { decision });
  }

  public async confirmPortOut(taskId: string, params: ConfirmPortOutParams) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/CONFIRM_PORT_OUT/${taskId}`, params);
  }

  public async confirmPortOutReversal(taskId: string, params: ConfirmPortOutParams) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/CONFIRM_PORT_OUT_REVERSAL/${taskId}`, params);
  }

  public async updateTask(taskId: string, decision: string) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/GENERIC/${taskId}`, { decision });
  }

  public async setMissingDataInventory(taskId: string, params: SetInventoryMissingDataForm) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/SET_MISSING_DATA/${taskId}`, params);
  }

  public async confirmGenericActivationTask(taskId: string, decision: string, description?: string) {
    return await this.axios.put<SuccessResponse>(`/task/api/task/REVIEW_CUSTOMER_DOCUMENTS/${taskId}`, {
      decision,
      description,
    });
  }

  public async listForOrder(orderId: string, params?: ListRequestParams) {
    return await this.axios.get<ScrollableEntry<TaskListItem>>(`/task/api/task/for-order/${orderId}`, { params });
  }

  public async exportTasks(params: ListRequestParams) {
    const { data } = await this.axios.get('/task/api/task/export', { params, responseType: 'blob' });
    fileDownload(data, 'Tasks.csv');
  }
}
