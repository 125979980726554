import React, { useMemo } from 'react';
import { Toolbar as MuiToolbar, Box, Stack } from '@mui/material';

import { AuthButtons } from './AuthButtons';
import { getTenantLogo } from '../../theme';
import { useApplicationContext } from '../../../app/contexts';
import { TenantName } from './TenantName';
import { Logo, MAX_LOGO_CSS_WIDTH } from './Logo';

export const Toolbar: React.FC = React.memo(() => {
  const { tenant: currentTenant } = useApplicationContext();
  const tenantLogo = useMemo(() => getTenantLogo(currentTenant?.id), [currentTenant?.id]);

  return (
    <MuiToolbar>
      <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignContent="center" sx={{ maxWidth: MAX_LOGO_CSS_WIDTH }}>
          <Logo src={tenantLogo} />
        </Box>
        <Stack gap={1} direction="row" alignItems="center">
          <TenantName tenantName={currentTenant?.name} />
          <AuthButtons />
        </Stack>
      </Stack>
    </MuiToolbar>
  );
});
