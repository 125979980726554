import { UserPermission } from '../api/sso/types';

enum PermissionValues {
  BILLING = 'BILLING',
  CRM = 'CRM',
  INVENTORY = 'INVENTORY',
  CDR = 'CDR',
  OM = 'OM',
  POS = 'POS',
  PRODUCT = 'PRODUCT',
  SEARCH = 'SEARCH',
  SSO = 'SSO',
  TASK = 'TASK',
  TENANT = 'TENANT',
  TICKET = 'TICKET',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
  WALLET = 'WALLET',
  BUSINESS = 'BUSINESS',
}

enum PermissionGroupValues {
  ACCOUNT = 'ACCOUNT',
  ADMIN = 'ADMIN',
  BILLING_CYCLE = 'BILLING_CYCLE',
  CUSTOMER = 'CUSTOMER',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  ELIGIBILITY_RULE = 'ELIGIBILITY_RULE',
  COMPONENT = 'COMPONENT',
  OFFER = 'OFFER',
  COMPATIBILITY = 'COMPATIBILITY',
  OFFER_CATEGORY = 'OFFER_CATEGORY',
  HISTORY = 'HISTORY',
  USER = 'USER',
  ROLE = 'ROLE',
  ORDER = 'ORDER',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SETTINGS = 'SETTINGS',
  USAGE = 'USAGE',
  INVOICE = 'INVOICE',
  CONSENT = 'CONSENT',
  ORGANISATION = 'ORGANISATION',
  USER_POOL = 'USER_POOL',
  RETRY_ORDER = 'RETRY_ORDER',
  ADD_ADD_ON = 'ADD_ADD_ON',
  CHANGE_ORDER = 'CHANGE_ORDER',
  PAYMENT = 'PAYMENT',
  CHARGE_CODE = 'CHARGE_CODE',
  SERVICE_FEE = 'SERVICE_FEE',
  RESERVE = 'RESERVE',
  PENALTY_RULE = 'PENALTY_RULE',
}

const PermissionGroupTypes = { ...PermissionValues, ...PermissionGroupValues };

export type PermissionType = keyof typeof PermissionValues;

export type PermissionGroupType = keyof typeof PermissionGroupTypes;

export type PermissionAction = 'VIEW' | 'EDIT' | 'DELETE' | 'CHANGE_STATUS';

interface Permission {
  view: boolean;
  modify: boolean;
  delete?: boolean;
  additionalActions?: { [key: string]: boolean };
  groups?: { [key in PermissionGroupType]: PermissionModel };
}

export class PermissionModel implements Permission {
  view: boolean;
  modify: boolean;
  delete: boolean;
  groups: Permission['groups'];
  additionalActions: Permission['additionalActions'];

  constructor({ view, modify, delete: del, groups, additionalActions }: UserPermission) {
    this.view = view;
    this.delete = !!del;
    this.modify = modify;
    this.groups = groups as Permission['groups'];
    this.additionalActions = additionalActions;
  }

  public hasPermission(actions?: PermissionAction[], groupPermissions?: PermissionGroupType[]): boolean {
    if (!groupPermissions?.length) {
      return actions?.every(a => this.hasAction(a)) ?? true;
    }

    return groupPermissions.every(p => (this.groups && this.groups[p]?.hasPermission(actions)) ?? false);
  }

  private hasAction(action: string): boolean {
    switch (action) {
      case 'VIEW':
        return this.view;
      case 'EDIT':
        return this.modify;
      case 'DELETE':
        return this.delete;
      default:
        return (this.additionalActions && this.additionalActions[action]) === true;
    }
  }
}

export type Permissions = { [key in PermissionType]: PermissionModel };
