import { useMemo } from 'react';
import { generatePath, Params, RouteObject, useLocation, useNavigate, useParams } from 'react-router-dom';
import QueryString, { parse } from 'qs';

import { billingRoutes } from '../billing/routes';
import { PermissionAction, PermissionGroupType, PermissionType } from '../business/permissions';
import { crmRoutes } from '../crm/routes';
import { orderManagementRoutes } from '../order-management/routes';
import { posRoutes } from '../pos/routes';
import { productCatalogRoutes } from '../product-catalog/routes';
import { inventoryManagementRoutes } from '../inventory-management/routes';
import { userManagementRoutes } from '../user-management/routes';
import { usePermissionsContext } from './contexts/PermissionsContext';

interface RouteParams {}

type QueryParams = QueryString.ParsedQs;

export interface RouteWithId {
  id: string;
}

// eslint-disable-next-line
export const useRouter = <T extends RouteParams = RouteParams, Q extends QueryParams = QueryParams>() => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isRoutePermitted } = usePermissionsContext();

  return useMemo(() => {
    return {
      push: (path: string, params?: Params, search?: string) =>
        isRoutePermitted(path) ? navigate({ pathname: generatePath(path, params), search }) : undefined,
      replace: (path: string, params?: Params, search?: string) =>
        isRoutePermitted(path)
          ? navigate({ pathname: generatePath(path, params), search }, { replace: true })
          : undefined,
      goBack: () => navigate(-1),
      navigateExternal: (path: string) => (window.location.href = path),
      pathname: location.pathname,
      query: {
        ...(parse(location.search, { ignoreQueryPrefix: true }) as Q),
        ...params,
      },
      location,
      params,
    };
  }, [params, location, navigate, isRoutePermitted]);
};

export interface RoutePermission {
  type: PermissionType;
  actions?: PermissionAction[];
  groups?: PermissionGroupType[];
}

export type AppRoute = RouteObject & {
  path: string;
  permission?: RoutePermission;
};

export class RoutesConfig {
  #routes: Record<string, AppRoute>;
  constructor(routes: Record<string, AppRoute>) {
    this.#routes = routes;
  }

  public list = (): AppRoute[] => Object.values(this.#routes);
  public route = (path: string) => this.list().find(r => r.path === path);
}

export class AppRoutes {
  #routes: RoutesConfig[];
  constructor(routes: RoutesConfig[]) {
    this.#routes = routes;
  }

  public list = (): AppRoute[] => this.#routes.flatMap(r => r.list());
  public route = (path: string) => this.#routes.flatMap(r => r.list()).find(r => r.path === path);
}

export const appRoutes = () =>
  new AppRoutes([
    posRoutes(),
    productCatalogRoutes(),
    crmRoutes(),
    orderManagementRoutes(),
    userManagementRoutes(),
    billingRoutes(),
    inventoryManagementRoutes(),
  ]);

export const routePaths = {
  homePage: '/',
  pos: {
    orderWithId: '/pos/order/:orderId',
    order: '/pos/order/',
    manageAddOn:
      '/pos/manage-add-on/customer/:customerId/subscription/:subscriptionId/product/:orderProductId/order/:orderId',
    change: '/pos/change/:subscriptionId/product/:orderProductId/order/:orderId',
    mainProductTermination: '/pos/terminate/:subscriptionId/product/:orderProductId/order/:orderId',
  },
  orders: '/orders',
  productCatalog: {
    offers: {
      list: '/product-catalog/offers',
      new: '/product-catalog/offers/new',
      edit: '/product-catalog/offers/edit/:id',
    },
    products: {
      list: '/product-catalog/products',
      new: '/product-catalog/products/new',
      edit: '/product-catalog/products/edit/:id',
    },
    components: {
      list: '/product-catalog/components',
      new: '/product-catalog/components/new',
      edit: '/product-catalog/components/edit/:id',
    },
    eligibilityRules: {
      list: '/product-catalog/eligibility-rules',
      new: '/product-catalog/eligibility-rules/new',
      edit: '/product-catalog/eligibility-rules/edit/:id',
    },
    chargeCodes: {
      list: '/product-catalog/charge-codes',
      new: '/product-catalog/charge-codes/new',
      edit: '/product-catalog/charge-codes/edit/:id',
    },
    serviceFees: {
      list: '/product-catalog/service-fee',
    },
    penaltyFees: {
      list: '/product-catalog/penalty-fee',
      new: '/product-catalog/penalty-fee/new',
      edit: '/product-catalog/penalty-fee/edit/:id',
    },
  },
  orderManagement: {
    tasksList: '/order-management/tasks',
    task: '/order-management/task/:id',
    ordersList: '/order-management/orders',
    order: '/order-management/customer/:customerId/order/:orderId',
  },
  crm: {
    customer: {
      search: '/customers',
      view: '/customers/:customerId',
      edit: '/customers/edit/:customerId',
      contactLog: '/customer/:customerId/contact-log',
      account: '/customer/:customerId/account/:accountId',
      documents: '/customer/:customerId/documents',
      payments: '/customer/:customerId/payments/:accountId',
    },
    customerTicket: {
      edit: '/customers/:customerId/cases/edit/:ticketId',
      editFiltered: '/customers/filtered-case/edit/:ticketId',
      list: '/customers/:customerId/cases',
    },
    subscription: {
      edit: '/customers/:customerId/subscription/:subscriptionId',
      history: '/customers/:customerId/subscription/:subscriptionId/history',
      list: '/customers/:customerId/subscriptions',
    },
    ticket: {
      list: '/cases',
      new: '/cases/customer/:customerId/subscription/:subscriptionId/new',
      edit: '/cases/edit/:ticketId',
    },
    invoice: {
      view: '/invoice/:invoiceId/:customerId',
    },
    invoicesList: {
      view: '/invoices/:accountId/:customerId',
    },
  },
  userManagement: {
    organisations: '/user-management/organisations',
    roles: {
      list: '/user-management/roles',
      new: '/user-management/roles/new',
      edit: '/user-management/roles/edit/:id',
    },
    users: {
      list: '/user-management/users',
      new: '/user-management/users/new',
      edit: '/user-management/users/edit/:id',
    },
    userPools: {
      list: '/user-management/user-pools',
      new: '/user-management/user-pool/new',
      edit: '/user-management/user-pool/edit/:id',
    },
  },
  billing: {
    billRuns: {
      list: '/billing',
      view: '/billing/:billRunId',
    },
    payments: {
      list: '/payments',
    },
  },
  inventoryManagement: {
    inventoryType: {
      list: '/inventory-management',
      load: '/inventory-management/load',
    },
  },
};
