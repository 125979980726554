import { ThemeOptions } from '@mui/material';
import {
  prodUsTenantWorldMobileDocumentTitle,
  prodUsTenantWorldMobileLogo,
  prodUsTenantWorldMobileThemeOptions,
} from './tenants/tenantWorldMobile';

export const PROD_US_TENANTS_ID_MAP = {
  WORLD_MOBILE: 'WORLD_MOBILE',
};

export const PROD_US_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [PROD_US_TENANTS_ID_MAP.WORLD_MOBILE]: prodUsTenantWorldMobileThemeOptions,
};

export const PROD_US_LOGO_BY_TENANT_MAP: Record<string, string> = {
  [PROD_US_TENANTS_ID_MAP.WORLD_MOBILE]: prodUsTenantWorldMobileLogo,
};

export const PROD_US_DOCUMENT_TITLE_OPTIONS_BY_TENANT_MAP: Record<string, string> = {
  [PROD_US_TENANTS_ID_MAP.WORLD_MOBILE]: prodUsTenantWorldMobileDocumentTitle,
};
