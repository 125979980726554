import React, { PropsWithChildren, useState } from 'react';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  Paper,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';

const rowsPerPageOptions = [5, 10, 25, 50, 100];

interface Props<T> {
  minWidth?: string | number;
  rows: Array<{ [K in keyof Partial<T>]: T[K] | React.ReactNode }>;
  columns: { [K in keyof Partial<T>]: string };
  defaultRowsPerPage?: number;
  showTablePagination?: boolean;
  draggable?: boolean;
  rawRowsData?: any;
  setNewRowsData?: (data: any) => void;
}

interface OnSortEndProps {
  oldIndex: number;
  newIndex: number;
}

export const Table = <T extends {}>({
  minWidth = 650,
  rows,
  columns,
  defaultRowsPerPage = 10,
  showTablePagination = true,
  draggable = false,
  rawRowsData,
  setNewRowsData,
}: Props<T>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const theme = useTheme();

  return (
    <Stack>
      <TableContainer component={Paper} variant="elevation" elevation={0}>
        <MuiTable sx={{ minWidth }} aria-label="table" size="small">
          <TableHead>
            <TableRow>
              {draggable && (
                <TableCell
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: theme.typography.subtitle2,
                  }}
                  align="left"
                />
              )}
              {Object.values(columns).map((header, idx) => (
                <TableCell
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: theme.typography.subtitle2,
                  }}
                  key={idx}
                  align="left"
                >
                  {`${header}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {draggable ? (
            <TableBodySortable onSortEnd={onSortEnd} useDragHandle>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                return (
                  <Row index={idx} key={idx}>
                    <TableCell style={{ width: '5%' }}>
                      <DragHandle />
                    </TableCell>
                    {Object.keys(columns).map(field => (
                      <TableCell key={field} align="left">
                        <>{row[field as keyof T]}</>
                      </TableCell>
                    ))}
                  </Row>
                );
              })}
            </TableBodySortable>
          ) : (
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                <TableRow key={idx}>
                  {Object.keys(columns).map(field => (
                    <TableCell key={field} align="left">
                      <>{row[field as keyof T]}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {showTablePagination && (
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );

  function onSortEnd({ oldIndex, newIndex }: OnSortEndProps) {
    if (setNewRowsData) {
      setNewRowsData(arrayMove(rawRowsData, oldIndex, newIndex));
    }
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  }

  function handleChangePage(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
    setPage(newPage);
  }
};

const DragHandle = SortableHandle(({ style }: any) => (
  <span style={{ ...style, ...{ cursor: 'move' } }}> {'::::'} </span>
));

const TableBodySortable = SortableContainer<PropsWithChildren>(({ children }: PropsWithChildren) => (
  <TableBody>{children}</TableBody>
));

const Row = SortableElement<PropsWithChildren>(({ ...other }) => <TableRow {...other}>{other.children}</TableRow>);
