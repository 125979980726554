import { ThemeOptions } from '@mui/material';

import { stagingTenant1Logo, stagingTenant1ThemeOptions } from './tenants/tenant1';
import {
  stagingBestConnectDocumentTitle,
  stagingBestConnectLogo,
  stagingBestConnectThemeOptions,
} from './tenants/bestConnect';
import { stagingTelfiDocumentTitle, stagingTelfiLogo, stagingTelfiThemeOptions } from './tenants/telfi';
import {
  stagingL2TravelSimDocumentTitle,
  stagingL2TravelSimLogo,
  stagingL2TravelSimThemeOptions,
} from './tenants/l2TravelSim';

export const STAGING_TENANTS_ID_MAP = {
  '1': '1',
  BEST_CONNECT: 'BEST_CONNECT',
  TELFI: 'TELFI',
  L2_TRAVEL_SIM: 'L2_TRAVEL_SIM',
};

export const STAGING_THEME_OPTIONS_BY_TENANT_MAP: Record<string, ThemeOptions> = {
  [STAGING_TENANTS_ID_MAP['1']]: stagingTenant1ThemeOptions,
  [STAGING_TENANTS_ID_MAP.BEST_CONNECT]: stagingBestConnectThemeOptions,
  [STAGING_TENANTS_ID_MAP.TELFI]: stagingTelfiThemeOptions,
  [STAGING_TENANTS_ID_MAP.L2_TRAVEL_SIM]: stagingL2TravelSimThemeOptions,
};

export const STAGING_LOGO_BY_TENANT_MAP: Record<string, string> = {
  [STAGING_TENANTS_ID_MAP['1']]: stagingTenant1Logo,
  [STAGING_TENANTS_ID_MAP.BEST_CONNECT]: stagingBestConnectLogo,
  [STAGING_TENANTS_ID_MAP.TELFI]: stagingTelfiLogo,
  [STAGING_TENANTS_ID_MAP.L2_TRAVEL_SIM]: stagingL2TravelSimLogo,
};

export const STAGING_DOCUMENT_TITLE_BY_TENANT_MAP: Record<string, string> = {
  [STAGING_TENANTS_ID_MAP.BEST_CONNECT]: stagingBestConnectDocumentTitle,
  [STAGING_TENANTS_ID_MAP.TELFI]: stagingTelfiDocumentTitle,
  [STAGING_TENANTS_ID_MAP.L2_TRAVEL_SIM]: stagingL2TravelSimDocumentTitle,
};
