import React from 'react';

import { AppBar as MuiAppBar } from '@mui/material';
import { MUI_APP_BAR_HEIGHT, drawerWidthClosed, drawerWidthExpanded } from '../../theme';

interface Props {
  shifted: boolean;
}

export const AppBar: React.FC<React.PropsWithChildren<Props>> = React.memo(({ shifted, children }) => (
  <MuiAppBar
    sx={{
      height: MUI_APP_BAR_HEIGHT,
      background: 'white',
      boxShadow: '0px 2px 30px 0px rgba(0, 0, 0, 0.06)',
      paddingLeft: `${shifted ? drawerWidthExpanded : drawerWidthClosed}px`,
      ...(!shifted && {
        transition: theme =>
          theme.transitions.create('padding', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }),
      ...(shifted && {
        transition: theme =>
          theme.transitions.create('padding', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
          }),
      }),
    }}
  >
    {children}
  </MuiAppBar>
));
