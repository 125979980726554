import React, { PropsWithChildren } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

interface Props {
  to: string;
  routerLinkProps?: Omit<RouterLinkProps, 'to' | 'children'>;
  muiLinkProps?: Omit<MuiLinkProps, 'children'>;
}

export const Link: React.FC<PropsWithChildren<Props>> = ({ to, children, muiLinkProps, routerLinkProps }) => (
  <MuiLink component={RouterLink} to={to} {...muiLinkProps} {...routerLinkProps}>
    {children}
  </MuiLink>
);
