import { DefaultValues } from 'react-hook-form/dist/types/form';

import { apiFeeToForm, discountedFormFeeToApi, formFeeToApi } from '../../business';
import { ProductRecurringDiscountForm } from './RecurringDiscountForm/validation';
import { ProductForm } from '../products/common/Form/validation';
import { ApiProductForm, Price, ProductPricing } from '../../api/product/types';
import { ProductPricingForm } from './PricingOptionForm/productPricingValidation';

export const productPricingToForm = (
  productPricing: ProductPricing[] | undefined,
): Array<DefaultValues<ProductPricingForm>> =>
  productPricing?.map(({ oneTimeFee, recurringFee, priceSchedule }) => ({
    configurePriceWithVat: true,
    vat: vatToForm(oneTimeFee, recurringFee),
    oneTimeFee: apiFeeToForm(oneTimeFee),
    recurringFee: apiFeeToForm(recurringFee),
    priceSchedule:
      (priceSchedule?.map(ps => {
        return {
          ...apiFeeToForm(ps?.price),
          duration: ps.duration,
          configurePriceWithVat: true,
        };
      }) as ProductRecurringDiscountForm[]) || [],
  })) || [];

export const vatToForm = (oneTimeFee: Price | undefined, recurringFee: Price | undefined) => {
  if (typeof oneTimeFee?.vat === 'number') return oneTimeFee?.vat;
  if (typeof recurringFee?.vat === 'number') return recurringFee?.vat;
  return undefined;
};

export const productPricingToApi = (productPricing: ProductForm['productPricing']): ApiProductForm['productPricing'] =>
  productPricing.map(({ oneTimeFee, recurringFee, priceSchedule, vat }) => {
    let mappedOneTimeFee = undefined;
    const oneTimeFeePricePresent = typeof oneTimeFee.price === 'number' && typeof oneTimeFee.priceNoVat === 'number';

    if (typeof oneTimeFee.discountedFee === 'number' && oneTimeFeePricePresent) {
      mappedOneTimeFee = discountedFormFeeToApi({ vat, ...oneTimeFee });
    } else if (oneTimeFeePricePresent) {
      mappedOneTimeFee = formFeeToApi({ vat, ...oneTimeFee });
    }

    const mappedPriceSchedule = (priceSchedule as unknown as ProductRecurringDiscountForm[]).map(
      ({ duration, ...priceScheduleItem }) => ({
        duration,
        price: formFeeToApi({ vat, ...priceScheduleItem }),
      }),
    );

    return {
      oneTimeFee: mappedOneTimeFee,
      recurringFee: formFeeToApi({ vat, ...recurringFee }),
      priceSchedule: mappedPriceSchedule,
    };
  });

export const productPricingToApiWithCurrency = (
  productPricing: ProductForm['productPricing'],
): ApiProductForm['productPricing'] =>
  productPricing.map(({ oneTimeFee, recurringFee, priceSchedule, vat }) => {
    let mappedOneTimeFee = undefined;
    const oneTimeFeePricePresent = typeof oneTimeFee?.price === 'number' && typeof oneTimeFee?.priceNoVat === 'number';

    if (typeof oneTimeFee?.discountedFee === 'number' && oneTimeFeePricePresent) {
      mappedOneTimeFee = discountedFormFeeToApi({ vat, ...oneTimeFee });
    } else if (oneTimeFeePricePresent) {
      mappedOneTimeFee = formFeeToApi({ vat, ...oneTimeFee });
    }

    const mappedPriceSchedule = (priceSchedule as unknown as ProductRecurringDiscountForm[]).map(
      ({ duration, currency, ...priceScheduleItem }) => ({
        duration,
        price: { ...formFeeToApi({ vat, ...priceScheduleItem }), currency },
      }),
    );

    return {
      oneTimeFee: { ...mappedOneTimeFee, currency: oneTimeFee?.currency },
      recurringFee: { ...formFeeToApi({ vat, ...recurringFee }), currency: recurringFee?.currency },
      priceSchedule: mappedPriceSchedule,
    };
  });

export function addScheduleRange<T extends { duration?: number }>(priceScheduleList: T[]): Array<T & ScheduleRange> {
  if (!priceScheduleList?.length) return [];

  let scheduledStart,
    scheduledEnd,
    prevScheduledEnd = 0;

  return priceScheduleList.map((scheduledPrice, index) => {
    if (index === 0) {
      scheduledStart = 1;
      scheduledEnd = priceScheduleList[0]?.duration || 0;
    } else {
      scheduledStart = prevScheduledEnd + 1;
      scheduledEnd = prevScheduledEnd + (scheduledPrice?.duration || 0);
    }
    prevScheduledEnd = scheduledEnd;

    return {
      ...scheduledPrice,
      scheduledStart,
      scheduledEnd,
    };
  });
}

interface ScheduleRange {
  scheduledStart: ProductRecurringDiscountForm['duration'];
  scheduledEnd: ProductRecurringDiscountForm['duration'];
}
