import React from 'react';
import { Controller, Path, PathValue, useFormContext } from 'react-hook-form';

import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from '@mui/material';

import { interpolatedTranslation } from '../../app/i18n';
import { useTranslation } from 'react-i18next';

interface Props<T> {
  name: Path<T>;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  color?: CheckboxProps['color'];
  defaultValue?: PathValue<T, Path<T>>;
  onChanged?(value: boolean): void;
}

export const CheckboxField = <T extends Record<string, any>>({
  label,
  onChanged,
  name,
  defaultValue,
  disabled = false,
  required = false,
  color = 'primary',
}: Props<T>) => {
  const { t, i18n } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                color={color}
                required={required}
                checked={!!field.value}
                disabled={disabled}
                onChange={handleChange(field.onChange)}
              />
            }
            label={label}
            required={required}
          />
          <FormHelperText variant="standard" error={!!error}>
            {error?.message &&
              i18n.exists(...interpolatedTranslation(error.message)) &&
              t(...interpolatedTranslation(error.message))}
          </FormHelperText>
        </>
      )}
    />
  );

  function handleChange(saveValue: (event: React.ChangeEvent<HTMLInputElement>) => void) {
    return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      saveValue(event);
      onChanged && onChanged(checked);
    };
  }
};
