import { AxiosInstance } from 'axios';

import { BillingClassifierEntry } from './types';

export class BillingClassifierApi {
  constructor(private readonly axios: AxiosInstance) {}

  public async list() {
    return await this.axios.get<BillingClassifierEntry[]>('/billing/api/classifiers');
  }
}
